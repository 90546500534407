import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { filter, Subject } from "rxjs";
import { v4 as idV4 } from "uuid";

import {
  borderDotsIcon,
  commentIcon,
  moreIcon,
  plusIcon,
  searchIcon2,
  starIcon,
} from "../assets/svg/exportSvg";
import Btn from "../components/buttons/Btn";
import Editor from "../components/editor/Editor";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import PAGE_SIZE from "../constants/pagination";
import ContractService from "../services/contractService";
import MessageService, { messageSvc } from "../services/messageService";
import store from "../store";
import { fixIconStyles } from "../utils";

const Inbox = observer(() => {
  const pageNumber = useRef(1);
  const unsubscribeAll = useRef(new Subject());
  const [contracts, setContracts] = useState([]);
  const [editorText, setEditorText] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);

  const formatMessage = (msg) => ({
    id: msg.id,
    img: "./assets/img/ava-2.png",
    contract: msg.contract,
    author: msg.sender.display_name,
    timestamp: msg.timestamp,
    message: msg.message,
    replies: msg.replies
      ? msg.replies.map((r) => ({
          author: r.sender.display_name,
          timestamp: r.timestamp,
          message: r.message,
        }))
      : [],
  });

  const formatMessages = (msgs) =>
    msgs ? msgs.map((x) => formatMessage(x)) : [];

  const loadMore = () => {
    pageNumber.current += 1;

    MessageService.getMessages(pageNumber.current).then((resp) => {
      setMessages([...messages, ...(formatMessages(resp) || [])]);
      setHasMore((resp || []).length === PAGE_SIZE);
    });
  };

  const processSocketMessage = (msg) => {
    setMessages((prevMessages) => {
      const msgs = [...prevMessages];
      const message = formatMessage(msg);
      setCurrentMessage(message);
      const msgIdx = msgs.findIndex((x) => x.id === msg.id);

      if (msgIdx !== -1) {
        msgs[msgIdx] = message;
        return [...msgs];
      }
      return [message, ...msgs];
    });
  };

  const saveMessage = (data) => {
    if (!currentMessage) {
      const { contract } = data;

      if (!contract) {
        toast.error("Please select a contract");
        return;
      }

      MessageService.postMessage(
        contract.id,
        contract.data.hospital_id,
        editorText,
      ).then((msg) => {
        const message = formatMessage(msg);
        setMessages([message, ...messages]);
        setCurrentMessage(message);
        setEditorText("");
      });

      return;
    }

    MessageService.postReply(currentMessage.id, editorText).then((msg) => {
      const msgs = [...messages];
      const message = formatMessage(msg);
      setCurrentMessage(message);
      const msgIdx = msgs.findIndex((x) => x.id === currentMessage.id);
      msgs[msgIdx] = message;
      setMessages(msgs);
      setEditorText("");
    });
  };

  const { company } = store.profile;

  useEffect(() => {
    if (company !== "hospital") {
      ContractService.getLinkedContractsLookup().then((resp) =>
        setContracts(resp),
      );
    }

    MessageService.getMessages().then((resp) => {
      setMessages(formatMessages(resp));
      setHasMore((resp || []).length === PAGE_SIZE);
    });

    messageSvc.inboxMessages$
      .pipe(filter((x) => !!x))
      .subscribe(processSocketMessage);

    store.setMessagesCount(0);

    return () => {
      unsubscribeAll.current.next(null);
      unsubscribeAll.current.complete();
    };
  }, []);

  // console.log("messages", messages);

  return (
    <div>
      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_responsive page__row_head page__row_border">
              <div className="page__col page__col_w50">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Your Inbox</div>
              </div>
              <div className="page__col page__col_w50">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__row page__row_responsive page__row_border">
              <div className="page__col page__col_w50">
                <div className="inbox">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div
                      className="sorting"
                      style={{ marginRight: 20, width: "100%" }}
                    >
                      <div className="sorting__search">
                        <button type="button" className="sorting__open">
                          {fixIconStyles(searchIcon2, store.profile.dark_mode)}
                        </button>
                        <input
                          className="sorting__input"
                          type="text"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      className="sorting__action"
                      onClick={() => setCurrentMessage(null)}
                    >
                      {fixIconStyles(plusIcon, store.profile.dark_mode)}
                    </button>
                  </div>
                  <div className="inbox__tags">
                    <a className="inbox__tag active" href="/">
                      Your inbox
                    </a>
                    <a className="inbox__tag" href="/">
                      Archives
                    </a>
                    <a className="inbox__tag" href="/">
                      Done
                    </a>
                    <a className="inbox__tag" href="/">
                      Saved
                    </a>
                  </div>
                  <div className="inbox__container">
                    <div className="inbox__title title">Recent Messages</div>
                    <div className="inbox__list">
                      {messages.map((item) => {
                        const {
                          img,
                          author,
                          message,
                          timestamp,
                          contract: {
                            patientName = item.patient_name,
                            contractRate = item.contract_rate,
                          },
                        } = item;

                        return (
                          <div
                            key={`item_${idV4()}`}
                            className={`inbox__item__container ${
                              item === currentMessage ? "active" : ""
                            }`}
                            style={{ marginBottom: 10 }}
                            onClick={() => setCurrentMessage(item)}
                            onKeyPress={(e) => {
                              if (e.key === "Enter" || e.key === " ") {
                                setCurrentMessage(item);
                              }
                            }}
                            role="button"
                            tabIndex={0}
                          >
                            <div
                              className="inbox__title title"
                              style={{ paddingBottom: 20 }}
                            >
                              {patientName} ($
                              {contractRate})
                            </div>
                            <div
                              key={`message_${idV4()}`}
                              className="inbox__item"
                            >
                              <div className="inbox__ava">
                                <img className="inbox__pic" src={img} alt="" />
                              </div>
                              <div className="inbox__details">
                                <div className="inbox__head">
                                  <div className="inbox__author caption">
                                    {author}
                                  </div>
                                  <div className="inbox__time caption">
                                    {moment.utc(timestamp).fromNow()}
                                  </div>
                                </div>
                                <div className="inbox__text">{message}</div>
                                <div className="actions">
                                  <button
                                    type="button"
                                    className="actions__btn"
                                  >
                                    {fixIconStyles(
                                      commentIcon,
                                      store.profile.dark_mode,
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="actions__btn"
                                  >
                                    {fixIconStyles(
                                      starIcon,
                                      store.profile.dark_mode,
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="actions__btn"
                                  >
                                    {fixIconStyles(
                                      moreIcon,
                                      store.profile.dark_mode,
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {hasMore && (
                      <div className="inbox__foot">
                        <Btn
                          data={{
                            label: " Load More",
                            className: "products__btn btn-dark ",
                          }}
                          onclickFunc={loadMore}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="page__col page__col_w50">
                <div className="inbox">
                  {currentMessage && (
                    <>
                      <div className="inbox__control">
                        <div className="inbox__btns">
                          <Btn
                            data={{
                              label: " Delete",
                              className: "inbox__btn btn-danger  ",
                            }}
                          />
                          <Btn
                            data={{
                              label: " Archive",
                              className: "inbox__btn btn-dark ",
                            }}
                          />
                        </div>
                        <a className="inbox__more" href="/">
                          {fixIconStyles(
                            borderDotsIcon,
                            store.profile.dark_mode,
                          )}
                        </a>
                      </div>
                      <div
                        className="inbox__title h5"
                        style={{ paddingBottom: 20 }}
                      >
                        {currentMessage.contract.patient_name} ($
                        {currentMessage.contract.contract_rate})
                      </div>
                      <div className="inbox__box">
                        <div className="inbox__ava">
                          <img
                            className="inbox__pic"
                            src="./assets/img/ava-2.png"
                            alt=""
                          />
                        </div>
                        <div className="inbox__details">
                          <div className="inbox__head">
                            <div className="inbox__author title">
                              {currentMessage.author}
                            </div>
                            <div className="inbox__time caption">
                              {moment.utc(currentMessage.timestamp).fromNow()}
                            </div>
                          </div>
                          <div className="inbox__text">
                            <p>{currentMessage.message}</p>
                          </div>
                          {/* <div className='files'>
                          <div className='files__list'>
                            <a className='files__item purple' href='#'>
                              <div className='files__preview'>
                                <img
                                  className='files__pic'
                                  src='./assets/img/figure-1.png'
                                  alt=''
                                />
                              </div>
                              <div className='files__details'>
                                <div className='files__title caption'>
                                  Attachment.PNG{' '}
                                </div>
                                <div className='files__size caption'>
                                  3.6 MB
                                </div>
                              </div>
                            </a>
                            <a className='files__item blue' href='#'>
                              <div className='files__preview'>
                                <img
                                  className='files__pic'
                                  src='./assets/img/figure-5.png'
                                  alt=''
                                />
                              </div>
                              <div className='files__details'>
                                <div className='files__title caption'>
                                  Attachment.PNG{' '}
                                </div>
                                <div className='files__size caption'>
                                  3.6 MB
                                </div>
                              </div>
                            </a>
                          </div>
                        </div> */}
                        </div>
                      </div>
                      {currentMessage.replies.map((reply) => (
                        <div key={`reply_${idV4()}`} className="inbox__box">
                          <div className="inbox__ava">
                            <img
                              className="inbox__pic"
                              src="./assets/img/ava-2.png"
                              alt=""
                            />
                          </div>
                          <div className="inbox__details">
                            <div className="inbox__head">
                              <div className="inbox__author title">
                                {reply.author}
                              </div>
                              <div className="inbox__time caption">
                                {moment.utc(reply.timestamp).fromNow()}
                              </div>
                            </div>
                            <div className="inbox__text">
                              <p>{reply.message}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <Editor
                    data={{ editorText }}
                    contracts={
                      company !== undefined &&
                      company !== "hospital" &&
                      !currentMessage
                        ? contracts
                        : null
                    }
                    save={saveMessage}
                    setEditorText={setEditorText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Inbox;
