import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import Select from "react-select";
import { v4 as idV4 } from "uuid";
import appointment from "../assets/img/appointment-1.png";
import hospital from "../assets/img/hospital-1.png";
import medicalAppImage from "../assets/img/medical-app-1.png";
import { downArrowIcon, removeIcon } from "../assets/svg/exportSvg";
import DetailsItem from "../components/DetailsItem";
import IconProgressItem from "../components/IconProgressItem";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import Btn from "../components/buttons/Btn";
import DateRange from "../components/inputs/DateRange";
import Dropdown from "../components/inputs/Dropdown";
import { monthAbbr, patientTrendChartOptions } from "../constants/chartOptions";
import {
  blue,
  borderColor,
  lightColor,
  purple,
  white,
} from "../constants/colors";
import { facilities, facilitiesList } from "../constants/facilities";
import { dropdownStyles } from "../constants/styles";
import ContractService from "../services/contractService";
import facilitiesSvc from "../services/facilitiesService";
import statisticsSvc from "../services/statisticsService";
import store from "../store";
import { fixIconStyles, formatCurrency, getContractsProgress } from "../utils";

const bgs = ["purple", "green", "yellow"];
const currentYear = new Date().getFullYear();
const years = [currentYear - 1, currentYear];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Statistics = observer(() => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    key: "selection",
  });
  const [contracts, setContracts] = useState([]);
  const [contractsCount, setContractsCount] = useState([]);
  const [expenseChart, setExpenseChart] = useState([]);
  const [facilitiesLookup, setFacilitiesLookup] = useState([]);
  const [patientTrend, setPatientTrend] = useState([]);
  const [perYear, setPerYear] = useState([]);
  const [selectedFacilityType, setSelectedFacilityType] = useState(
    facilitiesList[0],
  );
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  const fetchStatistics = () => {
    statisticsSvc
      .getHospitalDashboard(
        dateRange.startDate,
        dateRange.endDate,
        selectedFacilityType.value,
        selectedFacility ? selectedFacility.value : "",
      )
      .then((resp) => {
        if (resp) {
          setContracts(resp);
        }
      });

    statisticsSvc.getPerYear(selectedFacilityType.value).then((resp) => {
      if (resp) {
        setPerYear(resp);
      }
    });

    ContractService.getCount(
      selectedFacilityType.value,
      selectedFacility ? selectedFacility.value : "",
    ).then((val) => {
      const counts = val || [];

      years.forEach((year) => {
        months.forEach((month) => {
          const item = counts.find((d) => d.year === year && d.month === month);

          if (!item) {
            counts.push({ year, month, count: 0 });
          }
        });
      });

      setContractsCount(
        counts
          .map((x) => ({
            ...x,
            count: parseInt(x.count, 10),
          }))
          .sort((a, b) => {
            if (a.year === b.year) {
              return a.month - b.month;
            }

            return a.year - b.year;
          }),
      );
    });
  };

  const handleAcceptDateRange = () => fetchStatistics();

  const handleSelectDateRange = (ranges) => {
    setDateRange(ranges.selection);
  };

  const handleSelectFacility = (option) => {
    setSelectedFacilityType(option);
  };

  const newUsers = {
    colors: [blue],
    chart: {
      height: "100%",
      type: "bar",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      borderColor,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
    },
    xaxis: {
      axisBorder: {
        show: false,
        color: borderColor,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const newUser = {
    colors: [purple],
    chart: {
      height: "100%",
      type: "bar",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      borderColor,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
    },
    xaxis: {
      axisBorder: {
        show: false,
        color: borderColor,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const optionsMixedChart = {
    colors: [purple],
    chart: {
      height: "100%",
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      borderColor,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const contractsCountOptions = {
    colors: [lightColor, white],
    chart: {
      height: "100%",
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    grid: {
      borderColor,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        left: 10,
        right: 0,
        bottom: 0,
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        endingShape: "rounded",
        columnWidth: "10%",
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      x: {
        show: false,
      },
      shared: true,
    },
    xaxis: {
      labels: {
        show: false,
        formatter: (val) => monthAbbr[val - 1],
        style: {
          colors: white,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        color: borderColor,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter(val) {
          return val.toFixed(0);
        },
        style: {
          colors: white,
        },
      },
    },
  };

  const contractsCountSeries = [
    {
      name: currentYear - 1,
      type: "column",
      data: contractsCount
        .filter((x) => x.year === currentYear - 1)
        .map((x) => x.count),
    },
    {
      name: currentYear,
      type: "column",
      data: contractsCount
        .filter((x) => x.year === currentYear)
        .map((x) => x.count),
    },
  ];

  const expenseChartSeries = [
    {
      name: `${currentYear - 1}`,
      type: "column",
      data: expenseChart
        .filter((x) => x.year === currentYear - 1)
        .map((x) => x.expense),
    },
    {
      name: `${currentYear}`,
      type: "column",
      data: expenseChart
        .filter((x) => x.year === currentYear)
        .map((x) => x.expense),
    },
  ];

  const patientTrendSeries = [
    {
      name: currentYear - 1,
      type: "column",
      data: patientTrend
        .filter((x) => x.year === currentYear - 1)
        .map((x) => x.contracts),
    },
    {
      name: currentYear,
      type: "column",
      data: patientTrend
        .filter((x) => x.year === currentYear)
        .map((x) => x.contracts),
    },
  ];

  const user = [
    {
      name: "series-2",
      type: "column",
      data: [23, 12, 54, 45, 45],
    },
  ];

  const seriesMixedChart = [
    {
      name: "series-1",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "series-1",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
  ];

  const handleMouseEnter = () => setIsHovering(true);

  const handleMouseLeave = () => setIsHovering(false);

  useEffect(() => {
    ContractService.getExpenseChart().then((val) => {
      years.forEach((year) => {
        months.forEach((month) => {
          const item = val.find((d) => d.year === year && d.month === month);

          if (!item) {
            val.push({ year, month, expense: 0 });
          }
        });
      });

      setExpenseChart(
        val.sort((a, b) => {
          if (a.year === b.year) {
            return a.month - b.month;
          }

          return a.year - b.year;
        }),
      );
    });

    ContractService.getPatientTrend().then((val) => {
      years.forEach((year) => {
        months.forEach((month) => {
          const item = val.find((d) => d.year === year && d.month === month);

          if (!item) {
            val.push({ year, month, contracts: 0 });
          }
        });
      });

      setPatientTrend(
        val.sort((a, b) => {
          if (a.year === b.year) {
            return a.month - b.month;
          }

          return a.year - b.year;
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (!selectedFacility) {
      fetchStatistics();
    }

    setSelectedFacility(null);
    facilitiesSvc
      .getLookup(selectedFacilityType.value)
      .then(setFacilitiesLookup);
  }, [selectedFacilityType]);

  useEffect(() => {
    fetchStatistics();
  }, [selectedFacility]);

  const applicableContracts = contracts.filter(
    (x) =>
      !selectedFacilityType.value ||
      x.contract_type === selectedFacilityType.value,
  );

  const countByType = applicableContracts.reduce((acc, contract) => {
    const type = contract.contract_type;
    acc[type] = acc[type] ? acc[type] + 1 : 1;
    return acc;
  }, {});

  const totalCount = Object.values(countByType).reduce(
    (acc, count) => acc + count,
    0,
  );

  let contractsByType = Object.keys(countByType).map((type) => {
    const count = countByType[type];
    const percent = `${((count / totalCount) * 100).toFixed(0)}%`;
    return { contract_type: type, count, percent };
  });

  const currentYearContracts =
    perYear.length > 0
      ? perYear.find((x) => x.year === new Date().getFullYear())?.count
      : 0;

  const prevYearContracts =
    perYear.length > 0
      ? perYear.find((x) => x.year === new Date().getFullYear() - 1)?.count
      : 0;

  const secondLastYearContracts =
    perYear.length > 0
      ? perYear.find((x) => x.year === new Date().getFullYear() - 2)?.count
      : 0;

  const currentYearContractsChange =
    currentYearContracts && prevYearContracts
      ? (100 * (currentYearContracts - prevYearContracts)) / prevYearContracts
      : 0;

  const prevYearContractsChange =
    prevYearContracts && secondLastYearContracts
      ? (100 * (prevYearContracts - secondLastYearContracts)) /
        secondLastYearContracts
      : 0;

  if (!contractsByType) {
    contractsByType = [
      {
        contract_type: "SNF",
        count: 0,
        percent: "0%",
        bg: "bg-purple",
      },
      {
        contract_type: "Home Health",
        count: 0,
        percent: "0%",
        bg: "bg-green",
      },
      {
        contract_type: "Tele Health",
        count: 0,
        percent: "0%",
        bg: "bg-yellow",
      },
      {
        contract_type: "Out Patient",
        count: 0,
        percent: "0%",
        bg: "bg-purple",
      },
    ];
  }

  const totalContracts = contractsByType.reduce(
    (total, current) => total + current.count,
    0,
  );

  contractsByType = contractsByType
    .sort((a, b) => (a.count > b.count ? -1 : 1))
    .map((contract, idx) => ({
      ...contract,
      contract_type:
        facilities[contract.contract_type] || contract.contract_type,
      percent: `${Math.round(
        (totalContracts > 0 ? contract.count / totalContracts : 0) * 100,
        2,
      )}%`,
      bg: `bg-${bgs[idx % 3]}`,
    }));

  const totalContractCost = applicableContracts.reduce(
    (acc, contract) => acc + contract.contract_rate,
    0,
  );

  const avgContractCost = Math.round(
    applicableContracts.length > 0
      ? totalContractCost / applicableContracts.length
      : 0,
    2,
  );

  const totalContractLength = applicableContracts.reduce(
    (acc, contract) => acc + contract.contract_length,
    0,
  );

  const avgContractLength = Math.round(
    applicableContracts.length > 0
      ? totalContractLength / applicableContracts.length
      : 0,
    2,
  );

  const approvedContracts = applicableContracts.filter(
    (contract) => contract.approved && contract.approval_date != null,
  );

  const placementTimesInDays = approvedContracts.map((contract) => {
    const timestamp = new Date(contract.timestamp);
    const approvalDate = new Date(contract.approval_date);
    const timeDiffInMs = approvalDate - timestamp;
    return timeDiffInMs / (1000 * 60 * 60 * 24);
  });

  const sumOfPlacementTimes = placementTimesInDays.reduce(
    (acc, time) => acc + time,
    0,
  );

  const avgPlacementTime = sumOfPlacementTimes / placementTimesInDays.length;

  const mainStats = [
    {
      img: "./assets/img/folder.svg",
      detailsText: "Total Contracts This Year",
      detailsCounter:
        perYear.length > 0
          ? perYear.find((x) => x.year === new Date().getFullYear())?.count
          : 0,
      detailsProgress: "55%",
      bg: "bg-purple",
    },
    {
      img: "./assets/img/activity.svg",
      detailsText: "Average Contract Length",
      detailsCounter: avgContractLength || 0,
      detailsProgress: "52%",
      bg: "bg-pink",
    },
    {
      img: "./assets/img/bag.svg",
      detailsText: "Average Placement Time",
      detailsCounter: avgPlacementTime || 0,
      detailsProgress: "55%",
      bg: "bg-blue",
    },
    {
      img: "./assets/img/chat.svg",
      detailsText: "Average Contract Cost",
      detailsCounter: formatCurrency(avgContractCost || 0),
      detailsProgress: "68%",
      bg: "bg-red",
    },
  ];

  const currentDate = new Date();

  const activeContracts = contracts
    .filter(
      (x) =>
        !selectedFacilityType.value ||
        x.contract_type === selectedFacilityType.value,
    )
    .map((contract) => {
      const startDate = new Date(contract.start_date);

      const daysElapsed = Math.ceil(
        (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      );

      const progress = Math.round(
        Math.min((daysElapsed / contract.contract_length) * 100, 100),
        2,
      );

      return {
        ...contract,
        daysElapsed,
        progress,
      };
    })
    .filter(
      (contract) =>
        contract.daysElapsed >= 0 &&
        contract.daysElapsed <= contract.contract_length,
    );

  const contractsProgress = getContractsProgress(activeContracts).slice(0, 3);

  const latestContracts = contracts
    .sort((a, b) =>
      new Date(a.start_date).getTime() > new Date(b.start_date).getTime()
        ? -1
        : 1,
    )
    .slice(0, 3);

  return (
    <div>
      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head page__row_border">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Statistics</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>

            <div className="page__row page__row_border">
              <div className="page__col">
                <div
                  className="details__container"
                  style={{
                    borderRadius: 24,
                    display: "flex",
                    flexDirection: "column",
                    padding: 20,
                    marginBottom: 20,
                  }}
                >
                  <DateRange
                    dateRange={dateRange}
                    selectDateRange={handleSelectDateRange}
                    acceptDateRange={handleAcceptDateRange}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: 20,
                      justifyContent: "space-between",
                    }}
                  >
                    <Dropdown
                      data={{
                        selected: selectedFacilityType,
                        option_list: facilitiesList,
                        selectOption: handleSelectFacility,
                      }}
                    />
                    <Select
                      options={facilitiesLookup.map((x) => ({
                        value: x.id,
                        label: x.facility_name,
                      }))}
                      placeholder="Select a Facility"
                      value={selectedFacility}
                      onChange={setSelectedFacility}
                      isClearable
                      noOptionsMessage={() => "Select any other facility type"}
                      styles={{
                        ...dropdownStyles(store.profile.dark_mode),
                        control: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(store.profile.dark_mode).control(
                            baseStyles,
                          ),
                          borderRadius: 15,
                          marginLeft: 20,
                          width: 320,
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(
                            store.profile.dark_mode,
                          ).placeholder(baseStyles),
                          fontSize: 16,
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(
                            store.profile.dark_mode,
                          ).singleValue(baseStyles),
                          color: "#808191",
                          fontSize: 16,
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="details">
                  <div className="details__container">
                    <div className="details__title h6">
                      Active Contracts right now 💡
                    </div>
                    <div className="details__row">
                      <div className="details__col">
                        <div className="details__top">
                          <div className="details__number h1">
                            {activeContracts.length || 0}
                          </div>
                          <Link className="details__line" to={{}}>
                            <div className="details__preview">
                              <img
                                className="details__pic"
                                src="./assets/img/details-pic.png"
                                alt=""
                              />
                            </div>
                            <div className="details__info caption-sm">
                              Contracts per month
                            </div>
                          </Link>
                        </div>
                        <div className="details__bottom">
                          <div className="details__statistics">
                            <div className="details__chart details__chart_activity">
                              <Chart
                                options={optionsMixedChart}
                                series={seriesMixedChart}
                                type="line"
                                width="100%"
                                height="100%"
                              />
                            </div>
                            <div className="details__status">
                              <div className="details__icon bg-blue">
                                {fixIconStyles(
                                  downArrowIcon,
                                  store.profile.dark_mode,
                                )}
                              </div>
                              <div className="details__percent caption-sm color-blue-dark">
                                6%
                              </div>
                            </div>
                          </div>
                          <div className="details__info caption-sm">
                            Update your report preferences in settings
                          </div>
                        </div>
                      </div>
                      <div className="details__col">
                        <div className="details__box">
                          <div className="details__chart details__chart_counter">
                            <Chart
                              options={contractsCountOptions}
                              series={contractsCountSeries}
                              height="100%"
                            />
                          </div>
                          <button type="button" className="details__remove">
                            {fixIconStyles(removeIcon, store.profile.dark_mode)}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="details__list details__list_four">
                      {mainStats.map((data) => (
                        <DetailsItem key={idV4()} data={data} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="page__widgets">
                  <div className="widget widget_users widget_shadow widget_p0">
                    <div className="widget__head">
                      <div className="widget__title">Contracts</div>
                    </div>
                    <div className="widget__body">
                      <div className="widget__flex">
                        <div className="widget__desc">
                          <div className="widget__category caption-sm">
                            This Year
                          </div>
                          <div className="widget__number h4">
                            {currentYearContracts}
                          </div>
                          <div className="widget__percent">
                            {Math.round(currentYearContractsChange, 0)}%
                          </div>
                        </div>
                        <div className="widget__chart widget__chart_users">
                          <Chart
                            options={newUsers}
                            series={user}
                            height="100%"
                          />
                        </div>
                      </div>
                      <div className="widget__flex">
                        <div className="widget__desc">
                          <div className="widget__category caption-sm">
                            Last Year
                          </div>
                          <div className="widget__number h4">
                            {prevYearContracts}
                          </div>
                          <div className="widget__percent">
                            {prevYearContractsChange}%
                          </div>
                        </div>
                        <div className="widget__chart widget__chart_users">
                          <Chart
                            options={newUser}
                            series={user}
                            height="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="widget__foot">
                      <Link className="widget__link caption" to="/contracts">
                        Go to contracts
                      </Link>
                    </div>
                  </div>
                  <div className="widget widget_chart widget_purple">
                    <div className="widget__title color-white">Expense</div>
                    <div className="widget__wrap">
                      <div className="widget__chart widget__chart_earning">
                        {/* <div id="chart-income" /> */}
                        <Chart
                          options={patientTrendChartOptions}
                          series={expenseChartSeries}
                          height="100%"
                          width="100%"
                        />
                      </div>
                      <div className="widget__btns">
                        <Btn
                          data={{
                            label: "More Details",
                            className: "btn-dark btn_wide",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="page__widgets">
                  <div className="widget widget_shadow">
                    <div className="widget__title">Contract Progress</div>
                    <div className="quality">
                      <div className="quality__list">
                        {contractsProgress.map((data) => (
                          <IconProgressItem data={data} key={idV4()} />
                        ))}
                      </div>
                      <Link to="/contracts">
                        <Btn
                          data={{
                            label: "Show all contracts",
                            className: "quality__btn btn-dark btn_wide",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="widget widget_shadow">
                    <div className="widget__top">
                      <div className="widget__title mr-auto">
                        Latest Contracts
                      </div>
                      <Link className="widget__next" to={{}}>
                        <svg
                          className="icon icon-arrow-next-fat"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="10"
                          viewBox="0 0 13 10"
                        >
                          <path
                            fill="#11142d"
                            d="M8.613.2l.094.083 4 4c.028.028.055.06.08.1l-.08-.1a1.01 1.01 0 0 1 .097.112l.05.075.02.037.03.06.022.052.022.06.014.046.016.065.01.052.007.06.003.055V5v.033l-.003.052L13 5a1.01 1.01 0 0 1-.011.149l-.01.052-.015.065-.014.046-.02.06-.023.052-.03.06-.02.036-.05.074-.013.018-.083.094-4 4a1 1 0 0 1-1.497-1.32l.083-.094L9.585 6H1.67a1 1 0 0 1-.117-1.993L1.67 4h7.914L7.293 1.707A1 1 0 0 1 7.21.387l.083-.094A1 1 0 0 1 8.613.21z"
                          />
                        </svg>
                      </Link>
                    </div>
                    <div className="quality">
                      <div className="quality__list">
                        {latestContracts.map((data) => {
                          const {
                            contract_rate: contractRate,
                            patient_name: patientName,
                            out_patient_name: outPatientName,
                            snf_name: snfName,
                          } = data;
                          return (
                            <div className="quality__item" key={idV4()}>
                              <div
                                className={`quality__preview ${
                                  patientName && "bg-pink-opacity"
                                }`}
                              >
                                {patientName && (
                                  <img
                                    className="quality__pic"
                                    src={medicalAppImage}
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="quality__details">
                                <div className="quality__line">
                                  <div className="quality__title title">
                                    {patientName}
                                  </div>
                                  <div className="quality__price title">
                                    {contractRate &&
                                      `+${formatCurrency(contractRate)}`}
                                  </div>
                                </div>
                                <div className="quality__info caption-sm">
                                  {outPatientName || snfName}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Link to="/contracts">
                        <Btn
                          data={{
                            label: "Show all contracts",
                            className: "quality__btn btn-dark btn_wide",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page__col">
                <div className="page__group">
                  <div
                    className="widget widget_shadow"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="widget__title">2023 Breakdown Matrix</div>
                    <div className="goal">
                      <div className="goal__container">
                        <div className="goal__slider">
                          <Carousel
                            variant="light"
                            controls={false}
                            touch
                            slide
                          >
                            <Carousel.Item>
                              <div className="goal__preview">
                                <img
                                  className="goal__pic"
                                  src={hospital}
                                  alt=""
                                />
                                <div className="goal__bg bg-blue-light" />
                              </div>
                            </Carousel.Item>
                            <Carousel.Item>
                              <div className="goal__preview">
                                <img
                                  className="goal__pic"
                                  src={appointment}
                                  alt=""
                                />
                                <div className="goal__bg bg-pink" />
                              </div>
                            </Carousel.Item>
                          </Carousel>
                        </div>
                      </div>
                      <div className="goal__list">
                        {contractsByType.map((data) => {
                          const {
                            contract_type: contractType,
                            count,
                            percent,
                            bg,
                          } = data;
                          return (
                            <div
                              className="goal__item"
                              key={`contract_by_type_${contractType}`}
                            >
                              <div className="goal__head">
                                <div className="goal__title title">
                                  {contractType}
                                </div>
                                <div className="goal__percent title">
                                  {isHovering ? count : percent}
                                </div>
                              </div>
                              <div className="goal__indicator">
                                <div
                                  className={`goal__progress ${bg}`}
                                  style={{ width: percent }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="widget widget_empty widget_p0">
                    <div className="widget__title">Patient Trend</div>
                    <div className="widget__chart widget__chart_impressions">
                      <Chart
                        options={patientTrendChartOptions}
                        series={patientTrendSeries}
                        height="100%"
                        width="100%"
                      />
                      {/* <div id="chart-affiliate-impressions" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Statistics;
