import apiSvc from "./apiService";

class FacilitiesService {
  static createFacility(data) {
    return apiSvc.post("/facilities", data);
  }

  static getFacilities(search) {
    return apiSvc.get(`/facilities?search=${search}`);
  }

  static getWithinRangeFaciliy(lon, lat, radius) {
    return apiSvc.get(
      `/facilities/within-range?lon=${lon}&lat=${lat}&radius=${radius}`,
    );
  }

  static getFacilitiesByType(type) {
    return apiSvc.get(`/facilities/by-type?type=${type || ""}`);
  }

  static getFacility(type, id) {
    return apiSvc.get(`/facilities?type=${type}&id=${id}`);
  }

  static getLookup(type) {
    return apiSvc.get(`/facilities/lookup?type=${type || ""}`);
  }

  static updateFacility(data) {
    return apiSvc.put("/facilities", data);
  }
}

export default FacilitiesService;
