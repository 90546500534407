import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import patientSvc from "../../services/patientService";
import store from "../../store";

const CreatePatientModal = observer(({ hide, setPatients }) => {
  const [patientId, setPatientId] = useState("");
  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [birthDate, setBirthDate] = useState();

  const close = () => {
    setPatientId("");
    setBirthDate("");
    setFamilyName("");
    setName("");
    hide();
  };

  const searchPatient = async (event) => {
    event.preventDefault();

    if (patientId) {
      const patients = await patientSvc.getRedoxPatientById(patientId);
      setPatients(patients);
    } else if (familyName && birthDate && name) {
      const patients = await patientSvc.getRedoxPatientsByDemographic(
        name,
        familyName,
        birthDate,
      );

      setPatients(patients);
    } else {
      toast.error(
        "Either Patient ID or (name, family name and birth date) is required",
      );
    }
  };

  return (
    <Modal size="lg" show onHide={close} animation centered backdrop="static">
      <Modal.Header
        closeButton
        closeVariant={store.profile.dark_mode ? "white" : ""}
      >
        <Modal.Title className="ps-4">Search Patient (Redox)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="popup__form">
          <div className="popup__fieldset">
            <div className="popup__row">
              <div className="popup__field field">
                <div className="field__label">
                  Patient ID
                  <button
                    type="button"
                    className="hint"
                    onClick={() =>
                      setPatientId(
                        "urn:redox:redox-fhir-sandbox:MR|kyHGADnvX3xbkU4V9ayaqh",
                      )
                    }
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        setPatientId(
                          "urn:redox:redox-fhir-sandbox:MR|kyHGADnvX3xbkU4V9ayaqh",
                        );
                      }
                    }}
                  >
                    Hint
                  </button>
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={patientId}
                    onChange={(e) => setPatientId(e.target.value)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div className="field__label">
                  Name
                  <button
                    type="button"
                    onClick={() => {
                      setName("Keva");
                      setFamilyName("Green");
                      setBirthDate("1995-08-26");
                    }}
                    onKeyDown={(event) => {
                      // Handle keyboard events for "Enter" or "Space" keys
                      if (event.key === "Enter" || event.key === " ") {
                        setName("Keva");
                        setFamilyName("Green");
                        setBirthDate("1995-08-26");
                      }
                    }}
                    tabIndex={0} // Make the button focusable using the keyboard
                    className="hint"
                  >
                    Hint
                  </button>
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div className="field__label">Family Name</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={familyName}
                    onChange={(e) => {
                      setFamilyName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div className="field__label">Birth Date</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="date"
                    value={birthDate}
                    onChange={(e) => {
                      setBirthDate(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-dark"
              onClick={searchPatient}
            >
              Search
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CreatePatientModal;
