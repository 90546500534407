import { of } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import apiSvc from "./apiService";

const subjects = {};

class PubSubService {
  static connect(url) {
    if (!url) {
      return of(null);
    }

    const baseUrl = this.getBaseUrl(url);

    if (!baseUrl) {
      return of(null);
    }

    if (subjects[baseUrl] && !subjects[baseUrl].closed) {
      return subjects[baseUrl].asObservable();
    }

    subjects[baseUrl] = webSocket(url);
    return subjects[baseUrl].asObservable();
  }

  static getBaseUrl(url) {
    if (!url) {
      return "";
    }

    return url.split("?")[0];
  }

  static getUrl(hub) {
    return apiSvc.get(`/pubsub?hub=${hub}`);
  }
}

export default PubSubService;
