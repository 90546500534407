import { BehaviorSubject } from "rxjs";

import store from "../store";
import apiSvc from "./apiService";

export class AuthService {
  isAuthenticatedSub = new BehaviorSubject(false);

  static canAddContract() {
    return this.canPerformOperation("Create Contract");
  }

  static canAddPatient() {
    return this.canPerformOperation("Create Patient");
  }

  static canPerformOperation(operation) {
    if (store.profile.role === "admin") {
      return true;
    }

    const permission = store.permissions.find((x) => x.name === operation);

    return (
      permission !== undefined &&
      !!store.profile.role &&
      permission[store.profile.company][store.profile.role] === true
    );
  }

  static getNavigation() {
    return apiSvc.get("/auth/navigation");
  }

  static getPermissions() {
    return apiSvc.get("/auth/permissions");
  }

  static getUsers() {
    return apiSvc.get("/users");
  }

  isAuthenticated() {
    return this.isAuthenticatedSub.asObservable();
  }

  setIsAuthenticated(isAuthenticated) {
    this.isAuthenticatedSub.next(isAuthenticated);
  }

  static updateNavigation(name, company, role, enabled) {
    return apiSvc.post("/auth/navigation", {
      name,
      company,
      role,
      enabled,
    });
  }

  static updatePermission(name, sectionName, company, role, enabled) {
    return apiSvc.post("/auth/permissions", {
      name,
      section_name: sectionName,
      company,
      role,
      enabled,
    });
  }

  static async updateUserCompany(user, company) {
    await apiSvc.put("/users", {
      ...user,
      company,
    });
  }

  static async updateUserFacilities(user, facilityIds) {
    await apiSvc.put("/users", {
      ...user,
      facility_ids: facilityIds,
    });
  }

  static async updateUserRole(user, role) {
    await apiSvc.put("/users", {
      ...user,
      role,
    });
  }
}

export const authSvc = new AuthService();
