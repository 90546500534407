import {
  chatIcon,
  logoutIcon,
  messageIcon,
  notificationIcon,
  overviewIcon,
  profileIcon,
  settingIcon,
} from "../assets/svg/exportSvg";

const menuItems = [
  {
    text: "Overview",
    location: "home",
    icon: overviewIcon,
  },
];

const menuItemsList = {
  account: {
    text: "Accounts",
    icon: settingIcon,
    type: "button",
  },
  inbox: {
    text: "Inbox",
    location: "inbox",
    icon: messageIcon,
  },
  notification: {
    text: "Notifications",
    location: "notifications",
    icon: notificationIcon,
  },
  comment: {
    text: "Comments",
    location: "comments",
    icon: chatIcon,
  },
  profile: {
    text: "Profile",
    location: "#",
    icon: profileIcon,
  },
  logout: {
    text: "Log out",
    icon: logoutIcon,
    type: "button",
  },
};

export default menuItems;
export { menuItemsList };
