import PropTypes from "prop-types";
import Btn from "./buttons/Btn";

function TopBanner({ data }) {
  const { btnLabel, bannerTitle, bannerText, img } = data;
  let { buttonClick } = data;

  if (!buttonClick) {
    buttonClick = () => {};
  }

  return (
    <div className="banner">
      <div className="banner__container">
        <div className="banner__preview">
          <img className="banner__pic" src={img} alt="" />
        </div>
        <div className="banner__wrap">
          <div className="banner__title h3">{bannerTitle}</div>
          <div className="banner__text">{bannerText}</div>
          {btnLabel && (
            <Btn
              data={{
                label: btnLabel,
                className: "btn-light",
              }}
              onclickFunc={buttonClick}
            />
          )}
        </div>
      </div>
    </div>
  );
}

TopBanner.propTypes = {
  data: PropTypes.func.isRequired,
};

export default TopBanner;
