import moment from "moment";

export const fromPlainDateToIsoString = (val) => {
  const date = new Date(val);
  const timeZoneOffsetInMinutes = date.getTimezoneOffset();
  const timeZoneOffsetInMilliSeconds = timeZoneOffsetInMinutes * 60 * 1000;
  return new Date(date.getTime() + timeZoneOffsetInMilliSeconds).toISOString();
};

export const formatDate = (dateTime) =>
  moment.utc(dateTime).local().format("MMM D, yyyy");

export const formatDateTime = (dateTime) =>
  moment.utc(dateTime).local().format("DD/MM/yyyy HH:mm");

export const formatShortDate = (dateTime) =>
  moment.utc(dateTime).local().format("D MMM");

export const fromUtcDateToDateString = (date) =>
  moment.utc(date).local().format("YYYY-MM-DD");

export const isExpiring = (date, days) => {
  const today = new Date();
  const fiveDaysFromToday = new Date(
    today.getTime() + days * 24 * 60 * 60 * 1000,
  );

  return (
    date.getTime() >= today.getTime() &&
    date.getTime() <= fiveDaysFromToday.getTime()
  );
};
