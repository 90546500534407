export const baseFields = [
  {
    label: "Display Name",
    key: "display_name",
    type: "text",
    value: "",
  },
  {
    label: "Email Address",
    key: "email",
    type: "text",
    value: "",
    readOnly: true,
  },
  {
    label: "Phone Number",
    key: "phone_number",
    type: "text",
    value: "",
  },
  {
    label: "Job Title",
    key: "title",
    type: "text",
    value: "",
  },
  {
    label: "Pronoun",
    key: "pronoun",
    type: "select",
    value: "he/him",
    options: ["he/him", "she/her", "they/them"].map((x) => ({
      id: x,
      name: x,
    })),
  },
];

export default baseFields;
