import { observer } from "mobx-react-lite";
import { searchIcon2 } from "../assets/svg/exportSvg";
import store from "../store";
import { fixIconStyles } from "../utils";

const Search = observer(
  ({ children, value, placeholder, SearchOnClick, change = () => {} }) => {
    return (
      <div className="sorting__col">
        <div className="sorting__line">
          <div
            role="button"
            tabIndex={0}
            onClick={SearchOnClick || (() => {})}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                // Handle the enter key press as a click event
                SearchOnClick();
              }
            }}
            className="sorting__search"
          >
            <button type="button" className="sorting__open">
              {fixIconStyles(searchIcon2, store.profile.dark_mode)}
            </button>
            <input
              value={value}
              className="sorting__input"
              type="text"
              placeholder={placeholder || "Search"}
              onChange={(e) => change(e.target.value)}
            />
          </div>
          <div className="sorting__actions">{children}</div>
        </div>
      </div>
    );
  },
);

export default Search;
