export const isGuid = (value) => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return value.match(regex) !== null;
};

export const requestRoute = (url) => {
  const urlWithoutQueryString = url.split("?")[0];
  const urlParts = urlWithoutQueryString
    .split("/")
    .filter((x, idx) => idx !== 0 && isGuid(x) === false);
  return urlParts.join("/");
};
