import PropTypes from "prop-types";

function Btn({ data, onclickFunc }) {
  const { label, className } = data;

  return (
    <button
      type="button"
      onClick={() => {
        if (onclickFunc) {
          onclickFunc();
        }
      }}
      className={`btn  ${className}`}
    >
      {label}
    </button>
  );
}

Btn.propTypes = {
  data: {
    label: PropTypes.string,
    className: PropTypes.string,
  },
  onclickFunc: PropTypes.func,
};

Btn.defaultProps = {
  data: {}, // Provide a default value that matches the expected data type
  onclickFunc: () => {},
};

export default Btn;
