import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../config/auth-config";

class TokenService {
  static async acquireAccessToken(protectedResource) {
    const msalInstance = new PublicClientApplication(msalConfig);

    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
      return "";
    }

    const request = {
      scopes: protectedResource.scopes,
      account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
  }
}

export default TokenService;
