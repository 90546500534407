import axios from "axios";
import { toast } from "react-toastify";
import { Subject } from "rxjs";
import { protectedResources } from "../config/auth-config";
import toastMessages from "../constants/toasts.json";
import { requestRoute } from "../utils";
import TokenService from "./tokenService";

let requestsInProgress = 0;

const pendingRequests = {};
const toasts = {};

const getToastKey = (method, url) => `${method}|${url}`;

const updateToast = (config, type, response) => {
  setTimeout(() => {
    if (!config) {
      return;
    }

    const toastKey = getToastKey(config.method, requestRoute(config.url));

    let renderMessage = "";

    if (type === "error" && response) {
      if (response.status === 400) {
        renderMessage = response.data;
      } else {
        renderMessage =
          "We have encountered an issue while processing your request. Please try again later.";
      }
    }

    if (toasts[toastKey]) {
      toast.update(toasts[toastKey], {
        render: renderMessage,
        type,
        isLoading: false,
        autoClose: 2000,
      });

      delete toasts[toastKey];
    }
  }, 300);
};

class ApiService {
  api;

  loading$ = new Subject();

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 30000,
    });

    this.api.interceptors.request.use(async (config) => {
      const accessToken = await TokenService.acquireAccessToken(
        protectedResources.apiService,
      );
      if (!accessToken) {
        throw new axios.Cancel("Invalid access token");
      }

      const toastKey = getToastKey(config.method, requestRoute(config.url));
      const toastMessage = toastMessages[toastKey];

      if (toastMessage) {
        toasts[toastKey] = toast.loading(toastMessage);
      }

      if (!ApiService.spinnerSkipped(config.url)) {
        setTimeout(() => {
          requestsInProgress += 1;
          if (requestsInProgress > 0) {
            this.loading$.next(true);
          }
        }, 1000);
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
    });

    this.api.interceptors.response.use(
      (response) => {
        updateToast(response.config, "success");
        this.stopSpinner(response.config.url);
        return response;
      },
      (error) => {
        updateToast(error.config, "error", error.response);
        this.stopSpinner("");
        return Promise.reject(error);
      },
    );
  }

  delete(endpoint) {
    if (endpoint in pendingRequests) {
      return pendingRequests[endpoint];
    }

    pendingRequests[endpoint] = this.api
      .delete(endpoint)
      .then((resp) => resp?.data)
      .catch(() => {})
      .finally(() => delete pendingRequests[endpoint]);

    return pendingRequests[endpoint];
  }

  get(endpoint) {
    if (endpoint in pendingRequests) {
      return pendingRequests[endpoint];
    }

    pendingRequests[endpoint] = this.api
      .get(endpoint)
      .then((resp) => resp?.data)
      .catch(() => {
        return null;
      })
      .finally(() => delete pendingRequests[endpoint]);

    return pendingRequests[endpoint];
  }

  post(endpoint, data = null, config = null) {
    if (endpoint in pendingRequests) {
      return pendingRequests[endpoint];
    }

    pendingRequests[endpoint] = this.api
      .post(endpoint, data, config)
      .then((resp) => resp?.data)
      .finally(() => delete pendingRequests[endpoint]);

    return pendingRequests[endpoint];
  }

  put(endpoint, data = null, config = null) {
    if (endpoint in pendingRequests) {
      return pendingRequests[endpoint];
    }

    pendingRequests[endpoint] = this.api
      .put(endpoint, data, config)
      .then((resp) => resp?.data)
      .catch(() => {})
      .finally(() => delete pendingRequests[endpoint]);

    return pendingRequests[endpoint];
  }

  stopSpinner(url) {
    if (ApiService.spinnerSkipped(url)) {
      return;
    }

    requestsInProgress -= 1;

    if (requestsInProgress === 0) {
      this.loading$.next(false);
    }
  }

  static spinnerSkipped(url) {
    return url.indexOf("noSpinner=true") !== -1;
  }
}

const apiSvc = new ApiService();

export default apiSvc;
