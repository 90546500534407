import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import {
  facilitiesWithHospital,
  facilitiesWithHospitalList,
} from "../../constants/facilities";
import { dropdownStyles } from "../../constants/styles";
import capabilitiesSvc from "../../services/capabilitiesService";
import facilitiesSvc from "../../services/facilitiesService";
import store from "../../store";
import CitiesComboBox from "./CitiesCombobox";
import CountriesComboBox from "./CountriesComboBox";
import StatesComboBox from "./StatesComboBox";

const CreateFacility = observer(({ facilityDetails, created, hide }) => {
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [capabilities, setCapabilities] = useState([]);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [domainNames, setDomainNames] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [group, setGroup] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [locationName, setLocationName] = useState("");
  const [longitude, setLongitude] = useState(0);
  const [name, setName] = useState("");
  const [selectedCapabilities, setSelectedCapabilities] = useState([]);
  const [state, setState] = useState("");
  const [touched, setTouched] = useState(false);
  const [type, setType] = useState("");
  const [typeChanged, setTypeChanged] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [zip, setZip] = useState("");

  const createFacility = async (e) => {
    e.preventDefault();

    setTouched(true);

    if (
      !type ||
      !group ||
      !name ||
      !locationName ||
      !address1 ||
      !city ||
      !state ||
      !zip ||
      !country ||
      !longitude ||
      !latitude ||
      !domainNames
    ) {
      return;
    }

    try {
      const existingData = facilityDetails || {};

      const data = {
        ...existingData,
        facility_group: group,
        facility_type: type,
        facility_name: name,
        location_name: locationName,
        address: {
          ...(existingData.address || {}),
          address_1: address1,
          address_2: address2,
          city,
          state,
          zip,
          country,
        },
        location: {
          type: "Point",
          coordinates: [longitude, latitude],
        },
        domain_names: domainNames.split(",").map((x) => x.trim()),
        capabilities: selectedCapabilities.map((x) => ({
          id: x.value,
          name: x.label,
        })),
      };

      if (!editMode) {
        await facilitiesSvc.createFacility(data);
      } else {
        await facilitiesSvc.updateFacility(data);
      }

      setTouched(false);
      created();
    } catch {
      // TODO: Need to show an appropriate message to the user
    }
  };

  useEffect(() => {
    if (facilityDetails) {
      setEditMode(true);
      setType(facilityDetails.facility_type);
      setGroup(facilityDetails.facility_group);
      setName(facilityDetails.facility_name);
      setLocationName(facilityDetails.location_name);
      setAddress1(facilityDetails.address.address_1);
      setAddress2(facilityDetails.address.address_2);
      setCountry(facilityDetails.address.country);
      setState(facilityDetails.address.state);
      setCity(facilityDetails.address.city);
      setZip(facilityDetails.address.zip);
      setLongitude(facilityDetails.location.coordinates[0]);
      setLatitude(facilityDetails.location.coordinates[1]);
      setDomainNames(facilityDetails.domain_names.join(", "));

      setSelectedType({
        value: facilityDetails.facility_type,
        label: facilitiesWithHospital[facilityDetails.facility_type],
      });
      setSelectedCountry({
        value: facilityDetails.address.country,
        label: facilityDetails.address.country,
      });
      setSelectedState({
        value: facilityDetails.address.state,
        label: facilityDetails.address.state,
      });
      setSelectedCity({
        value: facilityDetails.address.city,
        label: facilityDetails.address.city,
      });
      setSelectedCapabilities(
        facilityDetails.capabilities.map((x) => ({
          value: x.id,
          label: x.name,
        })),
      );
    }
  }, []);

  useEffect(() => {
    if (typeChanged) {
      setSelectedCapabilities([]);
    }

    if (type) {
      capabilitiesSvc.getAll(type).then(setCapabilities);
    } else {
      setCapabilities([]);
    }
  }, [type]);

  return (
    <Modal size="lg" show onHide={hide} animation>
      <Modal.Header
        closeButton
        closeVariant={store.profile.dark_mode ? "white" : ""}
      >
        <Modal.Title className="ps-4">
          {!editMode ? "Create" : "Update"} Facility
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="popup__form">
          <div className="popup__fieldset">
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label ${touched && !type && "invalid"}`}
                >
                  Facility Type
                </div>
                <div className="field__wrap">
                  <Select
                    options={facilitiesWithHospitalList.map((facility) => ({
                      value: facility.value,
                      label: facility.title,
                    }))}
                    placeholder="Select a facility type"
                    value={selectedType}
                    onChange={(option) => {
                      setTypeChanged(true);
                      setSelectedType(option);
                      setType(option.value);
                    }}
                    styles={dropdownStyles(store.profile.dark_mode)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div
                  className={`field__label ${touched && !group && "invalid"}`}
                >
                  Facility Group
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={group}
                    onChange={(e) => setGroup(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label ${touched && !name && "invalid"}`}
                >
                  Facility Name
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div
                  className={`field__label ${
                    touched && !locationName && "invalid"
                  }`}
                >
                  Location Name
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label ${
                    touched && !address1 && "invalid"
                  }`}
                >
                  Address 1
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div className="field__label">Address 2</div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label ${touched && !country && "invalid"}`}
                >
                  Country
                </div>
                <div className="field__wrap">
                  <CountriesComboBox
                    selected={selectedCountry}
                    change={(val) => {
                      setCountry(val);
                      setSelectedCountry({
                        value: val,
                        label: val,
                      });

                      setState("");
                      setSelectedState(null);

                      setCity("");
                      setSelectedCity(null);
                    }}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div
                  className={`field__label ${touched && !state && "invalid"}`}
                >
                  State
                </div>
                <div className="field__wrap">
                  <StatesComboBox
                    country={country}
                    selected={selectedState}
                    change={(val) => {
                      setState(val);
                      setSelectedState({
                        value: val,
                        label: val,
                      });

                      setCity("");
                      setSelectedCity(null);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label ${touched && !city && "invalid"}`}
                >
                  City
                </div>
                <div className="field__wrap">
                  <CitiesComboBox
                    country={country}
                    state={state}
                    selected={selectedCity}
                    change={(val) => {
                      setCity(val);
                      setSelectedCity({
                        value: val,
                        label: val,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div className={`field__label ${touched && !zip && "invalid"}`}>
                  Zip
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label ${
                    touched && !longitude && "invalid"
                  }`}
                >
                  Longitude
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="number"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div
                  className={`field__label ${
                    touched && !latitude && "invalid"
                  }`}
                >
                  Latitude
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="number"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label ${
                    touched && !domainNames && "invalid"
                  }`}
                >
                  Domain Names
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    type="text"
                    placeholder="microsoft.com, gmail.com"
                    value={domainNames}
                    onChange={(e) => setDomainNames(e.target.value)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div className="field__label">Capabilities</div>
                <div className="field__wrap">
                  <Select
                    options={capabilities.map((facility) => ({
                      value: facility.id,
                      label: facility.name,
                    }))}
                    value={selectedCapabilities}
                    onChange={(options) => {
                      setSelectedCapabilities(options);
                    }}
                    isMulti
                    placeholder="Select capabilities"
                    noOptionsMessage={() =>
                      type
                        ? "Select any other facility type"
                        : "Select a facility type"
                    }
                    styles={dropdownStyles(store.profile.dark_mode)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <button
              className="btn btn-dark"
              type="button"
              onClick={createFacility}
            >
              {!editMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CreateFacility;
