import medicalAppImg from "../assets/img/medical-app-1.png";
import {
  circleChartGreen,
  circleChartPurple,
  circleChartRed,
  circleChartYellow,
} from "../constants/chartOptions";
import { facilities } from "../constants/facilities";
import { formatCurrency } from "./conversion";

const calendarColors = ["purple", "pink", "blue-light", "purple-light", "blue"];
const dayDivision = 100 / 7;
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const recentContractColors = ["blue", "pink", "yellow"];
const sectionOneColors = ["pink", "purple", "blue", "yellow"];

const chartOptions = [
  circleChartYellow,
  circleChartPurple,
  circleChartGreen,
  circleChartRed,
];

const currentDate = new Date();

export const contractStatuses = [
  "Pending",
  "Accepted",
  "Approved",
  "Placed",
  "Declined",
  "In Revision",
];

export const getActiveContracts = (contracts) =>
  contracts
    .slice(0)
    .map((contract) => {
      const startDate = new Date(contract.start_date);

      const daysElapsed = Math.ceil(
        (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      );

      const progress = Math.round(
        Math.min((daysElapsed / contract.contract_length) * 100, 100),
        2,
      );

      return {
        ...contract,
        daysElapsed,
        progress,
      };
    })
    .filter(
      (contract) =>
        contract.daysElapsed >= 0 &&
        contract.daysElapsed <= contract.contract_length,
    );

export const getCalendarContracts = (contracts) => {
  const currentWeekStartDate = new Date();
  currentWeekStartDate.setHours(0, 0, 0, 0);
  currentWeekStartDate.setDate(
    currentWeekStartDate.getDate() - currentWeekStartDate.getDay(),
  );

  const currentWeekLastDate = new Date(
    currentWeekStartDate.getFullYear(),
    currentWeekStartDate.getMonth(),
    currentWeekStartDate.getDate() + 6,
  );

  const result = contracts
    .slice(0)
    .sort((a, b) =>
      new Date(a.start_date).getTime() > new Date(b.start_date).getTime()
        ? 1
        : -1,
    )
    .filter((contract) => {
      const startDate = new Date(contract.start_date);

      if (startDate > currentWeekLastDate) {
        return false;
      }

      const daysElapsed = Math.floor(
        (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      );

      const daysLeft = contract.contract_length - daysElapsed;

      return daysLeft > 0;
    })
    .slice(0, 5)
    .map((contract, idx) => {
      const startDate = new Date(contract.start_date);

      const daysElapsed = Math.floor(
        (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      );

      const daysLeft = contract.contract_length - daysElapsed;

      if (startDate < currentWeekStartDate) {
        startDate.setDate(currentWeekStartDate.getDate());
        startDate.setMonth(currentWeekStartDate.getMonth());
        startDate.setFullYear(currentWeekStartDate.getFullYear());
      }

      const lastDate = new Date(contract.start_date);
      lastDate.setDate(lastDate.getDate() + contract.contract_length);

      if (lastDate > currentWeekLastDate) {
        lastDate.setDate(currentWeekLastDate.getDate() + 1);
        lastDate.setMonth(currentWeekLastDate.getMonth());
        lastDate.setFullYear(currentWeekLastDate.getFullYear());
      }

      const daysToShow = Math.min(
        7,
        Math.floor(
          (lastDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
        ),
      );

      return {
        schedules__text: contract.patient_name,
        daysLeft,
        bg: `bg-${calendarColors[idx % calendarColors.length]}`,
        style: {
          marginLeft: `${dayDivision * startDate.getDay()}%`,
          width: `${dayDivision * daysToShow}%`,
        },
      };
    });

  Array(Math.max(0, 5 - result.length))
    .fill(null)
    .forEach(() => result.push({}));

  return result;
};

export const getContractsByFacility = (contracts) => {
  const result = contracts
    .filter((x) => x.facility_name !== null)
    .reduce((acc, contract) => {
      const group = acc.find((g) => g.title === contract.facility_name);

      if (group) {
        group.contract_rate += contract.contract_rate;
      } else {
        acc.push({
          img: medicalAppImg,
          title: contract.facility_name,
          contract_rate: contract.contract_rate,
          className: `tabs__item bg-${
            recentContractColors[acc.length % recentContractColors.length]
          }`,
        });
      }

      return acc;
    }, []);

  return result.map((contract) => ({
    ...contract,
    title: facilities[contract.title] || contract.title,
    tabs__price: formatCurrency(contract.contract_rate),
  }));
};

export const getContractsByType = (contracts) => {
  const result = contracts.reduce((acc, contract) => {
    const group = acc.find((g) => g.title === contract.contract_type);

    if (group) {
      group.contract_rate += contract.contract_rate;
    } else {
      acc.push({
        img: medicalAppImg,
        title: contract.contract_type,
        contract_rate: contract.contract_rate,
        className: `tabs__item bg-${
          recentContractColors[acc.length % recentContractColors.length]
        }`,
      });
    }

    return acc;
  }, []);

  return result.map((contract) => ({
    ...contract,
    title: facilities[contract.title] || contract.title,
    tabs__price: formatCurrency(contract.contract_rate),
  }));
};

export const getContractsProgress = (contracts) => {
  const result = contracts
    .slice(0)
    .sort((a, b) => (a.progress > b.progress ? 1 : -1))
    .map((contract, idx) => ({
      img: medicalAppImg,
      chart_options: chartOptions[idx],
      title: contract.patient_name,
      info: facilities[contract.contract_type] || contract.contract_type,
      progress: contract.progress,
    }));

  Array(Math.max(0, 10 - result.length))
    .fill(null)
    .forEach(() => result.push({}));

  return result;
};

export const getMostRecentContracts = (contracts) =>
  contracts
    .slice(0)
    .sort((a, b) => {
      if (new Date(a.timestamp).getTime() === new Date(b.timestamp).getTime()) {
        return a.id - b.id;
      }

      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    })
    .slice(0, 3)
    .map((contract, idx) => ({
      img: medicalAppImg,
      title: contract.patient_name,
      caption: facilities[contract.contract_type] || contract.contract_type,
      tabs__price: formatCurrency(contract.contract_rate),
      className: `tabs__item bg-${
        recentContractColors[idx % recentContractColors.length]
      }`,
    }));

export const getSchedulesSectionOneContracts = (contracts) => {
  const result = contracts
    .slice(0)
    .sort((a, b) =>
      new Date(a.start_date).getTime() > new Date(b.start_date).getTime()
        ? 1
        : -1,
    )
    .slice(0, 3)
    .map((contract, idx) => {
      const startDate = new Date(contract.start_date);
      const day = days[startDate.getDay()];
      const date = startDate.getDate().toString();

      return {
        day,
        date,
        patient_name: contract.patient_name,
        contract_type:
          facilities[contract.contract_type] || contract.contract_type,
        bg: `bg-${sectionOneColors[idx % sectionOneColors.length]}-light`,
      };
    });

  Array(Math.max(0, 3 - result.length))
    .fill(null)
    .forEach(() => result.push({}));

  return result;
};

export const getWeeklySum = (contracts) => {
  currentDate.setHours(0, 0, 0, 0);
  currentDate.setDate(currentDate.getDate() - currentDate.getDay());

  const startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 28,
  );

  const endDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 6,
  );

  const weeklySums = [0, 0, 0, 0, 0];

  contracts.forEach((contract) => {
    const contractStartDate = new Date(contract.start_date);

    if (contractStartDate >= startDate && contractStartDate <= endDate) {
      const weekIndex = Math.abs(
        Math.ceil(
          (currentDate - contractStartDate) / (7 * 24 * 60 * 60 * 1000),
        ),
      );

      weeklySums[weekIndex] += contract.contract_rate;
    }
  });

  return {
    startDate,
    endDate,
    sums: weeklySums.reverse(),
  };
};
