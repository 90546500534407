import { useState } from "react";
import TextInput from "../components/inputs/TextInput";

function Settings() {
  const [currentTab, setCurrentTab] = useState("profile");

  const arrInput = [
    {
      label: "Your Full Name",
      type: "text",
    },
    {
      label: "Display Name",
      type: "text",
    },
    {
      label: "Role ",
      type: "text",
    },
    {
      label: "Location ",
      type: "text",
    },
    {
      label: "Role ",
      type: "text",
    },
    {
      label: "Team ",
      type: "text",
    },
  ];
  return (
    <div>
      <div className="popup mfp-show" id="popup-settings">
        <form className="popup__form">
          <div className="popup__title h6">Account Settings</div>
          <div className="popup__tag">
            <button
              className={`popup__tag ${
                currentTab === "profile" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("profile")}
              type="button"
            >
              Profile
            </button>
            <button
              className={`popup__tag ${
                currentTab === "password" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("password")}
              type="button"
            >
              Password
            </button>
            <button
              className={`popup__tag ${currentTab === "email" ? "active" : ""}`}
              onClick={() => setCurrentTab("email")}
              type="button"
            >
              Email
            </button>
            <button
              className={`popup__tag ${
                currentTab === "notifications" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("notifications")}
              type="button"
            >
              Notification
            </button>
            <button
              className={`popup__tag ${
                currentTab === "settings" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("settings")}
              type="button"
            >
              Settings
            </button>
          </div>
          <select className="popup__select mobile-show">
            <option>Profile</option>
            <option>Password</option>
            <option>Email</option>
            <option>Notification</option>
            <option>Settings</option>
          </select>
          <div className="popup__user">
            <div className="popup__title h6 mobile-show">Your Profile</div>
            <div className="popup__category caption-sm">Your Avatar</div>
            <div className="popup__line">
              <div className="popup__ava">
                <img className="popup__pic" src="./assets/img/ava.png" alt="" />
              </div>
              <div className="popup__details">
                <div className="popup__btns">
                  <div className="popup__loading">
                    <input className="popup__file" type="file" />
                    <button type="button" className="popup__btn btn btn_purple">
                      Upload New
                    </button>
                  </div>
                  <button type="button" className="popup__btn btn btn_gray">
                    Delete Avatar
                  </button>
                </div>
                <div className="popup__text">
                  Avatar help your teammates recognize you in Unity.
                </div>
              </div>
            </div>
          </div>
          <div className="popup__fieldset">
            <div className="w-full grid grid-cols-2 gap-4">
              {arrInput?.map((data) => (
                <TextInput data={data} />
              ))}
            </div>
            <div className="popup__field field">
              <div className="field__label">Bio</div>
              <div className="field__wrap">
                <textarea className="field__textarea" defaultValue="" />
              </div>
            </div>
          </div>
          <button className="popup__btn btn btn_black" type="submit">
            Update Profile
          </button>
        </form>
      </div>
    </div>
  );
}

export default Settings;
