import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Select from "react-select";
import { dropdownStyles } from "../../constants/styles";
import countriesNowSvc from "../../services/countriesNowService";
import store from "../../store";

const CitiesComboBox = observer(({ country, state, selected, change }) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      let options = [];

      if (!country) {
        setCities(options);
        return;
      }

      if (!state) {
        options = await countriesNowSvc.fetchCitiesByCountry(country);
        setCities(options);
        return;
      }

      options = await countriesNowSvc.fetchCitiesByState(country, state);
      setCities(options);
    };

    setCities([]);
    fetchCities();
  }, [country, state]);

  const handleChange = (selectedOption) => {
    change(selectedOption ? selectedOption.value : "");
  };

  let placeholder;

  if (state) {
    placeholder = "Select a city";
  } else if (country) {
    placeholder = "Select a state first";
  } else {
    placeholder = "Select a country first";
  }

  return (
    <Select
      options={cities.map((city) => ({
        value: city,
        label: city,
      }))}
      value={selected}
      onChange={handleChange}
      placeholder={placeholder}
      isDisabled={!country || !state}
      isClearable
      isSearchable
      noOptionsMessage={() => "Type to search..."}
      styles={dropdownStyles(store.profile.dark_mode)}
    />
  );
});

export default CitiesComboBox;
