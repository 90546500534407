import { EventType, PublicClientApplication } from "@azure/msal-browser";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { msalConfig } from "./config/auth-config";
import App from "./Routes/App";
import { authSvc } from "./services/authService";
import { profileSvc } from "./services/profileService";
import { register } from "./serviceWorkerRegistration";

import "./Style/index.css";
import "bootstrap/dist/css/bootstrap.min.css";

const msalInstance = new PublicClientApplication(msalConfig);

if (msalInstance.getActiveAccount()) {
  authSvc.setIsAuthenticated(true);
}

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
    authSvc.setIsAuthenticated(true);

    profileSvc.signIn();
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    if (msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  }

  if (event.eventType === EventType.LOGIN_FAILURE) {
    // TODO: Show appropriate error message here
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App instance={msalInstance} />
  </BrowserRouter>,
);

register();
