import PAGE_SIZE from "../constants/pagination";
import apiSvc from "./apiService";

class PatientService {
  static addRedoxPatient(patient) {
    return apiSvc.post("/patients/redox/copy", patient);
  }

  static getDrugSuggestedRates(hospitalId, id) {
    return apiSvc.get(
      `/patients/drug-suggested-rates?hospital_id=${hospitalId}&id=${id}`,
    );
  }

  static getPatient(hospitalId, id) {
    return apiSvc.get(`/patients/by-id?hospital_id=${hospitalId}&id=${id}`);
  }

  static getByDate(startDate, endDate, pageNumber = 1, search = "") {
    return apiSvc.get(
      `/patients/by-date?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}&pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}&search=${search}`,
    );
  }

  static getLookup(hospitalId) {
    return apiSvc.get(`/patients/lookup?hospital_id=${hospitalId}`);
  }

  static getRedoxPatientById(id) {
    return apiSvc.post("/patients/redox/id", {
      id,
    });
  }

  static getRedoxPatientsByDemographic(givenName, surName, birthDate) {
    return apiSvc.post("/patients/redox/demographic", {
      given: givenName,
      family: surName,
      birthdate: birthDate,
    });
  }
}

export default PatientService;
