import apiSvc from "./apiService";

class FinancialService {
  static getHospitalDashboard(startDate, endDate, type, id) {
    return apiSvc.get(
      `/financial/hospital-dashboard?facility_type=${type || ""}` +
        `&facility_id=${id || ""}` +
        `&start_date=${startDate.toISOString()}` +
        `&end_date=${endDate.toISOString()}`,
    );
  }
}

export default FinancialService;
