import apiSvc from "./apiService";

class ScheduleService {
  static getHospitalDashboard(startDate, endDate, type, id) {
    return apiSvc.get(
      `/schedule/hospital-dashboard?&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}&facility_type=${type}&facility_id=${id}`,
    );
  }
}

export default ScheduleService;
