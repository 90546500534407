import $ from "jquery";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import Select from "react-select";
import { v4 as idV4 } from "uuid";
import { utils, writeFile } from "xlsx";
import medicalAppImage from "../assets/img/medical-app-1.png";
import { downloadIcon } from "../assets/svg/exportSvg";
import DetailsItem from "../components/DetailsItem";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import TopItem from "../components/TopItem";
import Btn from "../components/buttons/Btn";
import DateRange from "../components/inputs/DateRange";
import Dropdown from "../components/inputs/Dropdown";

import {
  facilities,
  facilitiesList,
  patientTrendChartOptions,
} from "../constants";
import { dropdownStyles } from "../constants/styles";
import ContractService from "../services/contractService";
import facilitiesSvc from "../services/facilitiesService";
import financialSvc from "../services/financialService";
import store from "../store";
import { fixIconStyles, formatCurrency, formatDate } from "../utils";

const borderColor = "#E4E4E4";
const blue = "#A0D7E7";
const purple = "#6C5DD3";
const pink = "#FFB7F5";

const colors = ["blue", "pink", "yellow", "green"];
const expenseByContractTypeColors = [purple, pink, blue, borderColor];

const currentYear = new Date().getFullYear();
const years = [currentYear - 1, currentYear];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

let recentContracts = [];

const detailsList = [
  {
    img: "./assets/img/folder.svg",
    bg: "bg-purple",
    detailsCounter: "$0",
    detailsProgress: "0%",
  },
  {
    img: "./assets/img/activity.svg",
    bg: "bg-pink",
    detailsCounter: "$0",
    detailsProgress: "0%",
  },
  {
    img: "./assets/img/bag.svg",
    bg: "bg-blue",
    detailsCounter: "$0",
    detailsProgress: "0%",
  },
  {
    img: "./assets/img/folder.svg",
    bg: "bg-green",
    detailsCounter: "$0",
    detailsProgress: "0%",
  },
];

const Statement = observer(() => {
  const [expenseChart, setExpenseChart] = useState([]);
  const [facilitiesLookup, setFacilitiesLookup] = useState([]);
  const [financial, setFinancial] = useState({});
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedFacilityType, setSelectedFacilityType] = useState(
    facilitiesList[0],
  );
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    key: "selection",
  });

  const exportToXLSX = (filename, data) => {
    const sheetName = "Statement";
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(data);

    worksheet["!cols"] = [
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    utils.book_append_sheet(workbook, worksheet, sheetName);
    writeFile(workbook, `${filename}.xlsx`);
  };

  const fetchDashboard = () =>
    financialSvc
      .getHospitalDashboard(
        dateRange.startDate,
        dateRange.endDate,
        selectedFacilityType ? selectedFacilityType.value : null,
        selectedFacility ? selectedFacility.value : null,
      )
      .then((resp) => {
        if (resp) {
          setFinancial(resp);
        }
      });

  const handleAcceptDateRange = () => fetchDashboard();

  const handleSelectDateRange = (ranges) => {
    setDateRange(ranges.selection);
  };

  const saveAsXlsx = () =>
    exportToXLSX(
      "Statement",
      recentContracts.map((x) => ({
        Patient: x.orderId,
        "Contract Type": x.type,
        "Start Date": x.date,
        "Contract Length": x.length,
        "Contract Cost": formatCurrency(x.amount),
      })),
    );

  useEffect(() => {
    ContractService.getExpenseChart().then((val) => {
      years.forEach((year) => {
        months.forEach((month) => {
          const item = val.find((d) => d.year === year && d.month === month);

          if (!item) {
            val.push({ year, month, expense: 0 });
          }
        });
      });

      setExpenseChart(
        val.sort((a, b) => {
          if (a.year === b.year) {
            return a.month - b.month;
          }

          return a.year - b.year;
        }),
      );
    });
  }, []);

  useEffect(() => {
    if (!selectedFacility) {
      fetchDashboard();
    }

    setSelectedFacility(null);
    facilitiesSvc
      .getLookup(selectedFacilityType.value)
      .then(setFacilitiesLookup);
  }, [selectedFacilityType]);

  useEffect(() => {
    fetchDashboard();
  }, [selectedFacility]);

  const expenseChartSeries = [
    {
      name: `${currentYear - 1}`,
      type: "column",
      data: expenseChart
        .filter((x) => x.year === currentYear - 1)
        .map((x) => x.expense),
    },
    {
      name: `${currentYear}`,
      type: "column",
      data: expenseChart
        .filter((x) => x.year === currentYear)
        .map((x) => x.expense),
    },
  ];

  const optionsMixedChart = {
    colors: [purple],
    chart: {
      height: "100%",
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      borderColor,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  const seriesMixedChart = [
    {
      name: "series-1",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "series-1",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
  ];

  const expenseByContractTypeChartOptions = {
    colors: expenseByContractTypeColors,
    chart: {
      height: "100%",
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "71%",
          polygons: {
            strokeWidth: 0,
          },
        },
        expandOnClick: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const checkboxAll = () => {
    const checkboxAllElem = $(".products__row_head .checkbox__input");
    if (checkboxAllElem.is(":checked")) {
      checkboxAllElem
        .parents(".products__table")
        .find(".products__row:not(.products__row_head) .checkbox__input")
        .prop("checked", true)
        .attr("checked", "checked");
    } else {
      checkboxAllElem
        .parents(".products__table")
        .find(".products__row:not(.products__row_head) .checkbox__input")
        .prop("checked", false)
        .removeAttr("checked");
    }
  };

  let totalExpense = 0;
  let summaryData = [];
  let mostExpensiveContracts = [];
  let combinedExpense = 0;

  if (financial.total_expense) {
    totalExpense = financial.total_expense;
    summaryData = financial.cost_by_type;
    recentContracts = financial.recent_contracts;
    mostExpensiveContracts = financial.most_expensive_contracts;
    combinedExpense = financial.cost_by_type.reduce(
      (sum, obj) => sum + obj.total_cost,
      0,
    );
  }

  let expenseByContractTypeChartData = [];

  if (summaryData.length > 0) {
    const totalCost = summaryData
      .map((x) => x.total_cost)
      .reduce((a, b) => a + b);

    const expensiveFirst = summaryData.sort((a, b) =>
      a.total_cost > b.total_cost ? -1 : 1,
    );

    expensiveFirst.slice(0, 4).forEach((x, i) => {
      detailsList[i].detailsText =
        facilities[x.contract_type] || x.contract_type;
      detailsList[i].detailsCounter = formatCurrency(x.total_cost);
      detailsList[i].detailsProgress = `${(100 * x.total_cost) / totalCost}%`;
    });

    expenseByContractTypeChartData = expensiveFirst.map((x) => ({
      type: facilities[x.contract_type] || x.contract_type,
      cost: x.total_cost,
    }));
  }

  mostExpensiveContracts = mostExpensiveContracts.map((x, idx) => ({
    a_class: "quality__item",
    img: medicalAppImage,
    title: x.patient_name,
    caption: facilities[x.contract_type] || x.contract_type,
    tabs__price: formatCurrency(x.contract_rate),
    className: ` bg-${colors[idx % colors.length]}`,
  }));

  recentContracts = recentContracts.map((x) => ({
    date: formatDate(x.start_date),
    orderId: x.patient_name,
    amount: x.contract_rate,
    length: x.contract_length,
    type: facilities[x.contract_type] || x.contract_type,
  }));

  return (
    <div>
      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head page__row_border">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome page__welcome_sm h2">
                  Financials Report
                </div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__row page__row_border">
              <div className="page__col">
                <div
                  className="details__container"
                  style={{
                    borderRadius: 24,
                    display: "flex",
                    flexDirection: "column",
                    padding: 20,
                    marginBottom: 20,
                  }}
                >
                  <DateRange
                    dateRange={dateRange}
                    selectDateRange={handleSelectDateRange}
                    acceptDateRange={handleAcceptDateRange}
                  />
                  <div
                    style={{
                      display: "flex",
                      marginTop: 20,
                      justifyContent: "space-between",
                    }}
                  >
                    <Dropdown
                      data={{
                        selected: selectedFacilityType,
                        option_list: facilitiesList,
                        selectOption: setSelectedFacilityType,
                      }}
                    />
                    <Select
                      options={facilitiesLookup.map((x) => ({
                        value: x.id,
                        label: x.facility_name,
                      }))}
                      placeholder="Select a Facility"
                      value={selectedFacility}
                      onChange={setSelectedFacility}
                      isClearable
                      noOptionsMessage={() => "Select any other facility type"}
                      styles={{
                        ...dropdownStyles(store.profile.dark_mode),
                        control: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(store.profile.dark_mode).control(
                            baseStyles,
                          ),
                          borderRadius: 15,
                          marginLeft: 20,
                          width: 320,
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(
                            store.profile.dark_mode,
                          ).placeholder(baseStyles),
                          fontSize: 16,
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(
                            store.profile.dark_mode,
                          ).singleValue(baseStyles),
                          color: "#808191",
                          fontSize: 16,
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="details details_statements">
                  <div className="details__container">
                    <div className="details__title h6 mobile-hide">
                      Expense Report
                    </div>
                    <div className="details__row">
                      <div className="details__col">
                        <div className="details__top">
                          <div className="details__number h1">
                            {formatCurrency(totalExpense)}
                          </div>
                          <Link className="details__line" to={{}}>
                            <div className="details__preview">
                              <img
                                className="details__pic"
                                src="./assets/img/details-pic-1.png"
                                alt=""
                              />
                            </div>
                            <div className="details__info caption-sm">
                              Total expenses this month
                            </div>
                          </Link>
                        </div>
                        <div className="details__bottom">
                          <div className="details__statistics">
                            <div className="details__chart details__chart_activity">
                              <Chart
                                options={optionsMixedChart}
                                series={seriesMixedChart}
                                type="line"
                                width="100%"
                                height="100%"
                              />
                            </div>
                            <div className="details__status">
                              <div className="details__icon bg-green">
                                <svg
                                  className="icon icon-arrow-up-fat"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="8"
                                  height="6"
                                  viewBox="0 0 8 6"
                                >
                                  <path
                                    fill="#FFF"
                                    d="M0.626241893,0.959554545 C0.986725179,0.599069907 1.55395618,0.571339308 1.94624796,0.876363463 L2.04045546,0.959551893 L4,2.919 L5.95958454,0.959551893 C6.32006918,0.599068607 6.88730029,0.571340135 7.27959093,0.876365761 L7.37379811,0.959554545 C7.73428139,1.32003918 7.76200986,1.88727029 7.45698424,2.27956093 L7.37379546,2.37376811 L4.70712546,5.04042811 C4.34664177,5.40091044 3.77941248,5.42863985 3.38712178,5.12361634 L3.29291454,5.04042811 L0.626244545,2.37376811 C0.235719521,1.98324455 0.235718333,1.35007957 0.626241893,0.959554545 Z"
                                    transform="rotate(180 4 3)"
                                  />
                                </svg>
                              </div>
                              <div className="details__percent caption-sm color-green">
                                6%
                              </div>
                            </div>
                          </div>
                          <div className="details__info caption-sm">
                            Update your report preferences in settings
                          </div>
                        </div>
                      </div>
                      <div className="details__col">
                        <div className="details__chart details__chart_report">
                          <Chart
                            options={{
                              ...patientTrendChartOptions,
                              yaxis: {
                                labels: {
                                  formatter: (value) => formatCurrency(value),
                                },
                              },
                            }}
                            series={expenseChartSeries}
                            height="100%"
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="details__list details__list_four">
                      {detailsList.map((data) => (
                        <DetailsItem key={idV4()} data={data} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="page__stat page__stat_sm">
                  <div className="sorting mobile-hide">
                    <div className="sorting__actions">
                      <button
                        type="button"
                        className="sorting__action"
                        onClick={saveAsXlsx}
                      >
                        {fixIconStyles(downloadIcon, store.profile.dark_mode)}
                      </button>
                    </div>
                  </div>
                  <div className="products products_statement">
                    <div className="products__title h6 mobile-hide">
                      Statement
                    </div>
                    <div className="products__info caption mobile-hide">
                      Please note: Transactions are based Time in California,
                      USA.
                    </div>
                    <div className="products__table">
                      <div className="products__row products__row_head">
                        <div className="products__cell">
                          <label htmlFor="checkboxInput" className="checkbox">
                            <input
                              className="checkbox__input"
                              type="checkbox"
                              onClick={() => checkboxAll()}
                            />
                            <span className="checkbox__in">
                              <span className="checkbox__tick" />
                            </span>
                          </label>
                        </div>
                        <div className="products__cell">Date</div>
                        <div className="products__cell">Patient</div>
                        <div className="products__cell">Cost</div>
                        <div className="products__cell">Length (Days)</div>
                        <div className="products__cell text-right">Type</div>
                      </div>
                      {recentContracts.map((data) => {
                        const { date, orderId, amount, type, length } = data;
                        return (
                          <>
                            <div className="products__row">
                              <div className="products__cell">
                                <label
                                  htmlFor="checkboxInput"
                                  className="checkbox"
                                >
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                  />
                                  <span className="checkbox__in">
                                    <span className="checkbox__tick" />
                                  </span>
                                </label>
                              </div>
                              <div className="products__cell color-gray">
                                {date}
                              </div>
                              <div className="products__cell">{orderId}</div>
                              <div className="products__cell">
                                {formatCurrency(amount)}
                              </div>
                              <div className="products__cell color-red">
                                {length}
                              </div>
                              <div className="products__cell text-right">
                                <div className="products__status caption bg-green">
                                  {type}
                                </div>
                              </div>
                            </div>
                            <div className="products__body">
                              <div className="products__bg bg-green" />
                              <div className="products__line">
                                <div className="products__col color-gray">
                                  {date}
                                </div>
                                <div className="products__col">
                                  {formatCurrency(amount)}
                                </div>
                                <div className="products__col color-red">
                                  {length}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="products__more">
                      <Link to="/contracts">
                        <Btn
                          data={{
                            label: "Show All Contracts",
                            className: "products__btn btn-dark ",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page__col">
                <div className="page__group">
                  <div className="widget widget_stat widget_shadow widget_after">
                    <div className="widget__chart widget__chart_items">
                      <Chart
                        options={expenseByContractTypeChartOptions}
                        series={expenseByContractTypeChartData.map(
                          (x) => x.cost,
                        )}
                        width="100%"
                        height="100%"
                        type="donut"
                      />
                    </div>
                    <div className="widget__title">
                      Expense by Contract Type
                    </div>
                    <div className="widget__counter title title_md">
                      {formatCurrency(combinedExpense)}
                    </div>
                    <div className="widget__text widget__text_mb40">
                      Export Excel Report
                    </div>
                    <div className="widget__legend">
                      {expenseByContractTypeChartData.map((x) => (
                        <div key={idV4()} className="widget__color">
                          <div
                            className="widget__bg"
                            style={{
                              background:
                                expenseByContractTypeColors[
                                  expenseByContractTypeColors.length %
                                    expenseByContractTypeColors.length
                                ],
                            }}
                          />
                          <div className="widget__text">{x.type}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="widget widget_shadow widget_after">
                    <div className="widget__title mr-auto">
                      Most Expensive Contracts
                    </div>
                    <div className="quality quality_big">
                      <div className="quality__list">
                        {mostExpensiveContracts.map((data) => (
                          <TopItem data={data} key={idV4()} />
                        ))}
                      </div>
                      <Link to="/contracts">
                        <Btn
                          data={{
                            label: "Show all contracts",
                            className: "quality__btn btn-dark btn_wide",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Statement;
