export const notifications = [
  {
    id: "inbox",
    title: "Inbox",
    value: true,
  },
  {
    id: "new_contract",
    title: "New Contract",
    value: true,
  },
  {
    id: "contract_acceptance",
    title: "Contract Accepted",
    value: true,
  },
  {
    id: "contract_approval",
    title: "Contract Approval",
    value: true,
  },
  {
    id: "contract_refusal",
    title: "Contract Refused",
    value: true,
  },
];

export default notifications;
