import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import { v4 as idV4 } from "uuid";
import { baseFields } from "../constants/accountSettingsFields";
import { notifications as baseNotifications } from "../constants/notifications";
import { ProfileService } from "../services/profileService";
import store from "../store";
import { deepClone } from "../utils";
import TextInput from "./inputs/TextInput";

const AccountSettings = observer(({ close }) => {
  const [avatarFile, setAvatarFile] = useState(null);
  const [currentTab, setCurrentTab] = useState("profile");
  const [fields, setFields] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const handleDeleteAvatar = async () => {
    store.profile.avatar = null;
    await ProfileService.deleteAvatar();
    setAvatarFile(null);
  };

  const handleDropdownChange = (ev, field) => {
    const copy = deepClone(fields);
    const fieldIdx = copy.findIndex((x) => x.key === field.key);
    copy[fieldIdx].value = ev.target.value;
    copy[fieldIdx].dirty = true;

    setFields(copy);
  };

  const handleFieldChange = (ev, field) => {
    const copy = deepClone(fields);
    const fieldIdx = copy.findIndex((x) => x.key === field.key);
    copy[fieldIdx].value = ev.target.value;
    copy[fieldIdx].dirty = true;
    setFields(copy);
  };

  const handleFileChange = (ev) => {
    const file = ev.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const imgSrc = reader.result;
      setAvatarFile({ file, imgSrc });
    };

    reader.readAsDataURL(file);
  };

  const handleNotificationChange = async (value, notification) => {
    const copy = deepClone(notifications);
    const idx = copy.findIndex((x) => x.id === notification.id);
    copy[idx].value = value;
    copy[idx].dirty = true;
    setNotifications(copy);

    await ProfileService.updateNotification(notification.id, value);

    const updatedProfile = await ProfileService.getMyProfile();
    store.setProfile(updatedProfile);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    let avatar;

    if (avatarFile && avatarFile.file) {
      avatar = await ProfileService.uploadAvatar(avatarFile.file);
    } else if (store.profile.avatar) {
      [avatar] = store.profile.avatar.split("?se=");
    } else {
      avatar = "";
    }

    store.profile = fields.reduce(
      (obj, item) => ({
        ...obj,
        [item.key]: item.value,
      }),
      {
        ...deepClone(store.profile),
        avatar,
      },
    );

    await ProfileService.updateCompleteAccountSettings(store.profile);

    const updatedProfile = await ProfileService.getMyProfile();
    store.setProfile(updatedProfile);

    setFields(
      fields.slice(0).map((field) => ({
        ...field,
        dirty: false,
      })),
    );

    close();
  };

  const updateFieldValues = (fieldsList) => {
    const copy = deepClone(fieldsList);

    Object.keys(store.profile).forEach((key) => {
      const field = copy.find((x) => x.key === key);

      if (field) {
        if (store.profile[key]) {
          field.value = store.profile[key];
        }
      }
    });

    setFields(copy);

    const { avatar } = store.profile;
    if (avatar && avatar.indexOf("?se") !== 0) {
      setAvatarFile({ imgSrc: avatar });
    }
  };

  const updateNotificationFlags = (notificationsList) => {
    const { notifications: userNotifications } = store.profile;

    if (userNotifications) {
      Object.keys(userNotifications).forEach((key) => {
        const notification = notificationsList.find((x) => x.id === key);
        if (notification) {
          notification.value = userNotifications[key];
        }
      });
    }

    setNotifications(notificationsList);
  };

  useEffect(() => {
    updateFieldValues(baseFields);
    updateNotificationFlags(baseNotifications);
  }, []);

  return (
    <Modal size="lg" show onHide={close} animation>
      {store.profile.dark_mode ? (
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Account Settings</Modal.Title>
        </Modal.Header>
      ) : (
        <Modal.Header closeButton>
          <Modal.Title>Account Settings</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <form className="popup__form" onSubmit={handleSubmit}>
          <div className="popup__tags">
            <button
              className={`popup__tag ${
                currentTab === "profile" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("profile")}
              type="button"
            >
              Profile
            </button>
            {/* <button
              className={`popup__tag ${
                currentTab === 'password' ? 'active' : ''
              }`}
              onClick={() => setCurrentTab('password')}
              type='button'
            >
              Password
            </button>
            <button
              className={`popup__tag ${
                currentTab === 'emails' ? 'active' : ''
              }`}
              onClick={() => setCurrentTab('emails')}
              type='button'
            >
              Emails
            </button> */}
            <button
              className={`popup__tag ${
                currentTab === "notifications" ? "active" : ""
              }`}
              onClick={() => setCurrentTab("notifications")}
              type="button"
            >
              Notifications
            </button>
            {/* <button
              className={`popup__tag ${
                currentTab === 'settings' ? 'active' : ''
              }`}
              onClick={() => setCurrentTab('settings')}
              type='button'
            >
              Settings
            </button> */}
          </div>
          <select className="popup__select mobile-show">
            <option>Profile</option>
            {/* <option>Password</option> */}
            {/* <option>Email</option> */}
            <option>Notification</option>
            {/* <option>Settings</option> */}
          </select>
          {currentTab === "profile" && (
            <>
              <div className="popup__user">
                <div className="popup__title h6 mobile-show">Your Profile</div>
                <div className="popup__category caption-sm">Your Avatar</div>
                <div className="popup__line">
                  <div className="popup__ava">
                    <img
                      className="popup__pic"
                      src={
                        avatarFile ? avatarFile.imgSrc : "./assets/img/ava.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className="popup__details">
                    <div className="popup__btns">
                      <div className="popup__loading">
                        <input
                          className="popup__file"
                          type="file"
                          onChange={handleFileChange}
                        />
                        <button
                          type="button"
                          className="popup__btn  btn_purple"
                        >
                          Upload New
                        </button>
                      </div>
                      <button
                        className="popup__btn btn_gray"
                        style={{ borderRadius: 16 }}
                        type="button"
                        onClick={handleDeleteAvatar}
                      >
                        Delete Avatar
                      </button>
                    </div>
                    <div className="popup__text">
                      Avatar help your teammates recognize you in Unity.
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup__fieldset">
                <div className="w-full grid grid-cols-2 gap-4">
                  {fields.map((field) => (
                    <div key={field.key}>
                      {field.type === "text" && (
                        <TextInput
                          key={`text_${idV4()}`}
                          data={field}
                          onChange={(e) => handleFieldChange(e, field)}
                        />
                      )}

                      {field.type === "select" && (
                        <div className="popup__field field">
                          <div className="field__label">{field.label}</div>
                          <div className="field__wrap">
                            <select
                              className="field__input"
                              value={field.value}
                              onChange={(ev) => handleDropdownChange(ev, field)}
                            >
                              {field.options.map((x) => (
                                <option key={x.id} value={x.id}>
                                  {x.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <button className="btn btn-dark" type="submit">
                Update Profile
              </button>
            </>
          )}
          {currentTab === "notifications" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100%",
                width: "400px",
              }}
            >
              {notifications.map((notification) => (
                <div
                  key={notification.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <span>{notification.title}</span>
                  <Switch
                    onColor="#6c5dd3"
                    onChange={(value) =>
                      handleNotificationChange(value, notification)
                    }
                    checked={notification.value}
                  />
                </div>
              ))}
            </div>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default AccountSettings;
