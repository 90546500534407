import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { v4 as idV4 } from "uuid";
import appointmentImage from "../assets/img/appointment-1.png";
import {
  bagIcon,
  folderIcon,
  leftArrowIcon,
  overviewIcon,
  plusSquareIcon,
  rightArrowIcon,
} from "../assets/svg/exportSvg";
import IconProgressItem from "../components/IconProgressItem";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import TopBanner from "../components/TopBanner";
import TopItem from "../components/TopItem";
import Btn from "../components/buttons/Btn";
import CreateContract from "../components/contracts/CreateContract";
import DateRange from "../components/inputs/DateRange";
import Dropdown from "../components/inputs/Dropdown";
import { dropdownStyles } from "../constants";
import { earningsChartOptions } from "../constants/chartOptions";
import { facilitiesWithHospitalList } from "../constants/facilities";
import facilitiesSvc from "../services/facilitiesService";
import scheduleSvc from "../services/scheduleService";
import store from "../store";
import {
  fixIconStyles,
  formatShortDate,
  getActiveContracts,
  getCalendarContracts,
  getContractsByFacility,
  getContractsByType,
  getContractsProgress,
  getMostRecentContracts,
  getSchedulesSectionOneContracts,
  getWeeklySum,
} from "../utils";

const currentDay = new Date().getDay();

const tabsList = [
  {
    text: "Most Recent",
    icon: bagIcon,
  },
  {
    text: "By Contract Type",
    icon: overviewIcon,
  },
  {
    text: "By Facility",
    icon: folderIcon,
  },
  // {
  //   text: 'By Hospital',
  //   icon: bagIcon,
  // },
];

const Schedules = observer(() => {
  const navigate = useNavigate();

  const [contracts, setContracts] = useState([]);
  const [facilitiesLookup, setFacilitiesLookup] = useState([]);
  const [sectionOneSelectedIndex, setSectionOneSelectedIndex] = useState(0);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedFacilityType, setSelectedFacilityType] = useState(
    facilitiesWithHospitalList[0],
  );
  const [showCreateContractModal, setShowCreateContractModal] = useState(false);
  const [sectionFourContractIndex, setSectionFourContractIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    key: "selection",
  });

  let sectionOneContracts = [];
  let calendarContracts = [];
  let contractsProgress = [];
  let sectionFourContracts = [];
  const bannerContent = {
    btnLabel: "Create Contract",
    bannerTitle: "Schedules",
    bannerText: "Patient contract schedules",
    img: appointmentImage,
    buttonClick: () => setShowCreateContractModal(true),
  };

  const fetchSchedule = () => {
    scheduleSvc
      .getHospitalDashboard(
        dateRange.startDate,
        dateRange.endDate,
        selectedFacilityType ? selectedFacilityType.value : "",
        selectedFacility ? selectedFacility.value : "",
      )
      .then((resp) => {
        if (resp) {
          setContracts(resp);
        }
      });
  };

  useEffect(() => {
    if (!selectedFacility) {
      fetchSchedule();
    }

    setSelectedFacility(null);
    facilitiesSvc
      .getLookup(selectedFacilityType.value)
      .then(setFacilitiesLookup);
  }, [selectedFacilityType]);

  useEffect(() => {
    fetchSchedule();
  }, [selectedFacility]);

  let weeklySums = {
    sums: [],
    startDate: new Date(),
    endDate: new Date(),
  };

  const earningsSeries = {
    name: "Earning",
    type: "column",
    data: weeklySums.sums,
  };

  if (contracts.length > 0) {
    const activeContracts = getActiveContracts(contracts);
    sectionOneContracts = getSchedulesSectionOneContracts(activeContracts);
    calendarContracts = getCalendarContracts(contracts);
    contractsProgress = getContractsProgress(activeContracts).slice(0, 4);

    weeklySums = getWeeklySum(contracts);
    earningsSeries.data = weeklySums.sums;

    switch (activeTab) {
      case 0:
        sectionFourContracts = getMostRecentContracts(contracts).slice(0, 3);
        break;

      case 1:
        sectionFourContracts = getContractsByType(contracts).sort(
          (a, b) => b.contract_rate - a.contract_rate,
        );
        break;

      case 2:
        sectionFourContracts = getContractsByFacility(contracts);
        break;

      default:
        sectionFourContracts = [];
        break;
    }
  }

  let title;
  if (activeTab === 0) {
    title = "Most Recent";
  } else if (activeTab === 1) {
    title = "By Contract Type";
  } else {
    title = "By Facility";
  }

  return (
    <div>
      {showCreateContractModal && (
        <CreateContract
          created={() => setShowCreateContractModal(false)}
          hide={() => setShowCreateContractModal(false)}
        />
      )}

      <Leftsidebar />

      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Contract Schedules</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__content">
              <TopBanner data={bannerContent} />
              <div className="schedules">
                <div
                  className="schedules__container"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 20,
                    marginBottom: 20,
                  }}
                >
                  <DateRange
                    dateRange={dateRange}
                    selectDateRange={(ranges) => setDateRange(ranges.selection)}
                    acceptDateRange={() => fetchSchedule()}
                  />
                  <div style={{ display: "flex" }}>
                    <Dropdown
                      data={{
                        selected: selectedFacilityType,
                        option_list: facilitiesWithHospitalList,
                        selectOption: setSelectedFacilityType,
                      }}
                    />
                    <Select
                      options={facilitiesLookup.map((x) => ({
                        value: x.id,
                        label: x.facility_name,
                      }))}
                      placeholder="Select a Facility"
                      value={selectedFacility}
                      isClearable
                      onChange={setSelectedFacility}
                      noOptionsMessage={() => "Select any other facility type"}
                      styles={{
                        ...dropdownStyles(store.profile.dark_mode),
                        control: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(store.profile.dark_mode).control(
                            baseStyles,
                          ),
                          borderRadius: 15,
                          marginLeft: 20,
                          width: 320,
                        }),
                        placeholder: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(
                            store.profile.dark_mode,
                          ).placeholder(baseStyles),
                          fontSize: 16,
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          ...dropdownStyles(
                            store.profile.dark_mode,
                          ).singleValue(baseStyles),
                          color: "#808191",
                          fontSize: 16,
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="schedules__container">
                  <div className="schedules__col">
                    <div className="schedules__title h6">Small Timeline</div>
                    <div
                      className="schedules__list"
                      style={{ marginBottom: 20 }}
                    >
                      {sectionOneContracts.map((item, idx) => {
                        const {
                          day,
                          contract_type: contractType,
                          bg,
                          patient_name: patientName,
                          date,
                        } = item;
                        return patientName ? (
                          <Link
                            className={`schedules__item ${
                              sectionOneSelectedIndex === idx ? "active" : ""
                            }`}
                            to="/schedules"
                            key={`sectionOneContract_${idV4()}`}
                            onClick={() => setSectionOneSelectedIndex(idx)}
                          >
                            <div className={`schedules__date ${bg}`}>
                              <div className="schedules__name caption-sm">
                                {day}
                              </div>
                              <div className="schedules__number h4">{date}</div>
                            </div>
                            <div className="schedules__details">
                              <div className="schedules__category title">
                                {patientName}
                                {fixIconStyles(
                                  rightArrowIcon,
                                  store.profile.dark_mode,
                                )}
                              </div>
                              <div className="schedules__info caption-sm">
                                {contractType}
                              </div>
                              {/* <div className='schedules__time caption'>
                                {time}
                              </div> */}
                            </div>
                          </Link>
                        ) : (
                          <div style={{ height: 96 }} />
                        );
                      })}
                    </div>
                    <Btn
                      data={{
                        label: "View More",
                        className: "schedules__btn btn-dark btn_wide mt-3",
                      }}
                      onclickFunc={() => navigate("/contracts")}
                    />
                  </div>

                  <div className="schedules__col ps-5">
                    <div className="schedules__top">
                      <div className="schedules__title h6">
                        Contract Timeline
                      </div>
                      <div className="schedules__arrows">
                        <button type="button" className="schedules__arrow">
                          {fixIconStyles(
                            leftArrowIcon,
                            store.profile.dark_mode,
                          )}
                        </button>
                        <button type="button" className="schedules__arrow">
                          {fixIconStyles(
                            rightArrowIcon,
                            store.profile.dark_mode,
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="schedules__inner ">
                      <div className="schedules__graphic">
                        <div className="schedules__head">
                          {/* <div className='schedules__option'>Day</div> */}
                          <div className="schedules__week">
                            <div className="schedules__cell">
                              <div
                                className={`schedules__day ${
                                  currentDay === 0 ? "active" : ""
                                }`}
                              >
                                Su
                              </div>
                            </div>
                            <div className="schedules__cell">
                              <div
                                className={`schedules__day ${
                                  currentDay === 1 ? "active" : ""
                                }`}
                              >
                                M
                              </div>
                            </div>
                            <div className="schedules__cell">
                              <div
                                className={`schedules__day ${
                                  currentDay === 2 ? "active" : ""
                                }`}
                              >
                                Tu
                              </div>
                            </div>
                            <div className="schedules__cell">
                              <div
                                className={`schedules__day ${
                                  currentDay === 3 ? "active" : ""
                                }`}
                              >
                                W
                              </div>
                            </div>
                            <div className="schedules__cell">
                              <div
                                className={`schedules__day ${
                                  currentDay === 4 ? "active" : ""
                                }`}
                              >
                                Th
                              </div>
                            </div>
                            <div className="schedules__cell">
                              <div
                                className={`schedules__day ${
                                  currentDay === 5 ? "active" : ""
                                }`}
                              >
                                Fr
                              </div>
                            </div>
                            <div className="schedules__cell">
                              <div
                                className={`schedules__day ${
                                  currentDay === 6 ? "active" : ""
                                }`}
                              >
                                Sa
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="schedules__body">
                          <div className="schedules__grid">
                            <div className="schedules__line" />
                            <div className="schedules__line" />
                            <div className="schedules__line" />
                            <div className="schedules__line" />
                            <div className="schedules__line" />
                            <div className="schedules__line" />
                          </div>
                          {calendarContracts.map((item) => {
                            const {
                              daysLeft,
                              schedulesText = item.schedules__text,
                              style,
                              bg,
                            } = item;
                            return (
                              <div className="schedules__row" key={idV4()}>
                                <div className="schedules__week">
                                  <div
                                    className={`schedules__task ${bg}`}
                                    style={{
                                      ...style,
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="schedules__text">
                                      {daysLeft ? `${daysLeft} days left` : ""}
                                    </div>
                                    <div className="schedules__text">
                                      {schedulesText}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* <div className='schedules__legend'>
                      <div className='schedules__status green'>
                        Contract Start
                      </div>
                      <div className='schedules__status purple'>
                        Contract End
                      </div>
                      <div className='schedules__status gray'>
                        Contract Renewal
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="page__flex">
                <div className="widget widget_shadow">
                  <div className="widget__title">Contract Progress</div>
                  <div className="quality">
                    <div className="quality__list">
                      {contractsProgress.map((data) => (
                        <IconProgressItem data={data} key={idV4()} />
                      ))}
                    </div>
                    <Btn
                      data={{
                        label: "View More",
                        className:
                          "quality__btn quality__btn_mt30  btn-dark btn_wide",
                      }}
                      onclickFunc={() => navigate("/contracts")}
                    />
                  </div>
                </div>
                <div className="widget widget_shadow">
                  <div className="widget__title">{title}</div>
                  <div className="tabs">
                    <div className="tabs__row">
                      <div className="tabs__col">
                        {sectionFourContracts.map((data, index) => (
                          <TopItem
                            data={data}
                            key={idV4()}
                            onClickFunc={() => {
                              setSectionFourContractIndex(index);
                            }}
                            isActive={sectionFourContractIndex === index}
                          />
                        ))}
                      </div>
                      <div className="tabs__col">
                        <div className="tabs__chart">
                          <Chart
                            options={earningsChartOptions}
                            series={[earningsSeries]}
                            height="100%"
                          />
                        </div>
                        <div className="tabs__desc">
                          <div className="tabs__title title">Earnings</div>
                          <div className="tabs__date caption-sm">
                            {formatShortDate(weeklySums.startDate)} -{" "}
                            {formatShortDate(weeklySums.endDate)}
                          </div>
                          <button
                            type="button"
                            className="tabs__btn btn btn-dark"
                            onClick={() => setShowCreateContractModal(true)}
                            style={{ marginBottom: 29 }}
                          >
                            {fixIconStyles(
                              plusSquareIcon,
                              store.profile.dark_mode,
                            )}
                            <span className="btn__icon">
                              Create New Contract
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="tabs__nav">
                      {tabsList.map((data, index) => {
                        const { text, icon } = data;
                        return (
                          <Link
                            key={`tab_${idV4()}`}
                            className={`tabs__link ${
                              activeTab === index ? "active" : ""
                            }`}
                            to="/schedules"
                            onClick={() => setActiveTab(index)}
                          >
                            <div className="tabs__icon">{icon}</div>
                            <div className="tabs__text">{text}</div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Schedules;
