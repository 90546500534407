export const fixedFacilities = {
  assisted_living: "Assisted Living",
  out_patient: "Out Patient",
  snf: "SNF",
};

export const facilities = {
  ...fixedFacilities,
  "home health": "Home Health",
  "tele health": "Tele Health",
};

function toList(entries) {
  return entries
    .map(([value, title]) => ({
      value,
      title,
    }))
    .sort((a, b) => (a.value > b.value ? 1 : -1));
}

export const facilitiesList = toList(Object.entries(facilities));
facilitiesList.unshift({ value: "", title: "All Facilities" });

export const facilitiesWithHospital = {
  ...facilities,
  hospital: "Hospital",
};

export const facilitiesWithHospitalList = toList(
  Object.entries(facilitiesWithHospital),
);
