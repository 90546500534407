import { MsalProvider } from "@azure/msal-react";
import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";
import { filter } from "rxjs/operators";

import Comments from "../screens/Comments";
import Contracts from "../screens/Contracts";
import Facilities from "../screens/Facilities";
import Home from "../screens/Home";
import Inbox from "../screens/Inbox";
import Login from "../screens/Login";
import Notifications from "../screens/Notifications";
import NotificationsWithComment from "../screens/NotificationsWithComments";
import Patients from "../screens/Patients";
import Payouts from "../screens/Payouts";
import Permissions from "../screens/Permissions";
import Schedules from "../screens/Schedules";
import Settings from "../screens/Settings";
import Statement from "../screens/Statement";
import Statistics from "../screens/Statistics";
import apiSvc from "../services/apiService";
import { AuthService } from "../services/authService";
import MessageService, { messageSvc } from "../services/messageService";
import NotificationService from "../services/notificationService";
import { ProfileService, profileSvc } from "../services/profileService";
import pubSubSvc from "../services/pubsubService";
import store from "../store";
import { urlBase64ToUint8Array } from "../utils";
import RouteGuard from "./RouteGuard";

import "react-toastify/dist/ReactToastify.css";

const override = {
  position: "absolute",
  top: "50%",
  left: "50%",
  margin: "0 auto",
  width: 100,
  height: 100,
  zIndex: 1101,
};

// eslint-disable-next-line react/prop-types
function App({ instance }) {
  const registeredEmail = useRef("");
  const [loading, setLoading] = useState(false);

  const requestNotificationPermission = async () => {
    await Notification.requestPermission();
  };

  const configurePushSubscription = async (email) => {
    if (!("serviceWorker" in navigator) || email === registeredEmail.current) {
      return;
    }

    registeredEmail.current = email;

    await requestNotificationPermission();

    try {
      const registration = await navigator.serviceWorker.ready;

      let subscription = await registration.pushManager.getSubscription();

      if (subscription === null) {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            process.env.REACT_APP_VAPID_PUBLIC_KEY,
          ),
        });
      }

      NotificationService.subscribe(subscription);
    } catch (err) {
      // console.error(err);
    }
  };

  const getMessagesCount = (noSpinner) =>
    MessageService.getMessagesCount(noSpinner).then((resp) =>
      store.setMessagesCount(resp),
    );

  useEffect(() => {
    requestNotificationPermission();

    profileSvc.signIn();

    apiSvc.loading$.subscribe((isLoading) => {
      setLoading(isLoading);
    });

    profileSvc.signedIn$.subscribe(async (isSignedIn) => {
      if (!isSignedIn) {
        registeredEmail.current = "";
        store.userCompany = "";
        store.userRole = "";
        return;
      }

      ProfileService.getMyProfile().then((profile) => {
        store.setProfile(profile);

        if (profile) {
          configurePushSubscription(profile.email);
        }
      });

      AuthService.getPermissions().then((permissions) =>
        store.setPermissions(permissions),
      );

      AuthService.getNavigation().then((navigation) =>
        store.setNavigation(navigation),
      );
    });

    pubSubSvc.getUrl("inbox").then((url) =>
      pubSubSvc
        .connect(url)
        .pipe(filter((sub) => !!sub))
        .subscribe((json) => {
          const message = JSON.parse(json);

          if (
            message.contract.hospital_id === store.profile.location_id ||
            message.facility_id === store.profile.location_id
          ) {
            messageSvc.inboxMessages$.next(message);
          }
        }),
    );

    if (localStorage.getItem("darkMode") === "on") {
      store.setDarkMode(true);
    }

    getMessagesCount();
    setInterval(() => getMessagesCount(true), 60 * 1000);
  }, []);

  return (
    <MsalProvider instance={instance}>
      <Routes>
        <Route index element={<Login />} />
        <Route element={<RouteGuard />}>
          <Route path="home" element={<Home />} />
          <Route path="patient" element={<Patients />} />
          <Route path="contracts" element={<Contracts />} />
          <Route path="Schedules" element={<Schedules />} />
          <Route path="bids" element={<Payouts />} />
          <Route path="facilities" element={<Facilities />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="financials" element={<Statement />} />
          <Route path="settings" element={<Settings />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="permissions" element={<Permissions />} />
          <Route
            path="notificationsWithComment"
            element={<NotificationsWithComment />}
          />
          <Route path="comments" element={<Comments />} />
        </Route>
      </Routes>

      {loading && (
        <div className="loader__background">
          <HashLoader
            color="#6c5dd3"
            loading={loading}
            cssOverride={override}
            size={200}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      <div
        id="new-content"
        className="hidden"
        style={{
          backgroundColor: "#6c5dd3",
          color: "white",
          height: 30,
          left: 0,
          position: "fixed",
          right: 0,
          textAlign: "center",
          top: 0,
          width: "100%",
          zIndex: 1200,
        }}
      >
        New content is available and will be used when all tabs for this page
        are closed.
      </div>
      <ToastContainer />
    </MsalProvider>
  );
}

export default App;
