import { observer } from "mobx-react-lite";
import {
  commentIcon,
  likeIcon,
  moreIcon,
  starIcon,
} from "../assets/svg/exportSvg";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import Editor from "../components/editor/Editor";
import store from "../store";
import { fixIconStyles } from "../utils";

const NotificationsWithComment = observer(() => {
  const notificationList = [
    {
      notificationPic: "./assets/img/ava-1.png",
      notificationStatusPic: "./assets/img/chat.svg",
      notificationPreviewPic: "./assets/img/figure-1.png",
      notificationPreviewBg: "bg-purple-light",
      notificationAuthor: "Glenn Greer",
      notificationCategory: "Commented on",
      notificationInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
    {
      notificationPic: "./assets/img/ava-1.png",
      notificationStatusPic: "./assets/img/chat.svg",
      notificationPreviewPic: "./assets/img/figure-1.png",
      notificationPreviewBg: "bg-purple-light",
      notificationAuthor: "Glenn Greer",
      notificationCategory: "Commented on",
      notificationInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
    {
      notificationPic: "./assets/img/ava-1.png",
      notificationStatusPic: "./assets/img/chat.svg",
      notificationPreviewPic: "./assets/img/figure-1.png",
      notificationPreviewBg: "bg-yellow",
      notificationAuthor: "Glenn Greer",
      notificationCategory: "Commented on",
      notificationInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
    {
      notificationPic: "./assets/img/ava-1.png",
      notificationStatusPic: "./assets/img/chat.svg",
      notificationPreviewPic: "./assets/img/figure-1.png",
      notificationPreviewBg: "bg-pink",
      notificationAuthor: "Glenn Greer",
      notificationCategory: "Commented on",
      notificationInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
    {
      notificationPic: "./assets/img/ava-1.png",
      notificationStatusPic: "./assets/img/chat.svg",
      notificationPreviewPic: "./assets/img/figure-1.png",
      notificationPreviewBg: "bg-blue",
      notificationAuthor: "Glenn Greer",
      notificationCategory: "Commented on",
      notificationInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
  ];
  return (
    <div>
      <Leftsidebar />
      <div className="page__wrapper">
        <div className="page__center">
          <div className="page__row page__row_responsive page__row_head page__row_border">
            <div className="page__col">
              <div className="page__hello h5">Unity Dashboard v.1</div>
              <div className="page__welcome h2">Notifications</div>
            </div>
            <div className="page__col">
              <SearchAndNotification />
            </div>
          </div>
          <div className="page__row page__row_responsive page__row_border">
            <div className="page__col">
              <div className="notification">
                <div className="notification__tags">
                  <a className="notification__tag active" href="/">
                    Clients
                  </a>
                  <a className="notification__tag" href="/">
                    Products
                  </a>
                  <a className="notification__tag" href="/">
                    Administrator
                  </a>
                  <a className="notification__tag" href="/">
                    Sales
                  </a>
                  <a className="notification__tag" href="/">
                    Withdrawals
                  </a>
                </div>
                <div className="notification__container">
                  <div className="notification__title h6">
                    Recent Notification
                  </div>
                  <div className="notification__list">
                    {notificationList.map((data) => {
                      const {
                        notificationPreviewBg,
                        notificationPic,
                        // notification__time,
                        notificationInfo,
                        inboxText,
                        notificationCategory,
                        notificationAuthor,
                        notificationStatusPic,
                      } = data;
                      return (
                        <div className="notification__item">
                          <div className="notification__ava">
                            <img
                              className="notification__pic"
                              src={notificationPic}
                              alt=""
                            />
                            <div className="notification__status bg-blue">
                              <img
                                className="notification__pic"
                                src={notificationStatusPic}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="notification__details">
                            <div className="notification__author title">
                              {notificationAuthor}
                            </div>
                            <div className="notification__line caption">
                              <div className="notification__category">
                                {notificationCategory}
                              </div>
                              <div className="notification__info">
                                {notificationInfo}
                              </div>
                              <div className="notification__time">
                                {/* {notification__time} */}
                              </div>
                            </div>
                            <div className="inbox__text">{inboxText}</div>
                            <div className="actions">
                              <button type="button" className="actions__btn">
                                {fixIconStyles(
                                  commentIcon,
                                  store.profile.dark_mode,
                                )}
                              </button>
                              <button type="button" className="actions__btn">
                                {fixIconStyles(
                                  likeIcon,
                                  store.profile.dark_mode,
                                )}
                              </button>
                              <button type="button" className="actions__btn">
                                {fixIconStyles(
                                  starIcon,
                                  store.profile.dark_mode,
                                )}
                              </button>
                              <button type="button" className="actions__btn">
                                {fixIconStyles(
                                  moreIcon,
                                  store.profile.dark_mode,
                                )}
                              </button>
                            </div>
                          </div>
                          <div
                            className={`notification__preview ${notificationPreviewBg}`}
                          >
                            <img
                              className="notification__pic"
                              src={notificationPic}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="notification__btns">
                    <button
                      type="button"
                      className="notification__btn btn btn-dark"
                    >
                      Load More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="page__col">
              <div className="notification">
                <div className="notification__title h6">From Notification</div>
                <div className="notification__photo">
                  <img
                    className="notification__pic"
                    src="./assets/img/notification-pic.png"
                    alt=""
                  />
                </div>
                <div className="notification__body">
                  <div className="notification__stage h5">Collab Landing.</div>
                  <div className="notification__parameters">
                    <div className="notification__parameter">
                      <div className="notification__icon">
                        <img
                          className="notification__pic"
                          src="./assets/img/user.svg"
                          alt=""
                        />
                      </div>
                      <div className="caption">Glenn Greer</div>
                    </div>
                    <div className="notification__parameter">
                      <div className="notification__icon">
                        <img
                          className="notification__pic"
                          src="./assets/img/clock.svg"
                          alt=""
                        />
                      </div>
                      <div className="caption">12h</div>
                    </div>
                  </div>
                  <div className="notification__text color-gray">
                    &ldquo;Love this so much! What tools do you use to create
                    your 3d illustrations?&rdquo;
                  </div>
                </div>
                <Editor data={{}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NotificationsWithComment;
