import $ from "jquery";
import { observer } from "mobx-react-lite";
import Chart from "react-apexcharts";
import { boxIcon2, editIcon } from "../assets/svg/exportSvg";
import FilterBox from "../components/FilterBox";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import Btn from "../components/buttons/Btn";
import store from "../store";
import { fixIconStyles } from "../utils";

const Payouts = observer(() => {
  const blue = "#A0D7E7";
  const borderColor = "#E4E4E4";
  const newUsers = {
    colors: [blue],
    chart: {
      height: "100%",
      type: "bar",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      borderColor,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.9,
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
    },
    xaxis: {
      axisBorder: {
        show: false,
        color: borderColor,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  const user = [
    {
      name: "series-2",
      type: "column",
      data: [30, 60, 50, 80, 45],
    },
  ];

  const CheckBoxAll = () => {
    const checkboxAll = $(".products__row_head .checkbox__input");
    if (checkboxAll.is(":checked")) {
      checkboxAll
        .parents(".products__table")
        .find(".products__row:not(.products__row_head) .checkbox__input")
        .prop("checked", true)
        .attr("checked", "checked");
    } else {
      checkboxAll
        .parents(".products__table")
        .find(".products__row:not(.products__row_head) .checkbox__input")
        .prop("checked", false)
        .removeAttr("checked");
    }
  };
  const WithdrawHistoryist = [
    {
      productsPrice: "$2,932.9",
      productsTransactionCaption: "$25.00 transaction fee",
      method: "Paypal",
      productsStatus: "Paid",
      date: "17 Aug 2020",
      fees: "$3.49",
    },
    {
      productsPrice: "$2,932.9",
      productsTransactionCaption: "$25.00 transaction fee",
      method: "Paypal",
      productsStatus: "Paid",
      date: "17 Aug 2020",
      fees: "$3.49",
    },
    {
      productsPrice: "$2,932.9",
      productsTransactionCaption: "$25.00 transaction fee",
      method: "Paypal",
      productsStatus: "Paid",
      date: "17 Aug 2020",
      fees: "$3.49",
    },
    {
      productsPrice: "$2,932.9",
      productsTransactionCaption: "$25.00 transaction fee",
      method: "Paypal",
      productsStatus: "Paid",
      date: "17 Aug 2020",
      fees: "$3.49",
    },
    {
      productsPrice: "$2,932.9",
      productsTransactionCaption: "$25.00 transaction fee",
      method: "Paypal",
      productsStatus: "Paid",
      date: "17 Aug 2020",
      fees: "$3.49",
    },
  ];
  return (
    <div>
      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Your Bids</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__row">
              <div className="page__col">
                <div className="widget widget_shadow">
                  <div className="widget__title">Choose A Payment Method</div>
                  <div className="payment">
                    <div className="payment__row">
                      <div className="payment__details">
                        <div className="payment__preview bg-purple-light">
                          <img
                            className="payment__pic"
                            src="./assets/img/figure-5.png"
                            alt=""
                          />
                        </div>
                        <div className="payment__mail title">
                          payout@ui8.net
                        </div>
                        <div className="payment__info caption-sm">
                          Your selected payout method was confirmed on Feb 24,
                          2019.
                        </div>
                        <div className="payment__logo">
                          <img
                            className="payment__pic"
                            src="./assets/img/paypal.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="payment__variants">
                        <label htmlFor="payment" className="payment__label">
                          <input
                            className="payment__radio"
                            type="radio"
                            name="payment"
                            defaultChecked=""
                          />
                          <span className="payment__in">
                            <span className="payment__tick" />
                            <span className="payment__desc">
                              <span className="payment__logo">
                                <img
                                  className="payment__pic"
                                  src="./assets/img/paypal.png"
                                  alt=""
                                />
                              </span>
                              <span className="payment__info caption-sm">
                                Your Paypal account has been authorized for
                                payouts.
                              </span>
                              <button
                                type="button"
                                className="payment__btn btn btn_gray"
                              >
                                Deauthorize
                              </button>
                            </span>
                          </span>
                        </label>
                        <label htmlFor="radio" className="payment__label">
                          <input
                            className="payment__radio"
                            type="radio"
                            name="payment"
                          />
                          <span className="payment__in">
                            <span className="payment__tick" />
                            <span className="payment__desc">
                              <span className="payment__logo">
                                <img
                                  className="payment__pic payment__pic_black"
                                  src="./assets/img/payoneer.png"
                                  alt=""
                                />
                                <img
                                  className="payment__pic payment__pic_white"
                                  src="./assets/img/payoneer-white.png"
                                  alt=""
                                />
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page__col">
                <div className="widget widget_empty widget_stat widget_pt0 widget_responsive">
                  <div className="widget__row">
                    <div className="widget__col">
                      <div className="widget__chart widget__chart_earning-month">
                        <Chart options={newUsers} series={user} height="100%" />
                      </div>
                    </div>
                    <div className="widget__col">
                      <div className="widget__title">
                        Your earning this month
                      </div>
                      <div className="widget__counter title title_md color-purple">
                        479.4
                      </div>
                      <div className="widget__text widget__text_mb32">
                        Update your payout method in Settings
                      </div>
                      <button
                        type="button"
                        className="widget__btn btn btn_purple btn_wide"
                      >
                        Withdraw All Earning
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page__content page__content_pt64">
              <div className="page__stat">
                <div className="page__title h6">Withdraw History</div>
                <FilterBox SearchOnClick={() => {}}>
                  <button type="button" className="sorting__action">
                    {fixIconStyles(editIcon, store.profile.dark_mode)}
                  </button>
                  <button type="button" className="sorting__action">
                    {fixIconStyles(boxIcon2, store.profile.dark_mode)}
                  </button>
                </FilterBox>
                <div className="products products_history">
                  <div className="products__table">
                    <div className="products__row products__row_head">
                      <div className="products__cell">
                        <label htmlFor="checkbox" className="checkbox">
                          <input
                            className="checkbox__input"
                            type="checkbox"
                            onClick={() => CheckBoxAll()}
                          />
                          <span className="checkbox__in">
                            <span className="checkbox__tick" />
                          </span>
                        </label>
                      </div>
                      <div className="products__cell">Transaction</div>
                      <div className="products__cell">Payout Method</div>
                      <div className="products__cell">Stattus</div>
                      <div className="products__cell">Date Processed</div>
                      <div className="products__cell">Fees</div>
                    </div>
                    {WithdrawHistoryist.map((data) => {
                      const {
                        fees,
                        method,
                        date,
                        productsPrice,
                        productsTransactionCaption,
                        productsStatus,
                      } = data;
                      return (
                        <>
                          <div className="products__row">
                            <div className="products__cell">
                              <label htmlFor="checkbox" className="checkbox">
                                <input
                                  className="checkbox__input"
                                  type="checkbox"
                                />
                                <span className="checkbox__in">
                                  <span className="checkbox__tick" />
                                </span>
                              </label>
                            </div>
                            <div className="products__cell">
                              <div className="products__price title">
                                {productsPrice}
                              </div>
                              <div className="products__transaction caption color-gray">
                                {productsTransactionCaption}
                              </div>
                            </div>
                            <div className="products__cell">{method}</div>
                            <div className="products__cell">
                              <div className="products__status caption bg-green">
                                {productsStatus}
                              </div>
                            </div>
                            <div className="products__cell color-gray">
                              {date}
                            </div>
                            <div className="products__cell color-red">
                              {fees}
                            </div>
                          </div>
                          <div className="products__body">
                            <div className="products__bg bg-green" />
                            <div className="products__line">
                              <div className="products__col color-gray">
                                {date}
                              </div>
                              <div className="products__col">{method}</div>
                              <div className="products__col color-red">
                                {fees}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="products__more">
                    <Btn
                      data={{
                        label: "Load More",
                        className: "products__btn btn-dark",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Payouts;
