import { observer } from "mobx-react-lite";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import Select from "react-select";
import { v4 as idV4 } from "uuid";

import {
  bagIcon,
  boxIcon,
  chartIcon,
  discoveryIcon,
  documentIcon,
  settingIcon,
  walletIcon,
} from "../assets/svg/exportSvg";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import { dropdownStyles } from "../constants";
import { AuthService } from "../services/authService";
import facilitiesSvc from "../services/facilitiesService";
import store from "../store";
import { deepClone } from "../utils";

const companies = {
  hospital: "Hospital",
  insurance: "Insurance",
  out_patient: "Out Patient",
  snf: "SNF",
};

const roles = {
  coordinator: "Coordinator",
  case_manager: "Case Manager",
  director: "Director",
};

const Permissions = observer(() => {
  const [facilities, setFacilities] = useState([]);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("hospital");
  const [users, setUsers] = useState([]);

  const getCompanyIcon = (company) => {
    switch (company) {
      case "hospital":
        return (
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 256 256"
            >
              <path
                fill="#6c5dd3"
                d="M216 66h-42V56a22.1 22.1 0 0 0-22-22h-48a22.1 22.1 0 0 0-22 22v10H40a14 14 0 0 0-14 14v128a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V80a14 14 0 0 0-14-14ZM94 56a10 10 0 0 1 10-10h48a10 10 0 0 1 10 10v10H94ZM40 78h176a2 2 0 0 1 2 2v42.8a185.6 185.6 0 0 1-90 23.2a185.9 185.9 0 0 1-90-23.2V80a2 2 0 0 1 2-2Zm176 132H40a2 2 0 0 1-2-2v-71.6a198.7 198.7 0 0 0 90 21.6a198.7 198.7 0 0 0 90-21.6V208a2 2 0 0 1-2 2Zm-106-90a6 6 0 0 1 6-6h24a6 6 0 0 1 0 12h-24a6 6 0 0 1-6-6Z"
              />
            </svg>
          </i>
        );

      case "insurance":
        return (
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="#6c5dd3"
                d="M12 15.5q.625 0 1.062-.438q.438-.437.438-1.062v-1h1q.625 0 1.062-.438Q16 12.125 16 11.5t-.438-1.062Q15.125 10 14.5 10h-1V9q0-.625-.438-1.062Q12.625 7.5 12 7.5t-1.062.438Q10.5 8.375 10.5 9v1h-1q-.625 0-1.062.438Q8 10.875 8 11.5t.438 1.062Q8.875 13 9.5 13h1v1q0 .625.438 1.062q.437.438 1.062.438Zm0 6.425h-.25q-.125 0-.225-.05q-3.275-1.025-5.4-4.063Q4 14.775 4 11.1V6.375q0-.625.363-1.125q.362-.5.937-.725l6-2.25q.35-.125.7-.125t.7.125l6 2.25q.575.225.938.725q.362.5.362 1.125V11.1q0 3.675-2.125 6.712q-2.125 3.038-5.4 4.063q-.125.05-.475.05Zm0-2.025q2.6-.825 4.3-3.3q1.7-2.475 1.7-5.5V6.375l-6-2.25l-6 2.25V11.1q0 3.025 1.7 5.5t4.3 3.3Zm0-7.9Z"
              />
            </svg>
          </i>
        );

      case "out_patient":
        return (
          <i>
            <svg
              className="icon icon-bag"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="19"
              viewBox="0 0 21 19"
            >
              <path
                fill="#1b1d21"
                d="M9.83.028c2.62 0 4.778 1.99 5.043 4.54h.074c1.797 0 3.3 1.36 3.82 3.73h0l.8 6.286c.463 3.96-1.79 6.167-5.563 6.167h0-8.348c-4.078 0-6.162-1.958-5.494-6.41h0l.784-6.082c.433-2.34 1.976-3.69 3.813-3.69h0c.122-1.153.636-2.236 1.466-3.063A5.07 5.07 0 0 1 9.831.028zm5.117 6.04H4.77c-1.098 0-2.034.818-2.332 2.42h0l-.78 6.057c-.522 3.48.78 4.704 4.01 4.704h8.348c2.98 0 4.5-1.54 4.043-4.72h0L17.3 8.555c-.363-1.65-1.29-2.487-2.343-2.487h0zm-2.15 2.784a.75.75 0 0 1 .102 1.493l-.102.007h-.046a.75.75 0 0 1-.102-1.493l.102-.007h.046zm-5.83 0a.75.75 0 0 1 .102 1.493l-.102.007H6.92a.75.75 0 0 1-.102-1.493l.102-.007h.046zm2.862-7.324a3.57 3.57 0 0 0-3.546 3.04h7.08c-.256-1.72-1.74-3.04-3.534-3.04z"
              />
            </svg>
          </i>
        );

      case "snf":
        return (
          <i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="#6c5dd3"
                d="m0 8.853l2.886 10.115c2.738-.403 5.899-.633 9.113-.633s6.375.23 9.467.675l-.353-.042l2.886-10.115C14.497 4.628 8.858 4.405 0 8.853zm14.918 4.276h-2.071V15.2h-1.686v-2.071H9.09v-1.686h2.071V9.371h1.686v2.072h2.071z"
              />
            </svg>
          </i>
        );

      default:
        return <i />;
    }
  };

  const getSectionIcon = (section) => {
    switch (section) {
      case "Bids":
        return walletIcon;

      case "Contracts":
        return discoveryIcon;

      case "Facilities":
        return boxIcon;

      case "Financials":
        return documentIcon;

      case "Patients":
        return bagIcon;

      case "Statistics":
        return chartIcon;

      default:
        return settingIcon;
    }
  };

  const getUserCompanyValue = (company) => {
    if (!company) {
      return null;
    }

    return {
      value: company,
      label: companies[company],
    };
  };

  const getUserRoleValue = (role) => {
    if (!role) {
      return null;
    }

    return {
      value: role,
      label: role === "admin" ? "Admin" : roles[role],
    };
  };

  const setUserSelectedFacilities = () => {
    setUsers(
      deepClone(remoteUsers).map((user) => ({
        ...user,
        selectedFacilities: facilities
          .filter((x) => user.facility_ids.indexOf(x.id) !== -1)
          .map((x) => ({
            value: x.id,
            label: x.facility_name,
          })),
      })),
    );
  };

  const updateNavigation = (name, company, role, checked) => {
    AuthService.updateNavigation(name, company, role, checked);

    store.updateNavigation(name, company, role, checked);
  };

  const updatePermission = (permission, company, role, checked) => {
    AuthService.updatePermission(
      permission.name,
      permission.section_name,
      company,
      role,
      checked,
    );
    store.updatePermission(permission, company, role, checked);
  };

  const updateUserCompany = (user, company) => {
    const usersState = users.map((x) => ({ ...x }));
    const userIdx = usersState.findIndex((x) => x.id === user.id);
    usersState[userIdx].company = company;
    setUsers(usersState);

    AuthService.updateUserCompany(user, company);
  };

  const updateUserFacilities = (user, options) => {
    const copy = deepClone(users);
    const userIdx = copy.findIndex((x) => x.id === user.id);
    copy[userIdx].selectedFacilities = options;
    setUsers(copy);

    const facilityIds = options.map((x) => x.value);
    AuthService.updateUserFacilities(user, facilityIds);
  };

  const updateUserRole = (user, role) => {
    const usersState = users.map((x) => ({ ...x }));
    const userIdx = usersState.findIndex((x) => x.id === user.id);
    usersState[userIdx].role = role;
    setUsers(usersState);

    AuthService.updateUserRole(user, role);
  };

  const sections = Array.from(
    new Set(store.permissions.map((x) => x.section_name)),
  );

  useEffect(() => {
    AuthService.getUsers().then((resp) => {
      setRemoteUsers(resp);
    });

    facilitiesSvc.getLookup().then(setFacilities);
  }, []);

  useEffect(() => {
    setUserSelectedFacilities();
  }, [remoteUsers, facilities]);

  const checkBoxId = idV4(); // Generate the unique ID

  return (
    <>
      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div
            className="page__center"
            style={{
              margin: 0,
              maxWidth: "100%",
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <div className="page__row page__row_head">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Permissions</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__content">
              <div className="page__stat page__stat_pt32">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="navigation"
                >
                  <Nav variant="pills" className="customTabs">
                    <Nav.Item>
                      <Nav.Link eventKey="navigation">Role navigation</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="roles">Role permissions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="users">Users</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="navigation">
                      <Tab.Container activeKey={selectedCompany}>
                        <Nav variant="pills" className="innerTabs">
                          {Object.keys(companies).map((key) => (
                            <Nav.Item
                              key={key}
                              onClick={() => setSelectedCompany(key)}
                            >
                              <Nav.Link eventKey={key}>
                                {getCompanyIcon(key)}
                                {companies[key]}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                        <Tab.Content>
                          {Object.keys(companies).map((companyKey) => (
                            <Tab.Pane key={companyKey} eventKey={companyKey}>
                              <div className="permissions">
                                <table className="products__table">
                                  <thead>
                                    <tr className="products__row products__row_head">
                                      <th className="products__cell">Screen</th>
                                      {Object.values(roles).map((role) => (
                                        <th
                                          key={idV4()}
                                          className="products__cell"
                                        >
                                          {role}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {store.navigation.map((navigation) => (
                                      <tr
                                        key={idV4()}
                                        className="products__row"
                                      >
                                        <td className="products__cell">
                                          {navigation.name}
                                        </td>
                                        {Object.keys(
                                          navigation[companyKey],
                                        ).map((role) => {
                                          const checkboxId = `${companyKey}_${navigation.name}_${role}`;
                                          return (
                                            <td
                                              key={role}
                                              className="products__cell"
                                            >
                                              <label
                                                htmlFor={checkboxId}
                                                className="checkbox"
                                              >
                                                <input
                                                  className="checkbox__input"
                                                  type="checkbox"
                                                  id={checkboxId}
                                                  checked={
                                                    navigation[companyKey][role]
                                                  }
                                                  onChange={(e) =>
                                                    updateNavigation(
                                                      navigation.name,
                                                      companyKey,
                                                      role,
                                                      e.target.checked,
                                                    )
                                                  }
                                                />
                                                <span className="checkbox__in">
                                                  <span className="checkbox__tick" />
                                                </span>
                                              </label>
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Tab.Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="roles">
                      <Tab.Container activeKey={selectedCompany}>
                        <Nav variant="pills" className="innerTabs">
                          {Object.keys(companies).map((key) => (
                            <Nav.Item
                              key={key}
                              onClick={() => setSelectedCompany(key)}
                            >
                              <Nav.Link eventKey={key}>
                                {getCompanyIcon(key)}
                                {companies[key]}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                        <Tab.Content>
                          {Object.keys(companies).map((companyKey) => (
                            <Tab.Pane key={companyKey} eventKey={companyKey}>
                              <div className="permissions">
                                <table className="products__table">
                                  <thead>
                                    <tr className="products__row products__row_head">
                                      <th className="products__cell">Action</th>
                                      {Object.values(roles).map((role) => (
                                        <th
                                          key={`role_${idV4()}`}
                                          className="products__cell"
                                        >
                                          {role}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {sections.map((section) => {
                                      const idValue = idV4();
                                      return (
                                        <Fragment key={idValue}>
                                          <tr className="products__row">
                                            <td
                                              colSpan="4"
                                              className="products__cell"
                                            >
                                              <span className="material-symbols-outlined">
                                                {getSectionIcon(section)}
                                              </span>
                                            </td>
                                          </tr>
                                          {store.permissions.map(
                                            (permission) => {
                                              const keyValue = idV4();
                                              return (
                                                <tr
                                                  key={keyValue}
                                                  className="products__row"
                                                >
                                                  <td className="products__cell">
                                                    {permission.name}
                                                  </td>
                                                  {Object.keys(
                                                    permission[companyKey],
                                                  ).map((role) => (
                                                    <td
                                                      key={role}
                                                      className="products__cell"
                                                    >
                                                      <label
                                                        htmlFor={`check_${keyValue}_${role}`}
                                                        className="checkbox"
                                                      >
                                                        <input
                                                          className="checkbox__input"
                                                          type="checkbox"
                                                          id={`check_${keyValue}_${role}`}
                                                          checked={
                                                            permission[
                                                              companyKey
                                                            ][role]
                                                          }
                                                          onChange={(e) =>
                                                            updatePermission(
                                                              permission,
                                                              companyKey,
                                                              role,
                                                              e.target.checked,
                                                            )
                                                          }
                                                        />
                                                        <span className="checkbox__in">
                                                          <span className="checkbox__tick" />
                                                        </span>
                                                      </label>
                                                    </td>
                                                  ))}
                                                </tr>
                                              );
                                            },
                                          )}
                                        </Fragment>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Tab.Container>
                    </Tab.Pane>
                    <Tab.Pane eventKey="users">
                      <table className="products__table">
                        <thead>
                          <tr className="products__row products__row_head">
                            <th className="products__cell">
                              <label
                                htmlFor={`check__${checkBoxId}`}
                                className="checkbox"
                              >
                                <input
                                  className="checkbox__input"
                                  type="checkbox"
                                  id={`check__${checkBoxId}`}
                                />
                                <span className="checkbox__in">
                                  <span className="checkbox__tick" />
                                </span>
                              </label>
                            </th>
                            <th className="products__cell">Name</th>
                            <th className="products__cell">Company</th>
                            <th className="products__cell">Role</th>
                            <th className="products__cell">Facilities</th>
                            <th className="products__cell">Last Active</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((user) => (
                            <tr
                              key={`user_${user.id}`}
                              className="products__row"
                            >
                              <td className="products__cell">
                                <label
                                  htmlFor={`user_${user.id}`}
                                  className="checkbox"
                                >
                                  <input
                                    id={`user_${user.id}`}
                                    className="checkbox__input"
                                    type="checkbox"
                                  />
                                  <span className="checkbox__in">
                                    <span className="checkbox__tick" />
                                  </span>
                                </label>
                              </td>
                              <td className="products__cell">
                                <a className="products__item" href="/">
                                  <img
                                    className="patient_image"
                                    src="./assets/img/ava.png"
                                    alt=""
                                  />
                                  <div className="products__details">
                                    <div className="products__title title">
                                      {user.display_name}
                                    </div>
                                    <div
                                      className="products__info caption color-gray"
                                      style={{
                                        maxWidth: 200,
                                        overflowX: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {user.email}
                                    </div>
                                  </div>
                                </a>
                              </td>

                              <td className="products__cell">
                                {store.profile.id !== user.id ? (
                                  <Select
                                    options={Object.entries(companies).map(
                                      ([key, value]) => ({
                                        value: key,
                                        label: value,
                                      }),
                                    )}
                                    value={getUserCompanyValue(user.company)}
                                    onChange={(option) =>
                                      updateUserCompany(
                                        user,
                                        option ? option.value : "",
                                      )
                                    }
                                    styles={{
                                      ...dropdownStyles(
                                        store.profile.dark_mode,
                                      ),
                                      control: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).control(baseStyles),
                                        fontSize: 12,
                                        maxWidth: 240,
                                      }),
                                      menuList: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).menuList(baseStyles),
                                        fontSize: 11,
                                      }),
                                      singleValue: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).singleValue(baseStyles),
                                        fontSize: 11,
                                      }),
                                      valueContainer: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).valueContainer(baseStyles),
                                        minHeight: 40,
                                      }),
                                    }}
                                  />
                                ) : (
                                  <div>
                                    {(() => {
                                      if (user.role !== "admin") {
                                        return user.company
                                          ? companies[user.company]
                                          : "N/A";
                                      }
                                      return "";
                                    })()}
                                  </div>
                                )}
                              </td>

                              <td className="products__cell">
                                {store.profile.id !== user.id ? (
                                  <Select
                                    options={Object.entries(roles).map(
                                      ([key, value]) => ({
                                        value: key,
                                        label: value,
                                      }),
                                    )}
                                    value={getUserRoleValue(user.role)}
                                    onChange={(option) =>
                                      updateUserRole(
                                        user,
                                        option ? option.value : "",
                                      )
                                    }
                                    styles={{
                                      ...dropdownStyles(
                                        store.profile.dark_mode,
                                      ),
                                      control: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).control(baseStyles),
                                        fontSize: 12,
                                        maxWidth: 240,
                                      }),
                                      menuList: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).menuList(baseStyles),
                                        fontSize: 11,
                                      }),
                                      singleValue: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).singleValue(baseStyles),
                                        fontSize: 11,
                                      }),
                                      valueContainer: (baseStyles) => ({
                                        ...dropdownStyles(
                                          store.profile.dark_mode,
                                        ).valueContainer(baseStyles),
                                        minHeight: 40,
                                      }),
                                    }}
                                  />
                                ) : (
                                  <div style={{ fontSize: 14, paddingLeft: 8 }}>
                                    {user.role === "admin"
                                      ? "Admin"
                                      : roles[user.role]}
                                  </div>
                                )}
                              </td>

                              <td className="products__cell">
                                <Select
                                  options={facilities
                                    .filter(
                                      (x) => x.facility_type === user.company,
                                    )
                                    .map(
                                      ({
                                        id,
                                        facility_name: facilityName,
                                      }) => ({
                                        value: id,
                                        label: facilityName,
                                      }),
                                    )}
                                  value={user.selectedFacilities}
                                  onChange={(options) =>
                                    updateUserFacilities(user, options)
                                  }
                                  isMulti
                                  styles={{
                                    ...dropdownStyles(store.profile.dark_mode),
                                    control: (baseStyles) => ({
                                      ...dropdownStyles(
                                        store.profile.dark_mode,
                                      ).control(baseStyles),
                                      fontSize: 12,
                                      maxWidth: 240,
                                    }),
                                    menuList: (baseStyles) => ({
                                      ...dropdownStyles(
                                        store.profile.dark_mode,
                                      ).menuList(baseStyles),
                                      fontSize: 11,
                                    }),
                                    valueContainer: (baseStyles) => ({
                                      ...dropdownStyles(
                                        store.profile.dark_mode,
                                      ).valueContainer(baseStyles),
                                      minHeight: 40,
                                    }),
                                  }}
                                />
                              </td>

                              <td className="products__cell">
                                <div className="products__info caption color-gray">
                                  {user.last_activity
                                    ? moment.utc(user.last_activity).fromNow()
                                    : "N/A"}
                                </div>
                              </td>
                              {/* <td className='products__cell'>
                              <div className='switchBox'>
                                <input
                                  id='switch-toggle-6'
                                  className='switch-toggle switch-toggle-flat'
                                  type='checkbox'
                                />
                                <label htmlFor='switch-toggle-6'></label>
                              </div>
                            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Permissions;
