import apiSvc from "./apiService";

class StatisticsService {
  static getHospitalDashboard(startDate, endDate, type, id) {
    return apiSvc.get(
      `/statistics/hospital-dashboard?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}&facility_type=${
        type || ""
      }&facility_id=${id || ""}`,
    );
  }

  static getPerYear(facility) {
    return apiSvc.get(`/statistics/per-year?facility=${facility}`);
  }
}

export default StatisticsService;
