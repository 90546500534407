import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { v4 as idV4 } from "uuid";
import {
  notificationCounterIcon,
  rightArrowIcon,
  searchIcon,
  searchIcon2,
} from "../assets/svg/exportSvg";
import { notificationSvc } from "../services/notificationService";
import store from "../store";
import { fixIconStyles } from "../utils";

import "../Style/App.css";

const SearchAndNotification = observer(({ showSearch, placeholder }) => {
  const isSearchActiveRef = useRef({});
  const openNotificationsRef = useRef({});

  const openBurgerHeader = () => {
    document.querySelector(".page").classList.toggle("toggle");
    document.querySelector(".sidebar").classList.toggle("active");
  };

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const searchList = [];

  const handleClickOutside = (event) => {
    if (
      isSearchActiveRef.current &&
      !isSearchActiveRef.current.contains(event.target)
    ) {
      setIsSearchActive(false);
    }
    if (
      openNotificationsRef.current &&
      !openNotificationsRef.current.contains(event.target)
    ) {
      setOpenNotifications(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    notificationSvc.getRecent().then((data) => {
      setNotifications(data.notifications);
      setUnreadCount(data.total);
    });
  }, []);

  return (
    <div className="header">
      <button
        type="button"
        className="header__burger"
        onClick={() => openBurgerHeader()}
        aria-label="Open Header"
      />

      <div
        className={`search ${isSearchActive ? "active" : ""} ${
          isShow ? "show" : ""
        }`}
        style={{ visibility: showSearch ? "visible" : "hidden" }}
        ref={isSearchActiveRef}
      >
        <div className="search__field">
          <input
            className="search__input"
            type="text"
            placeholder={placeholder}
          />
          <button
            type="button" // Add the type attribute with the value "button"
            className="search__toggle"
            onClick={() => {
              setIsSearchActive(!isSearchActive);
            }}
          >
            {fixIconStyles(searchIcon, store.profile.dark_mode)}
          </button>
        </div>
        <div className="search__result">
          <div className="search__info caption-sm">Recent Searches</div>
          <div className="search__list">
            {searchList?.map((item) => (
              <a className="search__item" href="/" key={idV4()}>
                <div className="search__preview bg-pink">
                  <img className="search__pic" src={item.img} alt="" />
                </div>
                <div className="search__text">
                  {item.label}
                  {fixIconStyles(rightArrowIcon, store.profile.dark_mode)}
                </div>
              </a>
            ))}
          </div>
          <button type="button" className="search__btn btn btn-dark btn_wide">
            Advance Search
          </button>
        </div>
      </div>
      <button
        type="button"
        className={`header__search  ${isShow ? "active" : ""}`}
        style={{ visibility: showSearch ? "visible" : "hidden" }}
        onClick={() => setIsShow(!isShow)}
      >
        {fixIconStyles(searchIcon2, store.profile.dark_mode)}
      </button>
      <div
        className={`notifications ${openNotifications ? "active" : ""}`}
        ref={openNotificationsRef}
      >
        <button
          type="button"
          className="notifications__open"
          onClick={() => setOpenNotifications(!openNotifications)}
        >
          {fixIconStyles(notificationCounterIcon, store.profile.dark_mode)}
          {unreadCount > 0 && (
            <span className="notifications__counter">{unreadCount}</span>
          )}
        </button>
        <div className="notifications__wrap">
          <div className="notifications__info h6">Recent Notifications</div>
          {notifications.length > 0 && (
            <div className="notifications__list">
              {notifications.map((notification) => (
                <a
                  className="notifications__item"
                  href="/notifications"
                  key={`notification_${notification.id}`}
                >
                  <div className="notifications__ava">
                    <img
                      className="notifications__pic"
                      src="./assets/img/figure-1.png"
                      alt=""
                    />
                    {/* <div className="notifications__status bg-blue">
                    <img
                      className="notifications__pic"
                      src="./assets/img/chat.svg"
                      alt=""
                    />
                  </div> */}
                  </div>
                  <div className="notifications__details">
                    <div className="notifications__line">
                      <div className="notifications__user caption">
                        {notification.title}
                      </div>
                      <div className="notifications__time caption">
                        {moment.utc(notification.time).fromNow()}
                      </div>
                    </div>
                    <div className="notifications__text caption-sm notification-description">
                      {notification.description}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          )}
          {notifications.length === 0 && (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <div className="text-center">No recent notifications</div>
            </div>
          )}
          <div className="notifications__btns">
            <a
              className="notifications__btn btn btn-dark"
              href="/notifications"
            >
              See all incoming activity
            </a>
          </div>
        </div>
      </div>
      <a className="header__user" href="/">
        <img className="header__pic" src="./assets/img/ava.png" alt="" />
      </a>
    </div>
  );
});

export default SearchAndNotification;
