import { BehaviorSubject } from "rxjs";

import PAGE_SIZE from "../constants/pagination";
import apiSvc from "./apiService";

class MessageService {
  inboxMessages$ = new BehaviorSubject();

  static getMessage(id) {
    return apiSvc.get(`/messages?message_id=${id}`);
  }

  static getMessages(pageNumber = 1) {
    return apiSvc.get(
      `/messages?pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}`,
    );
  }

  static getMessagesCount(noSpinner) {
    return apiSvc.get(`/messages/count?noSpinner=${noSpinner}`);
  }

  static postMessage(contractId, hospitalId, message) {
    return apiSvc.post("/messages", {
      contract_id: contractId,
      hospital_id: hospitalId,
      message,
    });
  }

  static postReply(messageId, reply) {
    return apiSvc.post("/messages/reply", {
      message_id: messageId,
      reply,
    });
  }
}

export const messageSvc = new MessageService();

export default MessageService;
