import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { filter } from "rxjs";
import { loginRequest } from "../config/auth-config";
import { authSvc } from "../services/authService";

function Login() {
  const navigate = useNavigate();
  const { instance } = useMsal();

  useEffect(() => {
    const sub = authSvc
      .isAuthenticated()
      .pipe(filter((isAuthenticated) => isAuthenticated))
      .subscribe(() => navigate("/home"));

    return () => {
      sub.unsubscribe();
    };
  }, []);

  async function submitHandler(e) {
    e.preventDefault();
    instance.loginRedirect(loginRequest);
  }

  return (
    <div>
      <div className="vh-100 d-flex justify-content-center align-items-center ">
        <div className="col-md-5 p-5 shadow-sm border rounded-5 border-primary bg-white">
          <h2
            className="text-center mb-4 "
            style={{ color: "rgb(108, 93, 211)" }}
          >
            Login
          </h2>
          <form onSubmit={submitHandler}>
            <div className="form-group form-check text-center">
              <label className="form-check-label" htmlFor="exampleCheck1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  required
                />
                Accept Terms and Conditions
              </label>
            </div>

            {/* <Link className="d-grid"> */}
            <div className="col-md-12 text-center">
              <button
                className="btn btn-primary"
                type="submit"
                style={{ backgroundColor: "#6c5dd3" }}
              >
                Login
              </button>
            </div>
            {/* </Link> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
