import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import { dropdownStyles } from "../../constants";
import { fixedFacilities } from "../../constants/facilities";
import capabilitiesSvc from "../../services/capabilitiesService";
import ContractService from "../../services/contractService";
import facilitiesSvc from "../../services/facilitiesService";
import patientSvc from "../../services/patientService";
import store from "../../store";
import { fromPlainDateToIsoString, fromUtcDateToDateString } from "../../utils";

const CreateContract = observer(({ contractDetails, created, hide }) => {
  const contractUri = useRef("");

  const [capabilities, setCapabilities] = useState([]);
  const [contractFile, setContractFile] = useState(null);
  const [contractLength, setContractLength] = useState();
  const [contractRate, setContractRate] = useState("");
  const [drugs, setDrugs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [maxRadius, setMaxRadius] = useState();
  const [hospitalsLookup, setHospitalsLookup] = useState([]);
  const [patients, setPatients] = useState([]);
  const [radius, setRadius] = useState(10);
  const [selectedCapabilities, setSelectedCapabilities] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState(null);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [startDate, setStartDate] = useState("");
  // const [suggestedRate, setSuggestedRate] = useState(0);
  const [touched, setTouched] = useState(false);

  const createContract = async (e) => {
    e.preventDefault();

    setTouched(true);

    if (
      !selectedHospital ||
      !selectedPatient ||
      !radius ||
      !startDate ||
      !selectedContractType ||
      !contractLength ||
      !contractRate ||
      (!editMode && !contractFile)
    ) {
      return;
    }

    try {
      if (contractFile) {
        contractUri.current = await ContractService.uploadContract(
          contractFile,
        );
      }

      const data = {
        ...(contractDetails || {}),
        hospital_id: selectedHospital.value,
        patient_id: selectedPatient.value,
        start_date: fromPlainDateToIsoString(startDate),
        contract_type: selectedContractType.value,
        contract_rate: parseInt(contractRate, 10),
        contract_length: parseInt(contractLength, 10),
        radius: radius.value,
        max_radius: maxRadius.value,
        capabilities: selectedCapabilities.map((x) => ({
          id: x.value,
          name: x.label,
          suggested_rate: x.suggested_rate,
        })),
      };

      if (contractUri.current) {
        data.contract_uri = contractUri.current;
      }

      if (!editMode) {
        await ContractService.createContract(data);
      } else {
        await ContractService.updateContract(data);
      }

      setTouched(false);
      created();
    } catch (err) {
      // console.error(err);
    }
  };

  const removeFile = () => {
    setContractFile(null);
  };

  const refreshDrugSuggestedRates = (patientId) => {
    patientSvc
      .getDrugSuggestedRates(selectedHospital.value, patientId)
      .then(setDrugs);
  };

  const contractRadiusOptions = Array.from({ length: 10 }).map((_, index) => ({
    value: (index + 1) * 10,
    label: ((index + 1) * 10).toString(),
  }));

  useEffect(() => {
    facilitiesSvc.getFacilitiesByType("hospital").then((val) =>
      setHospitalsLookup(
        val.map((x) => ({
          value: x.id,
          label: x.facility_name,
        })),
      ),
    );

    if (contractDetails) {
      setContractLength(contractDetails.contract_length);
      setContractRate(contractDetails.contract_rate);
      setRadius(contractDetails.radius);
      setMaxRadius(contractDetails.expandedRadius);
      setStartDate(fromUtcDateToDateString(contractDetails.start_date));

      setSelectedContractType({
        value: contractDetails.contract_type,
        label: fixedFacilities[contractDetails.contract_type],
      });

      setSelectedPatient({
        value: contractDetails.patient_id,
        label: contractDetails.patient_name,
      });

      setSelectedHospital({
        value: contractDetails.hospital_id,
        label: contractDetails.hospital_name,
      });

      setSelectedCapabilities(
        contractDetails.capabilities.map((x) => ({
          value: x.id,
          label: x.name,
          suggested_rate: x.suggested_rate,
        })),
      );

      contractUri.current = contractDetails.contract_uri;
      setEditMode(true);
      setTouched(true);
    }

    // setSuggestedRate(100);
  }, []);

  useEffect(() => {
    setSelectedPatient(null);
    setDrugs([]);

    if (selectedHospital) {
      patientSvc.getLookup(selectedHospital.value).then((resp) => {
        setPatients(resp.sort((a, b) => (a.name > b.name ? 1 : -1)) || []);
        if (contractDetails && contractDetails.patient_id) {
          const patient = resp.find((x) => x.id === contractDetails.patient_id);

          if (patient) {
            setSelectedPatient({
              value: contractDetails.patient_id,
              label: contractDetails.patient_name,
            });
          }
        }
      });
    } else {
      setPatients([]);
    }
  }, [selectedHospital]);

  useEffect(() => {
    setCapabilities([]);

    if (selectedContractType) {
      capabilitiesSvc.getAll(selectedContractType.value).then(setCapabilities);
    }
  }, [selectedContractType]);

  return (
    <Modal size="lg" show onHide={hide} animation>
      <Modal.Header
        closeButton
        closeVariant={store.profile.dark_mode ? "white" : ""}
      >
        <Modal.Title className="ps-4">
          {!editMode ? "Create" : "Update"} Contract
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="popup__form p-0">
          <div className="popup__fieldset">
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label  mb-0 mb-0 ${
                    touched && !selectedHospital && "invalid"
                  }`}
                >
                  Hospital
                </div>
                <div className="field__wrap">
                  <Select
                    options={hospitalsLookup}
                    isDisabled={editMode}
                    placeholder="Select a hospital"
                    value={selectedHospital}
                    onChange={(option) => {
                      setSelectedHospital(option);
                    }}
                    styles={dropdownStyles(store.profile.dark_mode)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div
                  className={`field__label  mb-0 ${
                    touched && !selectedPatient && "invalid"
                  }`}
                >
                  Patient
                </div>
                <div className="field__wrap">
                  <Select
                    options={patients.map((patient) => ({
                      value: patient.id,
                      label: patient.name,
                    }))}
                    isDisabled={editMode}
                    value={selectedPatient}
                    noOptionsMessage={() =>
                      selectedHospital
                        ? "We don't have any patients in the selected hospital."
                        : "Please select the hospital to view patients."
                    }
                    isSearchable
                    placeholder="Select a patient"
                    styles={dropdownStyles(store.profile.dark_mode)}
                    onChange={(option) => {
                      setSelectedPatient(option);
                      refreshDrugSuggestedRates(option.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label  mb-0 ${
                    touched && !startDate && "invalid"
                  }`}
                >
                  Start Date
                </div>
                <input
                  className="field__input"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <div className="field__wrap" />
              </div>
              <div className="popup__field field">
                <div
                  className={`field__label  mb-0 ${
                    touched && !selectedContractType && "invalid"
                  }`}
                >
                  Contract Type
                </div>
                <div className="field__wrap">
                  <Select
                    options={Object.entries(fixedFacilities).map((entry) => ({
                      value: entry[0],
                      label: entry[1],
                    }))}
                    isDisabled={editMode}
                    value={selectedContractType}
                    onChange={(option) => setSelectedContractType(option)}
                    placeholder="Select Contract Type"
                    styles={dropdownStyles(store.profile.dark_mode)}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label  mb-0 ${
                    touched && !radius && "invalid"
                  }`}
                >
                  Radius
                </div>
                <div className="field__wrap">
                  <Select
                    options={contractRadiusOptions}
                    isDisabled={editMode}
                    value={radius}
                    isSearchable
                    placeholder="Select initial radius"
                    styles={dropdownStyles(store.profile.dark_mode)}
                    onChange={(option) => {
                      setRadius(option);
                    }}
                  />
                </div>
              </div>

              <div className="popup__field field">
                <div className="field__label  mb-0">Max Radius (miles)</div>
                <div className="field__wrap">
                  <Select
                    options={contractRadiusOptions}
                    isDisabled={editMode}
                    value={maxRadius}
                    isSearchable
                    placeholder="Select max radius"
                    styles={dropdownStyles(store.profile.dark_mode)}
                    onChange={(option) => {
                      setMaxRadius(option);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div className="field__label mb-0">Capabilities</div>
                <div className="field__wrap">
                  <Select
                    options={capabilities.map((facility) => ({
                      value: facility.id,
                      label: facility.name,
                      suggested_rate: facility.suggested_rate,
                    }))}
                    isDisabled={editMode}
                    value={selectedCapabilities}
                    onChange={(options) => setSelectedCapabilities(options)}
                    isMulti
                    placeholder="Select capabilities"
                    noOptionsMessage={() =>
                      selectedContractType
                        ? "Select any other contract type"
                        : "Select a contract type"
                    }
                    styles={dropdownStyles(store.profile.dark_mode)}
                  />
                </div>
              </div>
              <div className="popup__field field">
                <div
                  className={`field__label  mb-0 ${
                    touched && !contractLength && "invalid"
                  }`}
                >
                  Contract Length
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    value={contractLength}
                    onChange={(event) =>
                      setContractLength(event.target.value.replace(/\D/g, ""))
                    }
                    type="number"
                    min={1}
                  />
                </div>
              </div>
            </div>
            <div className="popup__row">
              <div className="popup__field field">
                <div
                  className={`field__label  mb-0 ${
                    touched && !contractRate && "invalid"
                  }`}
                >
                  Contract Rate
                  {/* <OverlayTrigger
                    className=""
                    placement="top"
                    overlay={
                      <Tooltip>
                        Suggested rate for this contract is ${suggestedRate}
                        /day
                      </Tooltip>
                    }
                  >
                    <span style={{ marginLeft: 8, marginTop: -5 }}>
                      {fixIconStyles(hintIcon, store.profile.dark_mode)}
                    </span>
                  </OverlayTrigger> */}
                </div>
                <div className="field__wrap">
                  <input
                    className="field__input"
                    value={contractRate}
                    onChange={(event) =>
                      setContractRate(event.target.value.replace(/\D/g, ""))
                    }
                    type="number"
                    min={1}
                  />
                </div>
              </div>
            </div>

            {!editMode && (
              <div className="d-flex gap-3">
                <div className="d-flex flex-column flex-fill">
                  <h6>Capabilities</h6>
                  <div className="table-container">
                    <div className="table-header">
                      <div className="cell">Name</div>
                      <div className="cell">Suggested Rate</div>
                    </div>
                    {selectedCapabilities.length === 0 && (
                      <div className="table-row">
                        <div className="text-center caption p-2">
                          Please select capabilities to get suggested rates.
                        </div>
                      </div>
                    )}
                    {selectedCapabilities.map((selectedCapability) => (
                      <div className="table-row" key={selectedCapability.id}>
                        <div className="cell">{selectedCapability.label}</div>
                        <div className="cell">
                          {selectedCapability.suggested_rate}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex flex-column flex-fill ml-2">
                  <h6>Drugs</h6>
                  <div className="table-container">
                    <div className="table-header">
                      <div className="cell">Name</div>
                      <div className="cell">Suggested Rate</div>
                    </div>
                    {drugs.length === 0 && (
                      <div className="table-row">
                        <div className="text-center caption p-2">
                          Please select a patient to get suggested rates.
                        </div>
                      </div>
                    )}
                    {drugs.map((drug) => (
                      <div className="table-row" key={drug.id}>
                        <div className="cell">{drug.id}</div>
                        <div className="cell">{drug.suggested_rate}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="">
              <label
                htmlFor="pdf_upload"
                className={`pdf_upload ${
                  touched && !editMode && !contractFile && "invalid"
                }`}
              >
                <input
                  id="pdf_upload"
                  className="visually-hidden"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files) {
                      setContractFile(e.target.files[0]);
                    }
                  }}
                />
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    fill="currentColor"
                    className="bi bi-cloud-upload"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
                    />
                  </svg>
                </span>
                <p className="pt-3">
                  Drag & drop files or{" "}
                  <span className="text-primary ">Browse</span>
                </p>
              </label>
            </div>
          </div>
          {contractFile && (
            <div className="d-flex align-items-center">
              <div className="mr-2">
                {" "}
                {contractFile.name}
                <div
                  className="btn btn-link remove-file"
                  onClick={removeFile}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      removeFile();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  style={{ height: "47px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.354 4.354a.5.5 0 0 1 .792-.792L8 7.208l2.854-2.646a.5.5 0 0 1 .792.792L8.707 8l2.647 2.854a.5.5 0 0 1-.792.792L8 8.792 5.146 11.44a.5.5 0 0 1-.792-.792L7.293 8 4.646 5.146a.5.5 0 0 1 .708-.708z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-dark"
              type="button"
              onClick={createContract}
            >
              {!editMode ? "Create" : "Update"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
});

export default CreateContract;
