import { observer } from "mobx-react-lite";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import Stepper from "react-stepper-horizontal";
import { v4 } from "uuid";

import ContractService from "../../services/contractService";
import store from "../../store";
import { formatDate, formatDateTime } from "../../utils";

import "./ContractDetails.css";

const ContractDetails = observer(({ contractDetails, hide, process }) => {
  const {
    acceptanceDate = contractDetails.acceptance_date,
    acceptedBy = contractDetails.accepted_by,
    approvalDate = contractDetails.approval_date,
    approvedBy = contractDetails.approved_by,
    capabilities,
    contractRate = contractDetails.contract_rate,
    contractLength = contractDetails.contract_length,
    contractType = contractDetails.contract_type,
    contractUri = contractDetails.contract_uri,
    facilityName = contractDetails.facility_name,
    hospitalId = contractDetails.hospital_id,
    hospitalName = contractDetails.hospital_name,
    id = contractDetails.id,
    patientName = contractDetails.patient_name,
    refusals,
    startDate = contractDetails.start_date,
    status,
    timestamp,
    version,
  } = contractDetails;

  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + contractLength - 1);

  const [comments, setComments] = useState("");
  const [error, setError] = useState(false);

  const onAcceptClick = () =>
    ContractService.acceptContract(hospitalId, id, comments).then(process);

  const onApproveClick = () =>
    ContractService.approveContract(id).then(process);

  const onRefuseClick = () => {
    if (!comments) {
      setError(true);
      return;
    }

    setError(false);

    ContractService.refuseContract(hospitalId, id, comments).then(process);
  };

  const now = Date.now();

  let activeStep;

  if (approvalDate && endDate.getTime() < now) {
    activeStep = 4;
  } else if (approvalDate && now >= new Date(startDate).getTime()) {
    activeStep = 3;
  } else if (approvalDate) {
    activeStep = 2;
  } else if (acceptanceDate) {
    activeStep = 1;
  } else {
    activeStep = 0;
  }

  const steps = [
    {
      label: "Sent to Facilities",
      timestamp: formatDate(timestamp),
    },
    {
      label: "Facility Acceptance",
      timestamp: acceptanceDate ? formatDate(acceptanceDate) : "",
    },
    {
      label: "Hospital Approval",
      timestamp: approvalDate ? formatDate(approvalDate) : "",
    },
    {
      label: now >= new Date(startDate).getTime() ? "Started On" : "Starts On",
      timestamp: formatDate(startDate),
    },
    {
      label: endDate.getTime() < now ? "Finished On" : "Finishes On",
      timestamp: formatDate(endDate),
    },
  ];

  const canAcceptContract =
    store.profile.company === contractType &&
    !acceptanceDate &&
    (!refusals || !refusals.find((x) => x.refused_by.id === store.profile.id));

  const [contractVersions, setContractVersions] = useState([]);

  useEffect(() => {
    setContractVersions([]);
    if (id) {
      ContractService.getContractVersions(id).then((resp) => {
        setContractVersions(resp);
      });
    }
  }, [id]);

  return (
    <Modal size="lg" show onHide={hide} animation centered>
      {store.profile.dark_mode ? (
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="ps-4">Contract Details</Modal.Title>
        </Modal.Header>
      ) : (
        <Modal.Header closeButton>
          <Modal.Title className="ps-4">
            Contract Details{" "}
            {version > 1 && (
              <span
                className="products__status caption bg-purple"
                style={{ fontSize: "18px" }}
              >
                V{version}
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body className="px-4 py-3">
        <div className="custom-stepper" style={{ marginBottom: 20 }}>
          <Stepper
            className="stepper"
            steps={steps.map((step) => ({
              title: (
                <div>
                  <span
                    className="stepper-text"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    {step.label}
                  </span>
                  <div
                    className="caption color-gray"
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    {step.timestamp}
                  </div>
                </div>
              ),
            }))}
            activeStep={activeStep}
            activeColor="#6c5dd3"
            completeColor="#6c5dd3"
            completeBarColor="#6c5dd3"
            completeOpacity="0.9"
            defaultBarColor="#6c5dd3"
            circleTop={0}
            circleFontColor="black"
            circleFontSize={18}
          />
        </div>

        <Accordion>
          {contractVersions &&
            contractVersions.map((contractVersion, index) => (
              <Accordion.Item key={v4()} eventKey={index.toString()}>
                <Accordion.Header>
                  Version {contractVersion.version}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row gy-4">
                    <div className="col-6">
                      <div className="Contract_details_box">
                        <div className="products__details">
                          <div className="products__info caption color-gray">
                            Contract Rate
                          </div>
                          <div className="products__title title mt-2">
                            {contractVersion.contract_rate}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="Contract_details_box">
                        <div className="products__details">
                          <div className="products__info caption color-gray">
                            Contract Length
                          </div>
                          <div className="products__title title mt-2">
                            {contractVersion.contract_length}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="Contract_details_box">
                        <div className="products__details">
                          <div className="products__info caption color-gray">
                            Start Date
                          </div>
                          <div className="products__title title mt-2">
                            {new Date(
                              contractVersion.start_date,
                            ).toLocaleDateString()}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 ">
                      <a href={contractVersion.contract_uri}>
                        <div className="Contract_details_box">
                          <div className="products__details">
                            <div className="products__info caption color-gray">
                              PDF{" "}
                            </div>
                            <div className="products__title title mt-2 d-flex align-items-center text-primary">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="currentColor"
                                className="bi bi-file-earmark-pdf"
                                viewBox="0 0 16 16"
                              >
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                              </svg>
                              <span className="ps-2">Contract</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>

        <div className="row gy-4">
          <div className="col-6">
            <div className="Contract_details_box">
              <div className="products__details">
                <div className="products__info caption color-gray">
                  Hospital Name
                </div>
                <div className="products__title title mt-2">{hospitalName}</div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="Contract_details_box">
              <div className="products__details">
                <div className="products__info caption color-gray">
                  Patient name
                </div>
                <div className="products__title title mt-2">{patientName}</div>
              </div>
            </div>
          </div>

          <div className="col-6 mt-2">
            <div className="Contract_details_box">
              <div className="products__details">
                <div className="products__info caption color-gray">
                  Contract Rate
                </div>
                <div
                  className={`products__title title mt-2 ${
                    !contractRate ? "color-red" : ""
                  }`}
                >
                  ${contractRate.toLocaleString()}
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 mt-2">
            <div className="Contract_details_box">
              <div className="products__details">
                <div className="products__info caption color-gray">
                  Contract Length
                </div>
                <div className="products__title title mt-2">
                  {contractLength}
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 mt-2">
            <div className="Contract_details_box">
              <div className="products__details">
                <div className="products__info caption color-gray">
                  Capabilities
                </div>
                <div className="products__title title mt-2">
                  {capabilities.map((x) => x.name).join(", ")}
                </div>
              </div>
            </div>
          </div>

          {facilityName && <div className="col-6 mt-2" />}

          {facilityName && (
            <div className="col-6 mt-2">
              <div className="Contract_details_box">
                <div className="products__details">
                  <div className="products__info caption color-gray">
                    Facility Name
                  </div>
                  <div className="products__title title mt-2">
                    {facilityName}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!!acceptedBy && (
            <div className="col-6 mt-2">
              <div className="Contract_details_box">
                <div className="products__details">
                  <div className="products__info caption color-gray">
                    Accepted By
                  </div>
                  <div className="products__title title mt-2">
                    {acceptedBy.display_name}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!!approvedBy && (
            <div className="col-6 mt-2">
              <div className="Contract_details_box">
                <div className="products__details">
                  <div className="products__info caption color-gray">
                    Approved By
                  </div>
                  <div className="products__title title mt-2">
                    {approvedBy.display_name}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-6 mt-2">
            <a href={contractUri}>
              <div className="Contract_details_box">
                <div className="products__details">
                  <div className="products__info caption color-gray">PDF </div>
                  <div className="products__title title mt-2 d-flex align-items-center text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-file-earmark-pdf"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                      <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                    </svg>
                    <span className="ps-2">Contract</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        {store.profile.company === "hospital" &&
          refusals &&
          refusals.length > 0 && (
            <div className="table-container">
              <div className="table-header">
                <div className="cell">Facility</div>
                <div className="cell">Refused by</div>
                <div className="cell">Refused on</div>
                <div className="cell">Comments</div>
              </div>
              {refusals.map((refusal) => (
                <div className="table-row" key={`refusal_${refusal.id}`}>
                  <div className="cell">{refusal.facility_name}</div>
                  <div className="cell">{refusal.refused_by.display_name}</div>
                  <div className="cell">
                    {formatDateTime(refusal.refusal_date)}
                  </div>
                  <div className="cell remaining-width">{refusal.reason}</div>
                </div>
              ))}
            </div>
          )}

        <div className="row pt-4 gy-4">
          <div className="col-12 mt-2">
            {canAcceptContract && (
              <>
                <textarea
                  name=""
                  id=""
                  className="field__textarea textarea"
                  placeholder="You can add your comments here..."
                  onChange={(e) => setComments(e.target.value)}
                />

                {error && (
                  <div className="flex items-start text-danger">
                    <div>
                      We would like to know the reason to refuse this contract.
                    </div>
                  </div>
                )}

                <div className="text-right mt-2">
                  <button
                    type="button"
                    className="action-button btn btn-dark mx-2"
                    onClick={onAcceptClick}
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    className="action-button btn btn-dark"
                    onClick={onRefuseClick}
                  >
                    Refuse
                  </button>
                </div>
              </>
            )}

            {store.profile.company === "hospital" && status === "Accepted" && (
              <button
                type="button"
                className="action-button btn btn-dark"
                onClick={onApproveClick}
              >
                Approve
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

ContractDetails.propTypes = {
  contractDetails: {
    acceptance_date: PropTypes.string,
    accepted_by: PropTypes.string,
    approval_date: PropTypes.string,
    approved_by: PropTypes.string,
    capabilities: PropTypes.string,
    contract_length: PropTypes.string,
    contract_type: PropTypes.string,
    contract_uri: PropTypes.string,
    facility_name: PropTypes.string,
    hospital_id: PropTypes.string,
    hospital_name: PropTypes.string,
    id: PropTypes.string,
    patient_name: PropTypes.string,
    refusals: PropTypes.string,
    start_date: PropTypes.string,
    status: PropTypes.string,
    timestamp: PropTypes.string,
  },
};

ContractDetails.defaultProps = {
  contractDetails: [], // Provide a default value that matches the expected data type
};

export default ContractDetails;
