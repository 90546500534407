import PropTypes from "prop-types";
import { useState } from "react";

function TextInput({ data }) {
  const { label, readOnly, type, value } = data;
  const [val, setValue] = useState(value);

  return (
    <div className="popup__field field">
      <div className="field__label">{label}</div>
      <div className="field__wrap">
        <input
          className="field__input"
          type={type}
          value={val}
          onChange={(event) => setValue(event.target.value)}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}

TextInput.propTypes = {
  data: {
    label: PropTypes.string,
    readOnly: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  },
  // onChange: PropTypes.func,
};

TextInput.defaultProps = {
  data: [],
  // onChange: () => {},
};
export default TextInput;
