function Address({ address }) {
  return (
    <div>
      {address.address_1 && (
        <>
          {address.address_1}
          <br />
        </>
      )}
      {address.address_2 && (
        <>
          {address.address_2}
          <br />
        </>
      )}
      {address.city},{address.zip}
      <br />
      {address.state},{address.country}
      <br />
    </div>
  );
}
Address.propTypes = {
  address: {},
};

Address.defaultProps = {
  address: [], // Provide a default value that matches the expected data type
};
export default Address;
