import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";

import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import PAGE_SIZE from "../constants/pagination";
import NotificationService, {
  notificationSvc,
} from "../services/notificationService";
import store from "../store";
import "../Style/App.css";

const NotificationsWithComment = observer(() => {
  const pageNumber = useRef(1);

  const [hasMore, setHasMore] = useState(true); // To track if there are more notifications to load
  const [notifications, setNotifications] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [types, setTypes] = useState([]);

  const loadNotifications = () =>
    NotificationService.getPagedNotifications(
      pageNumber.current,
      PAGE_SIZE,
      selectedType,
    ).then((resp) => {
      setNotifications(resp || []);
      setHasMore((resp || []).length === PAGE_SIZE);
    });

  const loadMore = () => {
    pageNumber.current += 1;
    NotificationService.getPagedNotifications(pageNumber.current).then(
      (resp) => {
        setNotifications([...notifications, ...(resp || [])]);
        setHasMore((resp || []).length === PAGE_SIZE);
      },
    );
  };

  useEffect(() => {
    loadNotifications();

    NotificationService.getTypes().then((resp) => {
      setTypes(resp || []);
    });
  }, [selectedType]);

  useEffect(() => {
    notificationSvc.markAllAsRead();
  }, []);

  const handleTagClick = (type) => {
    setSelectedType(type);
  };

  return (
    <div>
      <Leftsidebar />
      <div className="page__wrapper">
        <div className="page__center">
          <div className="page__row page__row_responsive page__row_head page__row_border">
            <div className="page__col">
              <div className="page__hello h5">
                Hi {store.profile.display_name},
              </div>
              <div className="page__welcome h2">Notifications</div>
            </div>
            <div className="page__col">
              <SearchAndNotification />
            </div>
          </div>
          <div className="page__row page__row_responsive page__row_border">
            <div className="page__col">
              <div className="notification">
                <div className="notification__tags">
                  {types.map((notificationType) => (
                    <button
                      className={`notification__tag `}
                      type="button"
                      onClick={() => handleTagClick(notificationType)}
                      key={v4()}
                    >
                      {notificationType}
                    </button>
                  ))}
                </div>
                <div className="notification__container">
                  <div className="notification__title h6">
                    Recent Notifications
                  </div>
                  {notifications.length > 0 && (
                    <div className="notification__list">
                      {(selectedType ? notifications : notifications).map(
                        (notification) => {
                          return (
                            <div
                              className="notification__item"
                              key={`notification_${notification.id}`}
                            >
                              <div className="notification__ava">
                                <img
                                  className="notification__pic"
                                  src="./assets/img/figure-1.png"
                                  alt=""
                                />
                                {/* <div className="notification__status bg-blue">
                                <img
                                  className="notification__pic"
                                  src="./assets/img/chat.svg"
                                  alt=""
                                />
                              </div> */}
                              </div>
                              <div className="notification__details">
                                <div className="d-flex flex-row">
                                  <div className="notification__author title flex-fill">
                                    {notification.title}
                                  </div>
                                  <div className="flex-fill text-right">
                                    <Link
                                      to={`/${notification.url}`}
                                      className="notification-link"
                                    >
                                      View
                                    </Link>
                                  </div>
                                </div>
                                <div className="notification__line caption">
                                  <div className="notification__time">
                                    {moment.utc(notification.time).fromNow()}
                                  </div>
                                </div>
                                <div className="inbox__text">
                                  {notification.description}
                                </div>
                                {/* <div className="actions">
                                <button type="button" className="actions__btn">
                                  {fixIconStyles(
                                    commentIcon,
                                    store.profile.dark_mode,
                                  )}
                                </button>
                                <button type="button" className="actions__btn">
                                  {fixIconStyles(
                                    likeIcon,
                                    store.profile.dark_mode,
                                  )}
                                </button>
                                <button type="button" className="actions__btn">
                                  {fixIconStyles(
                                    starIcon,
                                    store.profile.dark_mode,
                                  )}
                                </button>
                                <button type="button" className="actions__btn">
                                  {fixIconStyles(
                                    moreIcon,
                                    store.profile.dark_mode,
                                  )}
                                </button>
                              </div> */}
                              </div>
                              {/* <div className="notification__preview">
                              <img
                                className="notification__pic"
                                src="./assets/img/ava-1.png"
                                alt=""
                              />
                            </div> */}
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
                  {notifications.length === 0 && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ height: 200 }}
                    >
                      <div className="text-center">No recent notifications</div>
                    </div>
                  )}
                  {hasMore && (
                    <div className="notification__btns">
                      <button
                        type="button"
                        onClick={loadMore}
                        className="notification__btn btn btn-dark"
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="page__col">
              {/* <div className="notification">
                <div className="notification__title h6">From Notification</div>
                <div className="notification__photo">
                  <img
                    className="notification__pic"
                    src="./assets/img/notification-pic.png"
                    alt=""
                  />
                </div>
                <div className="notification__body">
                  <div className="notification__stage h5">Collab Landing.</div>
                  <div className="notification__parameters">
                    <div className="notification__parameter">
                      <div className="notification__icon">
                        <img
                          className="notification__pic"
                          src="./assets/img/user.svg"
                          alt=""
                        />
                      </div>
                      <div className="caption">Glenn Greer</div>
                    </div>
                    <div className="notification__parameter">
                      <div className="notification__icon">
                        <img
                          className="notification__pic"
                          src="./assets/img/clock.svg"
                          alt=""
                        />
                      </div>
                      <div className="caption">12h</div>
                    </div>
                  </div>
                  <div className="notification__text color-gray">
                    &ldquo;Love this so much! What tools do you use to create
                    your 3d illustrations?&rdquo;
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default NotificationsWithComment;
