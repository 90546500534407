import { observer } from "mobx-react-lite";
import { v4 as idV4 } from "uuid";
import Leftsidebar from "../components/Leftsidebar";
import SearchAndNotification from "../components/SearchAndNotification";
import TopBanner from "../components/TopBanner";
import Btn from "../components/buttons/Btn";
import Editor from "../components/editor/Editor";
import store from "../store";

const Comments = observer(() => {
  const editor = {
    editor__gallery: [
      "./assets/img/editor-pic-1.png",
      "./assets/img/editor-pic-2.png",
    ],
    filesAtachment: [
      {
        bg: "purple",
        files__pic: "./assets/img/figure-1.png",
        files__title: "Attachment.PNG",
        files__size: "3.6 MB",
      },
      {
        bg: "blue",
        files__pic: "./assets/img/figure-1.png",
        files__title: "Attachment.PNG",
        files__size: "3.6 MB",
      },
    ],
  };
  const filterCheckbox = [
    {
      label: "Likes",
    },
    {
      label: "Comments",
    },
    {
      label: "Mention",
    },
    {
      label: "Followers",
    },
    {
      label: "Mention",
    },
    {
      label: "Other",
    },
  ];
  const activityList = [
    {
      activityPic: "./assets/img/ava-4.png",
      activityStatusPic: "./assets/img/chat-black.svg",
      activityAuthor: "Glenn Greer",
      activityCategory: "Commented on",
      activityPreviewPic: "./assets/img/figure-5.png",
      activityInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
    {
      activityPic: "./assets/img/ava-4.png",
      activityStatusPic: "./assets/img/chat-black.svg",
      activityAuthor: "Glenn Greer",
      activityCategory: "Commented on",
      activityPreviewPic: "./assets/img/figure-5.png",
      activityInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
    {
      activityPic: "./assets/img/ava-4.png",
      activityStatusPic: "./assets/img/chat-black.svg",
      activityAuthor: "Glenn Greer",
      activityCategory: "Commented on",
      activityPreviewPic: "./assets/img/figure-5.png",
      activityInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
    {
      activityPic: "./assets/img/ava-4.png",
      activityStatusPic: "./assets/img/chat-black.svg",
      activityAuthor: "Glenn Greer",
      activityCategory: "Commented on",
      activityPreviewPic: "./assets/img/figure-5.png",
      activityInfo: "Collab",
      inboxText:
        "Love this so much! What tools do you use to create your 3d illustrations?",
    },
  ];
  const bannerContent = {
    btnLabel: "Check all settings",
    bannerTitle: "Your Patient",
    bannerText: "Create Your Patient Dashboard in Minutes",
    img: "./assets/img/banner-pic.png",
  };
  return (
    <div>
      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi
                  {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Comments</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__content">
              <div className="banner">
                <TopBanner data={bannerContent} />

                <div className="activity">
                  <div className="activity__row">
                    <div className="activity__col">
                      <div className="activity__container">
                        <div className="activity__title h6">
                          Recent Activity
                        </div>
                        <div className="activity__inner">
                          <div className="activity__variants">
                            {filterCheckbox.map((data) => {
                              const { label } = data;
                              return (
                                <label
                                  htmlFor="checkbox"
                                  className="checkbox"
                                  key={idV4()}
                                >
                                  <input
                                    className="checkbox__input"
                                    type="checkbox"
                                  />
                                  <span className="checkbox__in">
                                    <span className="checkbox__tick" />
                                    <span className="checkbox__text">
                                      {label}
                                    </span>
                                  </span>
                                </label>
                              );
                            })}
                          </div>
                          <div className="activity__group">
                            <div className="activity__list">
                              {activityList.map((data) => {
                                const {
                                  activityPreviewPic,
                                  activityStatusPic,
                                  activityInfo,
                                  activityText,
                                  activityCategory,
                                  activityAuthor,
                                  activityPic,
                                } = data;
                                return (
                                  <div
                                    className={`activity__item ${
                                      idV4() === 0 ? "active" : ""
                                    }`}
                                  >
                                    <div className="activity__ava">
                                      <img
                                        className="activity__pic"
                                        src={activityPic}
                                        alt=""
                                      />
                                      <div className="activity__status">
                                        <img
                                          className="activity__pic"
                                          src={activityStatusPic}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="activity__details">
                                      <div className="activity__author title">
                                        {activityAuthor}
                                      </div>
                                      <div className="activity__line caption">
                                        <div className="activity__category">
                                          {activityCategory}
                                        </div>
                                        <div className="activity__info">
                                          {activityInfo}
                                        </div>
                                        <div className="activity__time">
                                          12h
                                        </div>
                                      </div>
                                      <div className="activity__text">
                                        {activityText}
                                      </div>
                                    </div>
                                    <div className="activity__preview">
                                      <img
                                        className="activity__pic"
                                        src={activityPreviewPic}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="activity__btns">
                              <Btn
                                data={{
                                  label: " Load More",
                                  className: "activity__btn btn-dark ",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="activity__col">
                      <div className="activity__title h6">
                        Add a new comment
                      </div>
                      <Editor data={editor} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Comments;
