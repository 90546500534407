import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import store from "../store";
import { fixIconStyles } from "../utils";

const MenuItem = observer(({ data, handleShow, counter }) => {
  const { location, icon, text, type } = data;
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");

  const iconClone = fixIconStyles(icon, store.profile.dark_mode);

  return (
    <div>
      {type === "button" ? (
        <button
          className="sidebar__item js-popup-open"
          onClick={() => handleShow()}
          data-effect="mfp-zoom-in"
          type="button"
        >
          <div className="sidebar__icon">{iconClone}</div>
          <div className="sidebar__text">{text}</div>
          {counter && <div className="sidebar__counter">{counter}</div>}
        </button>
      ) : (
        <Link
          className={
            splitLocation[1] === location
              ? "sidebar__item active"
              : "sidebar__item"
          }
          to={`/${location}`}
        >
          <div className="sidebar__icon">{iconClone}</div>
          <div className="sidebar__text">{text}</div>
          {counter !== 0 && <div className="sidebar__counter">{counter}</div>}
        </Link>
      )}
    </div>
  );
});

export default MenuItem;
