import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { filter } from "rxjs/operators";
import { v4 as idV4 } from "uuid";
import hospitalImage from "../assets/img/hospital-1.png";
import medicalAppImg from "../assets/img/medical-app-1.png";
import medicalChartImg from "../assets/img/medical-chart-1.png";
import { removeIcon } from "../assets/svg/exportSvg";
import Leftsidebar from "../components/Leftsidebar";
import ReferralsItem from "../components/ReferralsItem";
import SearchAndNotification from "../components/SearchAndNotification";
import ContractDetails from "../components/contracts/ContractDetails";
import {
  expenseLineChartOptions,
  patientTrendChartOptions,
} from "../constants/chartOptions";
import ContractService from "../services/contractService";
import { ProfileService, profileSvc } from "../services/profileService";
import store from "../store";
import { fixIconStyles, formatCurrency } from "../utils";

const currentYear = new Date().getFullYear();
const years = [currentYear - 1, currentYear];
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Home = observer(() => {
  const [contractDetails, setContractDetails] = useState(null);
  const [currentMonthExpense, setCurrentMonthExpense] = useState(0);
  const [expenseChart, setExpenseChart] = useState([]);
  const [isTitleValid, setIsTitleValid] = useState(false);
  const [latestContracts, setLatestContracts] = useState([]);
  const [patientTrend, setPatientTrend] = useState([]);
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const [title, setTitle] = useState("");
  const [touched, setTouched] = useState(false);

  const getAccountSettings = async () => {
    const profile = await ProfileService.getMyProfile();

    if (!profile) {
      return;
    }

    setTitle(profile.title || "");
  };

  useEffect(() => {
    profileSvc.signedIn$.pipe(filter((signedIn) => signedIn)).subscribe(() => {
      getAccountSettings();
    });

    ContractService.getCurrentMonthExpense().then(setCurrentMonthExpense);

    ContractService.getExpenseChart().then((val) => {
      years.forEach((year) => {
        months.forEach((month) => {
          const item = val.find((d) => d.year === year && d.month === month);

          if (!item) {
            val.push({ year, month, expense: 0 });
          }
        });
      });

      setExpenseChart(
        val.sort((a, b) => {
          if (a.year === b.year) {
            return a.month - b.month;
          }

          return a.year - b.year;
        }),
      );
    });

    ContractService.getPatientTrend().then((val) => {
      years.forEach((year) => {
        months.forEach((month) => {
          const item = val.find((d) => d.year === year && d.month === month);

          if (!item) {
            val.push({ year, month, contracts: 0 });
          }
        });
      });

      setPatientTrend(
        val.sort((a, b) => {
          if (a.year === b.year) {
            return a.month - b.month;
          }

          return a.year - b.year;
        }),
      );
    });

    ContractService.getLatestContracts().then((val) => {
      setLatestContracts(
        val.slice(0, 3).map((contract) => ({
          ...contract,
          imgSrc: { medicalAppImg },
          title: contract.patient_name,
          caption: contract.facility_name,
          link: "/home",
        })),
      );
    });
  }, []);

  const expenseChartSeries = [
    {
      name: "2022",
      type: "line",
      data: expenseChart
        .filter((x) => x.year === currentYear - 1)
        .map((x) => x.expense),
    },
    {
      name: "2023",
      type: "line",
      data: expenseChart
        .filter((x) => x.year === currentYear)
        .map((x) => x.expense),
    },
  ];

  const patientTrendSeries = [
    {
      name: "2022",
      type: "column",
      data: patientTrend
        .filter((x) => x.year === currentYear - 1)
        .map((x) => x.contracts),
    },
    {
      name: "2023",
      type: "column",
      data: patientTrend
        .filter((x) => x.year === currentYear)
        .map((x) => x.contracts),
    },
  ];

  const updateAccountSettings = async (event) => {
    setTouched(true);
    event.preventDefault();

    setIsTitleValid(title);

    if (title) {
      const payload = {
        title,
      };

      await ProfileService.updateAccountSettings(payload);
      setShowAccountSettings(false);
      setTouched(false);
    }
  };

  return (
    <>
      {contractDetails && (
        <ContractDetails
          contractDetails={contractDetails}
          hide={() => setContractDetails(null)}
        />
      )}

      <Modal
        size="lg"
        show={showAccountSettings}
        onHide={() => setShowAccountSettings(false)}
        animation
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title className="ps-4">Account Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="popup__form">
            <div className="popup__fieldset">
              <div className="popup__row">
                <div className="popup__field field">
                  <div
                    className={`field__label ${
                      touched && !isTitleValid ? "invalid" : ""
                    }`}
                  >
                    Title (their job title)
                  </div>
                  <div className="field__wrap">
                    <input
                      className="field__input"
                      type="text"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setIsTitleValid(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-dark mt-5"
              onClick={updateAccountSettings}
            >
              Update Profile
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head page__row_border">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">
                  Welcome back
                  <span className="desktop-hide">👋</span>
                </div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__row page__row_border">
              <div className="page__col">
                <div className="slider__container">
                  <div className="slider__inner">
                    <Carousel variant="light" controls={false} touch slide>
                      <Carousel.Item>
                        <div className="slider__item">
                          <div className="slider__details">
                            <div className="slider__title h5">
                              Update your contact info
                            </div>
                            <div className="slider__text">
                              This helps speeding up the process of creating and
                              updating contracts
                            </div>
                            <Button
                              className="btn btn-light"
                              data-effect="mfp-zoom-in"
                              onClick={() => setShowAccountSettings(true)}
                            >
                              Account Settings
                            </Button>
                          </div>
                          <div className="slider__preview">
                            <img
                              className="slider__pic"
                              src={medicalChartImg}
                              alt="medical chart icon"
                            />
                          </div>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="slider__item">
                          <div className="slider__details">
                            <div className="slider__title h5">
                              Set up Hospital Locations
                            </div>
                            <div className="slider__text">
                              Need to update hospital locations? check out the
                              Facilities page
                            </div>
                            <Link
                              className="btn btn-light"
                              to="/facilities"
                              data-effect="mfp-zoom-in"
                            >
                              Facilities
                            </Link>
                          </div>
                          <div className="slider__preview">
                            <img
                              className="slider__pic"
                              src={hospitalImage}
                              alt=""
                            />
                          </div>
                        </div>
                      </Carousel.Item>
                      {/* {Carousel_list?.map((data,key)=>{
                        return <CarouselItem data={data} key={key} />
                      })} */}
                    </Carousel>
                    <button type="button" className="slider__close">
                      {fixIconStyles(removeIcon, store.profile.dark_mode)}
                    </button>
                  </div>
                </div>

                <div className="page__widgets">
                  <div className="widget widget_shadow">
                    <div className="widget__title">Latest Contracts</div>
                    <div className="widget__list">
                      {latestContracts.map((contract) => (
                        <ReferralsItem
                          data={contract}
                          key={idV4()}
                          onClick={() => {
                            ContractService.getContract(
                              contract.hospital_id,
                              contract.id,
                            ).then((_contract) =>
                              setContractDetails(_contract),
                            );
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="widget widget_chart widget_pink">
                    <div className="widget__title">Expense</div>
                    <div className="widget__wrap">
                      <div className="widget__chart widget__chart_earning">
                        <Chart
                          options={expenseLineChartOptions}
                          series={expenseChartSeries}
                          type="line"
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="widget__btns">
                        <button
                          type="button"
                          className="widget__btn btn btn-dark btn_wide"
                        >
                          Financials
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page__col">
                <div className="page__group">
                  <div className="widget widget_stat widget_pb60">
                    <div className="widget__title">Total Spend This Month</div>
                    <div
                      className="widget__counter title title_md color-purple"
                      style={{ fontSize: 54 }}
                    >
                      {formatCurrency(currentMonthExpense)}
                    </div>
                    <div className="widget__text">
                      Detailed reports available on Financial Page
                    </div>
                    <Link
                      className="widget__btn btn btn_white btn_wide"
                      to="/financials"
                    >
                      View Financials
                    </Link>
                  </div>
                  <div className="widget widget_empty widget_p0">
                    <div className="widget__title">Patient Trend</div>
                    <div className="widget__chart widget__chart_impressions">
                      <Chart
                        options={patientTrendChartOptions}
                        series={patientTrendSeries}
                        height="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Home;
