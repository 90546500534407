import { observer } from "mobx-react-lite";
import { rightArrowIcon } from "../assets/svg/exportSvg";
import store from "../store";
import { fixIconStyles } from "../utils";

const TopItem = observer(({ data, isActive, onClickFunc }) => {
  const {
    img,
    title,
    caption,
    tabsPrice = data.tabs__price,
    className,
    aClass = data.a_class,
  } = data;
  return (
    <span
      className={`${aClass || "tabs__item"} ${isActive ? "active" : ""}`}
      onClick={() => {
        if (onClickFunc) {
          onClickFunc();
        }
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          // Handle keydown event for Enter key or Spacebar
          if (onClickFunc) {
            onClickFunc();
          }
        }
      }}
      role="button" // Add a role attribute to indicate that this is an interactive element
      tabIndex={0} // Add tabIndex to make it focusable
    >
      <div
        className={` ${
          aClass ? "quality__preview" : "tabs__preview"
        } ${className} `}
      >
        <img
          className={aClass ? "quality__pic" : "tabs__pic"}
          src={img}
          alt=""
        />
      </div>
      <div className="tabs__details">
        <div className="tabs__category title">
          {title}
          {fixIconStyles(rightArrowIcon, store.profile.dark_mode)}
        </div>
        <div className="tabs__info caption-sm">{caption}</div>
        <div className="tabs__price caption">{tabsPrice}</div>
      </div>
    </span>
  );
});

export default TopItem;
