import { cloneElement } from "react";

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const fixIconStyles = (icon, darkMode) =>
  cloneElement(icon, {
    style: { color: darkMode ? "white" : "#11142D" },
  });

export const removeEmptyProps = (obj) => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      result[key] = obj[key];
    }
  });

  return result;
};
