import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { v4 as idV4 } from "uuid";

function Dropdown({ data }) {
  const { selected, optionList = data.option_list, selectOption } = data;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropDownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      ref={dropDownRef}
      className={`dropdown js-dropdown ${isDropdownOpen ? "active" : ""}`}
    >
      <div
        className="dropdown__head js-dropdown-head"
        onClick={() => toggleDropdown()}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            toggleDropdown();
          }
        }}
        role="button" // Add an appropriate role for accessibility
        tabIndex={0} // Make the button focusable using the keyboard
      >
        {selected?.title}
      </div>
      <div className="dropdown__body js-dropdown-body">
        {optionList?.map((option) => (
          <Link
            className="dropdown__item"
            key={idV4()}
            to={{}}
            onClick={() => {
              setIsDropdownOpen(false);
              selectOption(option);
            }}
          >
            <div className="dropdown__title title">{option?.title} </div>
            <div className="dropdown__info caption">{option?.caption}</div>
          </Link>
        ))}
      </div>
    </div>
  );
}
Dropdown.propTypes = {
  data: {
    selected: PropTypes.string,
    option_list: PropTypes.array,
    selectOption: PropTypes.func,
  },
};

Dropdown.defaultProps = {
  data: {
    selected: "",
    option_list: [],
    selectOption: () => {},
  }, // Provide a default value that matches the expected data type
};
export default Dropdown;
