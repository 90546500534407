import { Subject } from "rxjs";
import apiSvc from "./apiService";

export class ProfileService {
  signedIn$ = new Subject();

  static deleteAvatar() {
    return apiSvc.delete("/users/me/avatar");
  }

  static getMyProfile() {
    return apiSvc.get("/users/me").catch(() => {
      return null;
    });
  }

  signIn() {
    return apiSvc
      .post("/auth/signin")
      .then(() => this.signedIn$.next(true))
      .catch(() => {});
  }

  static switchTheme(darkMode) {
    return apiSvc.post("/users/me/switch-theme", { dark_mode: darkMode });
  }

  static updateAccountSettings(accountSettings) {
    return apiSvc.post("/users/me/account-settings", accountSettings);
  }

  static updateCompleteAccountSettings(accountSettings) {
    return apiSvc.post("/users/me/account-settings/complete", accountSettings);
  }

  static uploadAvatar(file) {
    const formData = new FormData();
    formData.append("file", file);

    return apiSvc.post("/users/me/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static updateNotification(key, enabled) {
    return apiSvc.post("/users/me/notification", {
      key,
      enabled,
    });
  }
}

export const profileSvc = new ProfileService();
