import { Subject } from "rxjs";

import apiSvc from "./apiService";

class NotificationService {
  unreadNotifications$ = new Subject();

  static async getPagedNotifications(pageNumber = 1, pageSize = 10, type = "") {
    return apiSvc.get(
      `/notifications?type=${type}&page=${pageNumber}&page_size=${pageSize}`,
    );
  }

  async getRecent() {
    return apiSvc.get("/notifications/recent").then((resp) => {
      const response = resp || {
        notifications: [],
        total: 0,
      };

      this.unreadNotifications$.next(response.total);
      return response;
    });
  }

  static async getTypes() {
    return apiSvc.get("/notifications/types");
  }

  markAllAsRead() {
    return apiSvc
      .post("/notifications/read", {})
      .then(() => this.unreadNotifications$.next(0));
  }

  static subscribe(subscription) {
    return apiSvc.post("/notifications/subscribe", subscription);
  }
}

export const notificationSvc = new NotificationService();

export default NotificationService;
