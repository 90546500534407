export const formatCurrency = (value) =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 10,
  }).format(value);

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);

  const result = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i += 1) {
    result[i] = rawData.charCodeAt(i);
  }

  return result;
};
