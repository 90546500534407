import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import { formatDate } from "../../utils";

function DateRange({ dateRange, selectDateRange, acceptDateRange }) {
  const [rangePickerVisible, setRangePickerVisible] = useState(false);
  const dataRangeRef = useRef(null);
  const accept = () => {
    setRangePickerVisible(false);
    acceptDateRange();
  };

  const toggleDropdown = () => {
    setRangePickerVisible(!rangePickerVisible);
  };

  const closeDropdown = () => {
    setRangePickerVisible(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dataRangeRef.current &&
        !dataRangeRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  return (
    <div
      ref={dataRangeRef}
      className={`dropdown js-dropdown ${rangePickerVisible ? "active" : ""}`}
      style={{ width: "auto" }}
    >
      <div
        role="button"
        className="dropdown__head js-dropdown-head"
        onClick={() => toggleDropdown()}
        onKeyDown={(event) => {
          // Handle keyboard events for "Enter" or "Space" keys
          if (event.key === "Enter" || event.key === " ") {
            toggleDropdown();
          }
        }}
        tabIndex={0} // Make the button focusable using the keyboard
      >
        {formatDate(dateRange.startDate)} -{formatDate(dateRange.endDate)}
      </div>
      <div
        className="dropdown__body js-dropdown-body"
        style={{
          width: 600,
          paddingBottom: 15,
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 20,
        }}
      >
        {rangePickerVisible && (
          <div style={{ textAlign: "right" }}>
            <DateRangePicker
              ranges={[dateRange]}
              onChange={selectDateRange}
              rangeColors={["#6c5dd3"]}
            />
            <button
              type="button"
              className="btn btn-sm"
              style={{
                backgroundColor: "#6c5dd3",
                color: "#fff",
                height: 36,
                minWidth: 100,
              }}
              onClick={accept}
            >
              Select
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

DateRange.propTypes = {
  dateRange: {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  },
  selectDateRange: PropTypes.func,
  acceptDateRange: PropTypes.func,
};

DateRange.defaultProps = {
  dateRange: {},
  selectDateRange: () => {},
  acceptDateRange: () => {},
};

export default DateRange;
