import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { rightArrowIcon } from "../assets/svg/exportSvg";
import store from "../store";
import { fixIconStyles } from "../utils";

const ReferralsItem = observer(({ data, onClick }) => {
  const { imgSrc, title, caption, link } = data;
  return (
    <Link className="widget__item" to={link} onClick={onClick}>
      <div className="widget__preview bg-pink">
        <img className="widget__pic" src={imgSrc} alt="" />
      </div>
      <div className="widget__details">
        <div className="widget__category title">
          {title}

          {fixIconStyles(rightArrowIcon, store.profile.dark_mode)}
        </div>
        <div className="widget__info caption">{caption}</div>
      </div>
    </Link>
  );
});

export default ReferralsItem;
