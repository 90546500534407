import { observer } from "mobx-react-lite";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import { editorBoldIcon, editorItalicIcon } from "../../assets/svg/exportSvg";
import store from "../../store";
import { fixIconStyles } from "../../utils";
import Btn from "../buttons/Btn";

const Editor = observer(
  ({ data: { editorText }, contracts, save, setEditorText }) => {
    const [contract, setContract] = useState("");

    return (
      <div className="editor">
        <div className="editor__wrap">
          <div className="editor__head">
            <div
              className="editor__control"
              style={{ marginTop: 0, width: 120 }}
            >
              <button type="button" className="editor__action active">
                {fixIconStyles(editorBoldIcon, store.profile.dark_mode)}
              </button>
              <button type="button" className="editor__action">
                {fixIconStyles(editorItalicIcon, store.profile.dark_mode)}
              </button>
            </div>

            {contracts && (
              <Form.Select
                className="field__input"
                value={contract}
                onChange={(e) => setContract(e.target.value)}
              >
                <option value="">Select Contract</option>
                {contracts.map((item) => (
                  <option key={item.id} value={JSON.stringify(item)}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </div>
          <div className="editor__body">
            <div className="editor__field">
              <textarea
                className="editor__textarea"
                placeholder="Enter your message here..."
                value={editorText}
                onChange={(e) => setEditorText(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Btn
          data={{
            label: " Send Message",
            className: "editor__btn btn-dark ",
          }}
          onclickFunc={() =>
            save({
              contract: contract ? JSON.parse(contract) : null,
            })
          }
        />
      </div>
    );
  },
);

export default Editor;
