import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { loginRequest } from "../config/auth-config";
import store from "../store";

const anonymousPaths = ["/home", "/inbox", "/notifications", "/comments"];

const RouteGuard = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const [isAuthorized, setIsAuthorized] = useState(false);

  const currentAccount = instance.getActiveAccount();

  const authRequest = {
    ...loginRequest,
  };

  const verifyRoutePermissions = async () => {
    if (currentAccount) {
      const isAnonymousPath = anonymousPaths.indexOf(location.pathname) !== -1;
      let authorized = isAnonymousPath || store.profile.role === "admin";

      setIsAuthorized(authorized);

      if (!authorized && store.profile.role && store.navigation.length > 0) {
        authorized =
          store.navigation.filter(
            (x) =>
              x[store.profile.company][store.profile.role] &&
              location.pathname.indexOf(x.location) !== -1,
          ).length !== 0;

        setIsAuthorized(authorized);

        if (!authorized) {
          navigate(anonymousPaths[0]);
        }
      }
    }
  };

  useEffect(() => {
    verifyRoutePermissions();
  }, []);

  useEffect(() => {
    verifyRoutePermissions();
  }, [currentAccount, store.profile, store.permissions, store.navigation]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {isAuthorized ? (
        <Outlet />
      ) : (
        <div
          style={{
            backgroundColor: "#6c5dd3",
            color: "#fff",
            display: "grid",
            height: "100vh",
            placeItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: 300,
              fontSize: "1.4rem",
            }}
          >
            Please wait. We are evaluating your permissions.
          </div>
        </div>
      )}
    </MsalAuthenticationTemplate>
  );
});

export default RouteGuard;
