import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { v4 as idV4 } from "uuid";

import malePatientImg from "../assets/img/patient-male-3.png";
import Btn from "../components/buttons/Btn";
import ContractDetails from "../components/contracts/ContractDetails";
import CreateContract from "../components/contracts/CreateContract";
import DateRange from "../components/inputs/DateRange";
import Leftsidebar from "../components/Leftsidebar";
import CreatePatientModal from "../components/patients/CreatePatientModal";
import PatientDetailsModal from "../components/patients/PatientDetailsModal";
import Search from "../components/Search";
import SearchAndNotification from "../components/SearchAndNotification";
import TopBanner from "../components/TopBanner";
import PAGE_SIZE from "../constants/pagination";
import { noResults } from "../constants/styles";
import apiSvc from "../services/apiService";
import { AuthService } from "../services/authService";
import { contractSvc } from "../services/contractService";
import patientSvc from "../services/patientService";
import store from "../store";
import {
  deepClone,
  formatDate,
  formatDateTime,
  removeEmptyProps,
} from "../utils";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const Patients = observer(() => {
  const pageNumber = useRef(1);
  const searchedPatients = useRef([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [checkedPatients, setCheckedPatients] = useState([]);
  const [contract, setContract] = useState(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPatients, setNewPatients] = useState([]);
  const [patients, setPatients] = useState([]);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [showCreateContract, setShowCreateContract] = useState(false);
  const [showCreatePatient, setShowCreatePatient] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPatients, setSelectedPatients] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    key: "selection",
  });

  const updateSearchParams = (params) => {
    const id = params.id || "";
    const name = params.name || searchTerm || "";
    const hospitalId = params.hospitalId || "";
    searchParams.get("id");
    const updatedParams = {
      id: "",
      name: "",
      hospital_id: "",
    };

    if (id) {
      updatedParams.id = id;
    }

    if (name) {
      updatedParams.name = name;
    }

    if (hospitalId) {
      updatedParams.hospital_id = hospitalId;
    }
    setSearchParams(updatedParams);
  };

  const handleSearchChange = (newValue) => {
    setSearchTerm(newValue);
    updateSearchParams({ name: searchTerm });
  };
  const loadPatients = () =>
    patientSvc
      .getByDate(
        dateRange.startDate,
        dateRange.endDate,
        pageNumber.current,
        debouncedSearchTerm,
      )
      .then((resp) => {
        setPatients(resp || []);
        setHasMore((resp || []).length === PAGE_SIZE);
      });

  const addRedoxPatient = async (patient) => {
    const patientRecord = searchedPatients.current.find(
      (x) => x.id === patient.id,
    );

    if (patientRecord) {
      await patientSvc.addRedoxPatient(patientRecord);
    }

    setShowPatientDetails(false);
    setNewPatients([]);
  };

  const toggleSelectCheckBox = (e, i) => {
    const indx = checkedPatients.indexOf(i);
    const checkedPatientsCopy = [...checkedPatients];

    if (e.target.checked) {
      checkedPatientsCopy.push(i);
    } else {
      checkedPatientsCopy.splice(indx, 1);
    }

    setCheckedPatients(checkedPatientsCopy);
  };

  const checkboxAll = () => {
    if (checkedPatients.length === patients.length) {
      setCheckedPatients([]);
    } else {
      const arr = [];
      let i = 0;
      while (i < patients.length) {
        arr.push(i);
        i += 1;
      }
      setCheckedPatients(arr);
    }
  };

  const handleAcceptDateRange = () => {
    pageNumber.current = 1;
    loadPatients();
  };

  const handleSelectDateRange = (ranges) => {
    setDateRange(ranges.selection);
  };

  const hideCreateContractModal = () => {
    contractSvc.showCreateContract$.next(false);
  };

  const loadMore = () => {
    patientSvc
      .getByDate(
        dateRange.startDate,
        dateRange.endDate,
        (pageNumber.current += 1),
        debouncedSearchTerm,
      )
      .then((resp) => {
        setPatients([...patients, ...(resp || [])]);
        setHasMore((resp || []).length === PAGE_SIZE);
      });
  };

  const reshapePatientDetails = (patientDetails) => {
    const { allergies, demographics, diagnoses } = patientDetails;

    allergies.forEach((allergy) => {
      removeEmptyProps(allergy);

      // eslint-disable-next-line no-param-reassign
      allergy.categories = allergy.categories
        ? allergy.categories.join(", ")
        : "";
      // eslint-disable-next-line no-param-reassign
      allergy.onset_datetime = formatDateTime(allergy.onset_datetime);
      // eslint-disable-next-line no-param-reassign
      allergy.recorded_date = formatDateTime(allergy.recorded_date);
      // eslint-disable-next-line no-param-reassign
      allergy.last_occurrence = formatDateTime(allergy.last_occurrence);

      allergy.reactions.forEach((reaction) => {
        removeEmptyProps(reaction);
        // eslint-disable-next-line no-param-reassign
        reaction.onset = formatDateTime(reaction.onset);
      });
    });

    removeEmptyProps(demographics);

    demographics.email_addresses =
      demographics.email_addresses && demographics.email_addresses.join(" / ");

    diagnoses.forEach((diagnosis) => {
      removeEmptyProps(diagnosis);

      // eslint-disable-next-line no-param-reassign
      diagnosis.onset_datetime = formatDateTime(diagnosis.onset_datetime);
      // eslint-disable-next-line no-param-reassign
      diagnosis.abatement_datetime = formatDateTime(
        diagnosis.abatement_datetime,
      );
    });
  };

  const getPatientDetails = async (hospitalId, id) => {
    const patientDetails = await patientSvc.getPatient(hospitalId, id);
    reshapePatientDetails(patientDetails);
    setSelectedPatients([patientDetails]);
    setShowPatientDetails(true);
    setNewPatients([]);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setSearchTerm(queryParams.get("name") || "");
    const hospitalIdParam = queryParams.get("hospital_id");
    const idParam = queryParams.get("id");

    updateSearchParams({
      id: idParam,
      name: searchTerm,
      hospitalId: hospitalIdParam,
    });
    if (idParam && hospitalIdParam) {
      getPatientDetails(hospitalIdParam, idParam);
    }
  }, []);

  // const arrInput = [
  //   {
  //     label: 'Name ',
  //     type: 'text',
  //   },
  //   {
  //     label: 'Sex ',
  //     type: 'text',
  //   },
  //   {
  //     label: 'Location ',
  //     type: 'text',
  //   },
  //   {
  //     label: 'SSN ',
  //     type: 'text',
  //   },
  // ];

  const bannerContent = {
    btnLabel: AuthService.canAddPatient()
      ? "Upload patients from your EMR"
      : "",
    bannerTitle: "Your Patients",
    bannerText: "Pull patients from your EMR/EHR",
    img: malePatientImg,
    buttonClick: () => setShowCreatePatient(true),
  };

  useEffect(() => {
    apiSvc.loading$.subscribe(setLoading);
    contractSvc.contract$.subscribe(setContract);
    contractSvc.showCreateContract$.subscribe((show) =>
      setShowCreateContract(show === true),
    );

    loadPatients();
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  useEffect(() => {
    pageNumber.current = 1;
    loadPatients();
  }, [debouncedSearchTerm]);

  const patientsToShow =
    selectedPatients.length > 0 ? selectedPatients : newPatients;

  return (
    <div>
      {showPatientDetails && (
        <PatientDetailsModal
          isNew={newPatients.length > 0}
          patients={patientsToShow}
          addPatient={addRedoxPatient}
          hide={() => {
            updateSearchParams({ id: null });
            setShowPatientDetails(false);
          }}
        />
      )}

      {contract && (
        <ContractDetails
          contractDetails={contract}
          hide={() => setContract(null)}
        />
      )}

      {showCreateContract && (
        <CreateContract
          created={hideCreateContractModal}
          hide={hideCreateContractModal}
        />
      )}

      {showCreatePatient && (
        <CreatePatientModal
          hide={() => setShowCreatePatient(false)}
          setPatients={(_patients) => {
            setShowCreatePatient(false);
            setShowPatientDetails(true);
            searchedPatients.current = deepClone(_patients);
            _patients.forEach((_patient) => reshapePatientDetails(_patient));
            setSelectedPatients([]);
            setNewPatients(_patients);
          }}
        />
      )}

      <Leftsidebar />

      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Patients</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__content">
              <TopBanner data={bannerContent} />
              <div className="page__stat page__stat_pt32">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: 360 }}>
                    <DateRange
                      dateRange={dateRange}
                      selectDateRange={handleSelectDateRange}
                      acceptDateRange={handleAcceptDateRange}
                    />
                  </div>
                  <Search
                    value={searchTerm}
                    placeholder="Search imported patients"
                    change={handleSearchChange}
                  >
                    {/* {authSvc.canAddPatient() && (
                    <>
                      <button
                        className='sorting__action'
                        onClick={() => setShowCreatePatient(true)}
                      >
                        {fixIconStyles(plusIcon, store.profile.dark_mode)}
                      </button>

                      <button className='sorting__action'>{fixIconStyles(editIcon, store.profile.dark_mode)}</button>
                    </>
                  )}
                  <button className='sorting__action'>{fixIconStyles(boxIcon2, store.profile.dark_mode)}</button> */}
                  </Search>
                </div>
                <div className="products products_main">
                  <div className="products__table">
                    <div className="products__row products__row_head">
                      <div className="products__cell">
                        <label htmlFor="checkbox" className="checkbox">
                          <input
                            className="checkbox__input"
                            type="checkbox"
                            checked={
                              checkedPatients.length > 0 &&
                              checkedPatients.length === patients.length
                            }
                            id="checkbox"
                            onChange={(e) => checkboxAll(e)}
                          />
                          <span className="checkbox__in">
                            <span className="checkbox__tick" />
                          </span>
                        </label>
                      </div>
                      <div className="products__cell">Name</div>
                      <div className="products__cell">Date of Birth</div>
                      <div className="products__cell">Phone Number</div>
                      <div className="products__cell">Email Address</div>
                    </div>

                    {patients.map((patient, idx) => {
                      const { demographics } = patient;

                      return (
                        <div
                          className="products__row"
                          onClick={() => {
                            updateSearchParams({
                              id: patient.id,
                              hospitalId: patient.hospital_id,
                            });
                            getPatientDetails(patient.hospital_id, patient.id);
                          }}
                          role="button"
                          tabIndex={0}
                          key={idV4()}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              updateSearchParams({
                                id: patient.id,
                                hospitalId: patient.hospital_id,
                              });
                              getPatientDetails(
                                patient.hospital_id,
                                patient.id,
                              );
                            }
                          }}
                        >
                          <div className="products__cell">
                            <label
                              htmlFor={`check_${patient.id}`}
                              className="checkbox"
                            >
                              <input
                                className="checkbox__input"
                                type="checkbox"
                                id={`check_${patient.id}`}
                                checked={checkedPatients?.includes(idx)}
                                onChange={(e) => toggleSelectCheckBox(e, idx)}
                              />
                              <span className="checkbox__in">
                                <span className="checkbox__tick" />
                              </span>
                            </label>
                          </div>

                          <div className="products__cell">
                            <button type="button" className="products__item">
                              <img
                                className="patient_image"
                                src="./assets/img/ava.png"
                                alt=""
                              />

                              <div className="products__details">
                                <div className="products__title title">
                                  {demographics.first_name}{" "}
                                  {demographics.last_name}
                                </div>
                                <div
                                  className="products__info caption color-gray"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {demographics.sex}
                                </div>
                              </div>
                            </button>
                          </div>

                          <div className="products__cell">
                            <div className="products__payment">
                              {formatDate(demographics.dob)}
                            </div>
                          </div>
                          <div className="products__cell">
                            <div className="products__payment">
                              {demographics.phone_number}
                            </div>
                          </div>
                          <div className="products__cell">
                            <div className="products__cost">
                              <div className="products__money">
                                {demographics.email_addresses}
                              </div>
                              <div className="products__tooltip">
                                <div className="products__sale">
                                  <div className="products__icon bg-red">
                                    <img
                                      className="products__pic"
                                      src="./assets/img/chat-black.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="products__caption caption-sm">
                                    Latest Sale
                                  </div>
                                </div>
                                <div className="products__price h3">
                                  daily_rate
                                </div>
                                <div className="products__progress">
                                  <span style={{ width: "68%" }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {loading && patients.length === 0 && (
                    <div style={noResults}>Loading patients</div>
                  )}
                  {!loading && patients.length === 0 && (
                    <div style={noResults}>
                      No results. Please adjust your date range
                    </div>
                  )}
                  {hasMore && (
                    <div className="products__more">
                      <Btn
                        data={{
                          label: "Load More",
                          className: "btn-dark",
                        }}
                        onclickFunc={loadMore}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Patients;
