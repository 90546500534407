import {
  blue,
  borderColor,
  grayOpacity,
  green,
  purple,
  red,
  yellow,
} from "./colors";

export const monthInitials = [
  "J",
  "F",
  "M",
  "A",
  "M",
  "J",
  "J",
  "A",
  "S",
  "O",
  "N",
  "D",
];

export const monthAbbr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const circleChart = {
  chart: {
    height: "100%",
    type: "radialBar",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%",
      },
      track: {
        background: grayOpacity,
        margin: 0,
      },
      dataLabels: {
        show: false,
      },
    },
  },
  fill: {
    colors: purple,
  },
};

export const circleChartPurple = {
  ...circleChart,
};

export const circleChartGreen = {
  ...circleChart,
  fill: {
    colors: green,
  },
};

export const circleChartRed = {
  ...circleChart,
  fill: {
    colors: red,
  },
};

export const circleChartYellow = {
  ...circleChart,
  fill: {
    colors: yellow,
  },
};

export const earningsChartOptions = {
  colors: [blue],
  chart: {
    height: "100%",
    type: "bar",
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  grid: {
    borderColor,
    strokeDashArray: 0,
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  stroke: {
    curve: "smooth",
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      columnWidth: "80%",
    },
  },
  states: {
    hover: {
      filter: {
        type: "darken",
        value: 0.9,
      },
    },
  },
  legend: {
    show: false,
  },
  tooltip: {
    shared: true,
  },
  xaxis: {
    axisBorder: {
      show: false,
      color: borderColor,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const expenseLineChartOptions = {
  colors: [blue, purple],
  chart: {
    height: "100%",
    type: "line",
    toolbar: {
      show: false,
    },
  },
  grid: {
    borderColor,
    strokeDashArray: 0,
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
    padding: {
      top: 0,
      left: 15,
      right: 0,
      bottom: 0,
    },
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  stroke: {
    width: 2,
    curve: "smooth",
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    x: {
      show: false,
    },
  },
};

export const patientTrendChartOptions = {
  colors: [blue, purple],
  chart: {
    height: "100%",
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  states: {
    hover: {
      filter: {
        type: "darken",
        value: 0.9,
      },
    },
  },
  grid: {
    borderColor,
    strokeDashArray: 0,
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 0,
      left: 15,
      right: 0,
      bottom: 0,
    },
  },
  stroke: {
    curve: "smooth",
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      columnWidth: "60%",
    },
  },
  legend: {
    show: false,
  },
  tooltip: {
    shared: true,
  },
  xaxis: {
    axisBorder: {
      show: false,
      color: borderColor,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      formatter: (value) => monthInitials[value - 1],
    },
    tooltip: {
      enabled: false,
    },
  },
  yaxis: {
    labels: {
      formatter(val) {
        return val.toFixed(0);
      },
    },
  },
};
