import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

import { observer } from "mobx-react-lite";
import store from "../../store";
import PatientDetails from "./PatientDetails";

const PatientDetailsModal = observer(
  ({ isNew, patients, addPatient, hide }) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };

    return (
      <Modal size="lg" show onHide={hide} animation centered>
        <Modal.Header
          closeButton
          closeVariant={store.profile.dark_mode ? "white" : ""}
        >
          <Modal.Title className="ps-4">Patient Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {patients.length > 1 ? (
            <div className="carousel__custom">
              <Carousel
                interval={null}
                variant="dark"
                activeIndex={index}
                onSelect={handleSelect}
              >
                {patients.map((patient) => (
                  <Carousel.Item key={`patient_${patient.id}`}>
                    <PatientDetails patient={patient} />
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className="custom-buttons">
                {isNew && (
                  <button
                    type="button"
                    className="btn btn-sm btn-dark"
                    onClick={() => addPatient(patients[index])}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>
              <PatientDetails patient={patients[0]} />
              <div className="text-right">
                {isNew && (
                  <button
                    type="button"
                    className="btn btn-sm btn-dark"
                    onClick={() => addPatient(patients[0])}
                  >
                    Add
                  </button>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  },
);

export default PatientDetailsModal;
