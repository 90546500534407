import PropTypes from "prop-types";
import Search from "./Search";
import Dropdown from "./inputs/Dropdown";

function FilterBox({ children, placeholder, SearchOnClick }) {
  const optionList = [
    {
      title: " Bento 3D Kit",
      caption: "Illustration",
    },
    {
      title: " Bento 3D Kit",
      caption: "Illustration",
    },
    {
      title: " Collab UI Kit",
      caption: "Coded Template",
    },
  ];
  return (
    <div className="sorting">
      <div className="sorting__row">
        <div className="sorting__col">
          <div className="sorting__dropdowns">
            <Dropdown
              data={{
                label: "Last 30 days",
                optionList,
              }}
            />
            <Dropdown
              data={{
                label: "Aug 2020",
                optionList,
              }}
            />
          </div>
        </div>
        <Search placeholder={placeholder} SearchOnClick={SearchOnClick}>
          {children}
        </Search>
      </div>
    </div>
  );
}

FilterBox.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.string,
  SearchOnClick: PropTypes.func,
};

// Default Props
FilterBox.defaultProps = {
  children: null,
  placeholder: "Enter your search",
  SearchOnClick: () => {},
};

export default FilterBox;
