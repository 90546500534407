import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Select from "react-select";
import { dropdownStyles } from "../../constants/styles";
import countriesNowSvc from "../../services/countriesNowService";
import store from "../../store";

const StatesComboBox = observer(({ country, selected, change }) => {
  const [states, setStates] = useState([]);

  useEffect(() => {
    const fetchStates = async () => {
      let options = [];

      if (!country) {
        setStates(options);
        return;
      }

      options = await countriesNowSvc.fetchStates(country);
      setStates(options);
    };

    setStates([]);
    fetchStates();
  }, [country]);

  const handleChange = (selectedOption) => {
    change(selectedOption ? selectedOption.value : "");
  };

  return (
    <Select
      options={states.map((state) => ({
        value: state,
        label: state,
      }))}
      value={selected}
      onChange={handleChange}
      placeholder={country ? "Select a state" : "Select a country first"}
      isDisabled={!country}
      isClearable
      isSearchable
      noOptionsMessage={() => "Type to search..."}
      styles={dropdownStyles(store.profile.dark_mode)}
    />
  );
});

export default StatesComboBox;
