/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { v4 } from "uuid";

import fields from "../../constants/fields.json";
import ContractService, { contractSvc } from "../../services/contractService";
import { formatDate } from "../../utils";
import Address from "./Address";

function PatientDetails({ patient }) {
  const { allergies, demographics, diagnoses, id, medication } = patient;

  const [contracts, setContracts] = useState([]);

  const fetchContracts = () => {
    ContractService.getContractsByPatient(id).then((resp) =>
      setContracts(resp || []),
    );
  };

  const viewContract = (contract) => contractSvc.contract$.next(contract);

  useEffect(() => {
    fetchContracts();

    contractSvc.contractCreated$.subscribe((created) => {
      if (created) {
        fetchContracts();
      }
    });
  }, []);

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Demographics</Accordion.Header>
        <Accordion.Body>
          <div className="fields__group">
            {Object.keys(demographics)
              .filter((x) => x !== "address")
              .map((key) => (
                <dl key={`field_${key}`}>
                  <dt>{fields[key]}</dt>
                  <dd>
                    {demographics[key] === "True"
                      ? "Yes"
                      : demographics[key] === "False"
                      ? "No"
                      : demographics[key]}
                  </dd>
                </dl>
              ))}

            <dl>
              <dt>{fields.address}</dt>
              <dd>
                <div className="demographics__address">
                  {demographics.address.map((address) => (
                    <Address key={`address_${address.id}`} address={address} />
                  ))}
                </div>
              </dd>
            </dl>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Diagnoses</Accordion.Header>
        <Accordion.Body>
          <div className="fields__section">
            {diagnoses.map((diagnosis) => (
              <div key={`diagnosis_${diagnoses.id}`} className="fields__group">
                {Object.keys(diagnosis)
                  .filter((x) => x !== "identifiers")
                  .map((key) => (
                    <dl key={`field_${key}`}>
                      <dt>{fields[key]}</dt>
                      <dd>{diagnosis[key]}</dd>
                    </dl>
                  ))}
              </div>
            ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>Allergies</Accordion.Header>
        <Accordion.Body>
          {allergies.map((allergy) => (
            <div key={`allergy_${allergy.id}`} className="fields__group">
              {Object.keys(allergy)
                .filter(
                  (x) =>
                    ["id", "identifiers", "notes", "reactions"].indexOf(x) ===
                    -1,
                )
                .map((key) => (
                  <dl key={`field_${key}`}>
                    <dt>{fields[key]}</dt>
                    <dd>{allergy[key]}</dd>
                  </dl>
                ))}

              <dl>
                <dt>{fields.notes}</dt>
                <dd className="notes">
                  {allergy.notes.map((note) => (
                    <p key={`allergy_note_${note.id}`}>{note}</p>
                  ))}
                </dd>
              </dl>

              <Accordion defaultActiveKey={0}>
                {allergy.reactions.map((reaction, indx) => {
                  const reactionId = v4();

                  return (
                    <Accordion.Item
                      key={`reaction_${reactionId}`}
                      eventKey={reactionId}
                    >
                      <Accordion.Header>Reaction # {indx}</Accordion.Header>
                      <Accordion.Body>
                        <div className="fields__group">
                          {Object.keys(reaction)
                            .filter((x) => x !== "notes")
                            .map((key) => (
                              <dl key={`field_${key}`}>
                                <dt>{fields[key]}</dt>
                                <dd className={key}>{reaction[key]}</dd>
                              </dl>
                            ))}

                          {reaction.notes.length > 0 && (
                            <dl>
                              <dt>{fields.notes}</dt>
                              <dd className="notes">
                                {reaction.notes.map((note) => (
                                  <p key={`reaction_note_${reaction.id}`}>
                                    {note}
                                  </p>
                                ))}
                              </dd>
                            </dl>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
          ))}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>Medication</Accordion.Header>
        <Accordion.Body>
          <div className="fields__section">
            {medication.map((medicationItem) => (
              <div
                key={`medication_${medicationItem.identifier}`}
                className="fields__group"
              >
                <dl>
                  <dt>{fields.name}</dt>
                  <dd>{medicationItem.name}</dd>
                </dl>

                <dl>
                  <dt>{fields.dose}</dt>
                  <dd>
                    {medicationItem.dosage.doseValue}{" "}
                    {medicationItem.dosage.doseUnit}
                  </dd>
                </dl>

                <dl>
                  <dt>{fields.repeatPeriod}</dt>
                  <dd>
                    {medicationItem.dosage.period}{" "}
                    {medicationItem.dosage.periodUnit}
                  </dd>
                </dl>

                <dl>
                  <dt>{fields.frequency}</dt>
                  <dd>{medicationItem.dosage.frequency}</dd>
                </dl>

                <dl>
                  <dt>{fields.dosageRoute}</dt>
                  <dd>{medicationItem.dosage.route}</dd>
                </dl>

                <dl>
                  <dt>{fields.intent}</dt>
                  <dd>{medicationItem.intent}</dd>
                </dl>

                <dl>
                  <dt>{fields.status}</dt>
                  <dd>{medicationItem.status}</dd>
                </dl>
              </div>
            ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>Contracts</Accordion.Header>
        <Accordion.Body>
          {contracts.map((contract) => (
            <div
              key={`contract_${contract.id}`}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              Last Modified: {formatDate(contract.timestamp)}{" "}
              <button
                type="button"
                className="btn btn-sm"
                style={{
                  backgroundColor: "#6c5dd3",
                  color: "#fff",
                  width: 120,
                  height: 32,
                }}
                onClick={() => viewContract(contract)}
              >
                View
              </button>
            </div>
          ))}

          {contracts.length === 0 && (
            <div style={{ textAlign: "right" }}>
              <button
                type="button"
                className="btn btn-sm"
                style={{
                  backgroundColor: "#6c5dd3",
                  color: "#fff",
                  width: 120,
                  height: 32,
                }}
                onClick={() => contractSvc.showCreateContract$.next(true)}
              >
                Create
              </button>
            </div>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

PatientDetails.propTypes = {
  patient: {
    allergies: PropTypes.array,
    demographics: PropTypes.array,
    diagnoses: PropTypes.array,
    id: PropTypes.string,
    medication: PropTypes.array,
  },
};

PatientDetails.defaultProps = {
  patient: {
    allergies: [],
    demographics: [],
    diagnoses: [],
    id: "",
    medication: [],
  },
};

export default PatientDetails;
