import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { v4 as idV4 } from "uuid";

import medicalApp from "../assets/img/medical-app-1.png";
import { editIcon } from "../assets/svg/exportSvg";
import ContractDetails from "../components/contracts/ContractDetails";
import CreateContract from "../components/contracts/CreateContract";
import Dropdown from "../components/inputs/Dropdown";
import Leftsidebar from "../components/Leftsidebar";
import Search from "../components/Search";
import SearchAndNotification from "../components/SearchAndNotification";
import { facilities } from "../constants/facilities";
import PAGE_SIZE from "../constants/pagination";
import { noResults } from "../constants/styles";
import apiSvc from "../services/apiService";
import { AuthService } from "../services/authService";
import ContractService from "../services/contractService";
import store from "../store";
import {
  contractStatuses,
  fixIconStyles,
  formatDate,
  isExpiring,
} from "../utils";

const statusOptions = contractStatuses.map((title) => ({
  title,
}));

const Contracts = observer(() => {
  const pageNumber = useRef(1);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedContracts, setSelectedContracts] = useState([]);
  const [contractDetails, setContractDetails] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [editContractDetails, setEditContractDetails] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const [showCreateContractModal, setShowCreateContractModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchContracts = () => {
    ContractService.getContracts(
      selectedStatus.title,
      debouncedSearchTerm,
    ).then((resp) => {
      setContracts(resp || []);
      setHasMore((resp || []).length === PAGE_SIZE);
    });
  };

  const updateSearchParams = (params) => {
    const id = params.id || "";
    const name = params.name || searchTerm || "";
    const status = params.status || searchParams.get("status") || "";
    const hospitalId = params.hospitalId || "";

    const updatedParams = {
      id: "",
      name: "",
      status: "",
      hospital_id: "",
    };

    if (id) {
      updatedParams.id = id;
    }

    if (name) {
      updatedParams.name = name;
    }

    if (status) {
      updatedParams.status = status;
    }

    if (hospitalId) {
      updatedParams.hospital_id = hospitalId;
    }
    setSearchParams(updatedParams);
  };

  const hideContractDetails = () => {
    setContractDetails(null);
    updateSearchParams({ id: null });
  };

  const loadMore = () => {
    ContractService.getContracts(
      selectedStatus.title,
      debouncedSearchTerm,
      (pageNumber.current += 1),
    ).then((resp) => {
      setContracts([...contracts, ...(resp || [])]);
      setHasMore((resp || []).length === PAGE_SIZE);
    });
  };

  const onCheckboxAllClick = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setSelectedContracts([]);
      return;
    }

    setSelectedContracts(contracts.slice(0));
  };

  const onStatusChange = (option) => {
    pageNumber.current = 1;
    setSelectedStatus(option);
    setContracts([]);
    setHasMore(false);
  };

  const reloadContracts = () => {
    pageNumber.current = 1;
    fetchContracts();
    setSelectedContracts([]);
  };

  useEffect(() => {
    reloadContracts();
    updateSearchParams({ status: selectedStatus.title });
  }, [debouncedSearchTerm, selectedStatus]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  useEffect(() => {
    apiSvc.loading$.subscribe(setLoading);

    const queryParams = new URLSearchParams(location.search);
    setSearchTerm(queryParams.get("name"));

    const contractIdParam = queryParams.get("id");
    const hospitalIdParam = queryParams.get("hospital_id");

    if (contractIdParam && hospitalIdParam) {
      ContractService.getContract(hospitalIdParam, contractIdParam).then(
        (resp) => {
          if (!resp) {
            return;
          }

          setContractDetails(resp);
        },
      );
    }

    const statusParam = queryParams.get("status");
    if (statusParam) {
      statusOptions.forEach((option) => {
        if (statusParam === option.title) {
          setSelectedStatus(option);
        }
      });
    }
  }, []);

  const handleSearchChange = (newValue) => {
    setSearchTerm(newValue);
    updateSearchParams({ name: searchTerm });
  };

  return (
    <div>
      {contractDetails && (
        <ContractDetails
          contractDetails={contractDetails}
          hide={hideContractDetails}
          process={() => {
            setContractDetails(null);
            reloadContracts();
          }}
        />
      )}

      {showCreateContractModal && (
        <CreateContract
          created={() => {
            setShowCreateContractModal(false);
            reloadContracts();
          }}
          hide={() => setShowCreateContractModal(false)}
        />
      )}

      {editContractDetails && (
        <CreateContract
          contractDetails={editContractDetails}
          created={() => {
            setEditContractDetails(null);
            reloadContracts();
          }}
          hide={() => setEditContractDetails(null)}
        />
      )}

      <Leftsidebar />
      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Contracts</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__content">
              <div className="banner">
                <div className="banner__container">
                  <div className="banner__preview">
                    <img className="banner__pic" src={medicalApp} alt="" />
                  </div>
                  <div className="banner__wrap">
                    <div className="banner__title h3">Your Contracts</div>
                    <div className="banner__text">Create patient contracts</div>
                    {AuthService.canAddContract() && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => setShowCreateContractModal(true)}
                      >
                        Create Contract
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="page__stat page__stat_pt32">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ width: 200 }}>
                    <Dropdown
                      data={{
                        selected: selectedStatus,
                        option_list: statusOptions,
                        selectOption: onStatusChange,
                      }}
                    />
                  </div>
                  <Search
                    value={searchTerm}
                    placeholder="Search"
                    change={handleSearchChange}
                  >
                    {AuthService.canAddContract() &&
                      selectedContracts.length === 1 && (
                        <button
                          type="button"
                          className="sorting__action"
                          onClick={() => {
                            setEditContractDetails(selectedContracts[0]);
                          }}
                        >
                          {fixIconStyles(editIcon, store.profile.dark_mode)}
                        </button>
                      )}
                  </Search>
                </div>
                <div className="products products_main">
                  <div className="products__table">
                    <div className="products__row products__row_head">
                      <div className="products__cell">
                        <label htmlFor="checkbox" className="checkbox">
                          <input
                            id="checkbox"
                            className="checkbox__input"
                            type="checkbox"
                            checked={
                              contracts.length > 0 &&
                              contracts.length === selectedContracts.length
                            }
                            onChange={onCheckboxAllClick}
                          />
                          <span className="checkbox__in">
                            <span className="checkbox__tick" />
                          </span>
                        </label>
                      </div>
                      <div className="products__cell">Patient Name</div>
                      <div className="products__cell">Contract Duration</div>
                      <div className="products__cell">Contract Length</div>
                      <div className="products__cell">Approved date</div>
                      <div className="products__cell">Status</div>
                    </div>

                    {contracts.map((contract) => {
                      const endDate = new Date(contract.start_date);
                      endDate.setDate(
                        new Date(contract.start_date).getDate() +
                          contract.contract_length -
                          1,
                      );

                      return (
                        <div
                          className="products__row"
                          onClick={(e) => {
                            if (e.target.className === "checkbox__tick") {
                              return;
                            }
                            setContractDetails(contract);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              setContractDetails(contract);
                            }
                          }}
                          role="button" // Add the appropriate role
                          tabIndex={0} // Make the element focusable
                          key={idV4()}
                        >
                          <div className="products__cell">
                            <label
                              htmlFor={`check__${contract.id}`}
                              className="checkbox"
                            >
                              <input
                                id={`check__${contract.id}`}
                                className="checkbox__input"
                                type="checkbox"
                                checked={
                                  selectedContracts.findIndex(
                                    (x) => x.id === contract.id,
                                  ) !== -1
                                }
                                onChange={(e) =>
                                  setSelectedContracts(
                                    e.target.checked
                                      ? [...selectedContracts, contract]
                                      : selectedContracts.filter(
                                          (x) => x.id !== contract.id,
                                        ),
                                  )
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                              <span className="checkbox__in">
                                <span className="checkbox__tick" />
                              </span>
                            </label>
                          </div>

                          <div className="products__cell">
                            <div
                              className="products__item"
                              role="button"
                              tabIndex={0} // Add tabIndex to make the div focusable
                              onClick={() =>
                                updateSearchParams({
                                  id: contract.id,
                                  hospitalId: contract.hospital_id,
                                })
                              }
                              onKeyDown={(event) => {
                                if (
                                  event.key === "Enter" ||
                                  event.key === " "
                                ) {
                                  // Handle keyboard "click" behavior on Enter or Space key press
                                  updateSearchParams({
                                    id: contract.id,
                                    hospitalId: contract.hospital_id,
                                  });
                                }
                              }}
                            >
                              <div className="activity__preview">
                                <img
                                  className="activity__pic"
                                  src={medicalApp}
                                  alt=""
                                />
                              </div>

                              <div className="products__details">
                                <div className="products__title title">
                                  {contract.patient_name}
                                </div>
                                <div className="products__info caption color-gray">
                                  {facilities[contract.contract_type]}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="products__cell">
                            <div
                              className={`products__payment caption ${
                                isExpiring(endDate, 5)
                                  ? "color-red"
                                  : "color-gray"
                              }`}
                            >
                              {formatDate(contract.start_date)} -{" "}
                              {formatDate(endDate)}
                            </div>
                          </div>
                          <div className="products__cell">
                            <div className="products__info caption color-gray">
                              {contract.contract_length}
                            </div>
                          </div>
                          <div className="products__cell">
                            <div className="products__info caption color-gray">
                              {contract.approval_date
                                ? formatDate(contract.approval_date)
                                : ""}
                            </div>
                          </div>

                          <div className="products__cell">
                            <div className="products__status caption bg-purple">
                              {contract.status}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {loading && contracts.length === 0 && (
                    <div style={noResults}>Loading contracts</div>
                  )}
                  {!loading && contracts.length === 0 && (
                    <div style={noResults}>
                      No results. Please adjust your status
                    </div>
                  )}
                  {hasMore && (
                    <div className="products__more">
                      <button
                        type="button"
                        onClick={() => loadMore()}
                        className="btn btn-dark"
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Contracts;
