const API_URL = "https://countriesnow.space/api/v0.1/countries";

class CountriesNowService {
  static fetchCountries = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    return data.data.map((x) => x.country).sort((a, b) => (a > b ? 1 : -1));
  };

  static fetchCitiesByCountry = async (country) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      country,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`${API_URL}/cities`, requestOptions)
      .then((response) => response.json())
      .then((result) => result.data.sort((a, b) => (a > b ? 1 : -1)))
      .catch(() => {});
  };

  static fetchCitiesByState = async (country, state) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      country,
      state,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`${API_URL}/state/cities`, requestOptions)
      .then((response) => response.json())
      .then((result) => result.data.sort((a, b) => (a > b ? 1 : -1)))
      .catch(() => {});
  };

  static fetchStates = async (country) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      country,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    return fetch(`${API_URL}/states`, requestOptions)
      .then((response) => response.json())
      .then((result) =>
        result.data.states.map((x) => x.name).sort((a, b) => (a > b ? 1 : -1)),
      )
      .catch(() => {});
  };
}

export default CountriesNowService;
