export const dropdownStyles = (darkMode) => ({
  control: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: darkMode
      ? "rgba(228, 228, 228, 0.04)"
      : "rgba(228, 228, 228, 0.3)",
    borderColor: darkMode
      ? "rgba(228, 228, 228, 0.04)"
      : "rgba(228, 228, 228, 0.3)",
    borderRadius: 10,
    color: "#808191",
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    color: "#808191",
    fontWeight: "bold",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: darkMode ? "#242731" : "white",
    color: "#808191",
    opacity: 1,
    zIndex: 10,
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: darkMode ? "#242731" : "white",
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    color: "#808191",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: darkMode ? "#242731" : "white",
    color: "#808191",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: 14,
    fontWeight: "bold",
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    color: "#808191",
    minHeight: 54,
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: "#808191",
    fontSize: 14,
    fontWeight: "bold",
  }),
});

export const noResults = {
  fontSize: 13,
  lineHeight: 18 / 13,
  fontWeight: 500,
  textAlign: "center",
  paddingTop: 30,
  color: "#b2b3bd",
};
