import { useMsal } from "@azure/msal-react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as idV4 } from "uuid";
import whiteLogoImage from "../assets/img/logo-white.png";
import LogoImage from "../assets/img/logo.png";
import * as svg from "../assets/svg/exportSvg";
import {
  logoutIcon,
  moonIcon,
  profileIcon,
  sidebarArrowIcon,
  sunIcon,
} from "../assets/svg/exportSvg";
import menuItems, { menuItemsList } from "../config/menu-items";
import { notificationSvc } from "../services/notificationService";
import { ProfileService } from "../services/profileService";
import store from "../store";
import { deepClone, fixIconStyles } from "../utils";
import AccountSettings from "./AccountSettings";
import MenuItem from "./MenuItem";

import "../Style/App.css";

const Leftsidebar = observer(() => {
  const { instance } = useMsal();

  const [show, setShow] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const handleClose = () => setShow(false);

  //  this function directly access body so no other way to tackle this problem
  const openSidebar = () => {
    document.querySelector(".page").classList.toggle("toggle");
    document.querySelector(".sidebar").classList.toggle("active");
  };

  //  this function directly access body so no other way to tackle this problem
  const closeSideBar = () => {
    document.querySelector(".page").classList.remove("toggle");
    document.querySelector(".sidebar").classList.remove("active");
    document.querySelector("body").classList.remove("no-scroll");
    document.querySelector("html").classList.remove("no-scroll");
  };

  const [isOpenUser, setIsOpenUser] = useState(false);

  // this function directly accesses body so no other way to tackle this problem
  const setDarkMode = (darkMode) => {
    if (darkMode) {
      document.querySelector("body").classList.add("dark");
    } else {
      document.querySelector("body").classList.remove("dark");
    }

    localStorage.setItem("darkMode", darkMode ? "on" : "off");
  };

  const switchDarkMode = () => {
    let darkMode = false;
    if (localStorage.getItem("darkMode") === "off") {
      document.querySelector("body").classList.add("dark");
      darkMode = true;
    } else {
      document.querySelector("body").classList.remove("dark");
      darkMode = false;
    }

    localStorage.setItem("darkMode", darkMode ? "on" : "off");
    ProfileService.switchTheme(darkMode);
    store.setDarkMode(darkMode);
  };

  async function logOut() {
    instance.logout();
  }

  useEffect(() => {
    const subscription =
      notificationSvc.unreadNotifications$.subscribe(setUnreadCount);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (store.profile.dark_mode !== undefined) {
      setDarkMode(store.profile.dark_mode);
    }
  }, [store.profile]);

  let navigation = [];

  if (store.profile.role && store.profile.company) {
    navigation = deepClone(store.navigation);
  }

  if (navigation && store.profile.role !== "admin") {
    try {
      navigation = navigation.filter(
        (x) => x[store.profile.company][store.profile.role],
      );
    } catch {
      // TODO: Need to show an appropriate message to the user
    }
  }

  const secureMenuItems = [...menuItems, ...navigation];

  return (
    <div>
      {show && <AccountSettings close={handleClose} />}
      <div className="sidebar">
        <div className="sidebar__top">
          <button
            type="button"
            className="sidebar__close"
            onClick={() => closeSideBar()}
          >
            <svg
              className="icon icon-close"
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 13 13"
            >
              <path
                fill="#1B1D21"
                fillRule="evenodd"
                d="M12.4801,2.08058 C12.773,1.78769 12.773,1.31281 12.4801,1.01992 C12.1872,0.72703 11.7123,0.72703 11.4194,1.01992 L6.8232,5.6161 L2.22705,1.01992 C1.93415,0.72703 1.45928,0.72703 1.16639,1.01992 C0.8735,1.31281 0.87349,1.78769 1.16639,2.08058 L5.7626,6.6768 L1.16639,11.273 C0.8735,11.5659 0.87349,12.0407 1.16639,12.3336 C1.45928,12.6265 1.93416,12.6265 2.22705,12.3336 L6.8232,7.7374 L11.4194,12.3336 C11.7123,12.6265 12.1872,12.6265 12.4801,12.3336 C12.773,12.0407 12.773,11.5659 12.4801,11.273 L7.8839,6.6768 L12.4801,2.08058 Z"
              />
            </svg>
          </button>
          <Link className="sidebar__logo" to="/">
            <img
              className="sidebar__pic sidebar__pic_black"
              src={LogoImage}
              alt=""
            />
            <img
              className="sidebar__pic sidebar__pic_white"
              src={whiteLogoImage}
              alt=""
            />
          </Link>
          <button
            type="button"
            className="sidebar__burger"
            onClick={() => openSidebar()}
            aria-label="Open Sidebar"
          />
        </div>
        <div className="sidebar__wrapper">
          <div className="sidebar__inner">
            <Link className="sidebar__logo" to="/">
              <img
                className="sidebar__pic"
                src="./assets/img/logo-sm.png"
                alt=""
              />
            </Link>
            <div className="sidebar__list">
              <div className="sidebar__group">
                <div className="sidebar__menu">
                  {secureMenuItems.map((data) => {
                    const modifiedData = { ...data }; // Create a shallow copy of the 'data' object

                    if (modifiedData.name && !modifiedData.text) {
                      modifiedData.text = modifiedData.name;
                    }

                    if (typeof modifiedData.icon === "string") {
                      modifiedData.icon = svg[modifiedData.icon];
                    }

                    return (
                      <MenuItem
                        key={`menuItem_${idV4()}`}
                        data={modifiedData}
                      />
                    );
                  })}

                  <MenuItem
                    data={menuItemsList?.account}
                    handleShow={() => setShow(true)}
                  />
                </div>
              </div>
              <div className="sidebar__group">
                <div className="sidebar__caption caption-sm">Insights</div>
                <div className="sidebar__menu">
                  <MenuItem
                    data={menuItemsList?.inbox}
                    counter={store.messagesCount}
                  />
                  <MenuItem
                    data={menuItemsList?.notification}
                    counter={unreadCount}
                  />
                </div>
              </div>
            </div>
            <div className="sidebar__profile">
              <div
                className={`sidebar__details ${
                  isOpenUser ? "d-block" : "d-none"
                }`}
              >
                <div
                  className="sidebar__link"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setShow(true);
                    setIsOpenUser(false);
                  }}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      setShow(true);
                      setIsOpenUser(false);
                    }
                  }}
                >
                  <div className="sidebar__icon">
                    {fixIconStyles(profileIcon, store.profile.dark_mode)}
                  </div>
                  <div className="sidebar__text">Profile</div>
                </div>
                <div
                  className="sidebar__link"
                  role="button"
                  tabIndex={0}
                  onClick={logOut}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      logOut();
                    }
                  }}
                  aria-label="LogOut"
                >
                  <div className="sidebar__icon">
                    {fixIconStyles(logoutIcon, store.profile.dark_mode)}
                  </div>
                  <div className="sidebar__text">Logout</div>
                </div>
                <div className="sidebar__text">Logout</div>
              </div>
            </div>
            <Link
              className={`sidebar__user ${isOpenUser ? "active" : ""}`}
              to={{}}
              onClick={() => setIsOpenUser(!isOpenUser)}
            >
              <div className="sidebar__ava">
                <img
                  className="sidebar__pic"
                  src={store.profile.avatar || "./assets/img/ava.png"}
                  alt=""
                />
              </div>
              <div className="sidebar__desc">
                <div className="sidebar__man">Orlando Health</div>
                <div className="sidebar__status caption">Hospital Account</div>
              </div>
              <div className="sidebar__arrow">
                {fixIconStyles(sidebarArrowIcon, store.profile.dark_mode)}
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="sidebar__bottom fixed">
        <label
          htmlFor="modeChnge"
          className="switch switch_theme js-switch-theme"
        >
          <input
            className="switch__input"
            type="checkbox"
            onChange={() => switchDarkMode()}
            id="modeChnge"
          />
          <span className="switch__in">
            <span className="switch__box" />
            <span className="switch__icon">
              {moonIcon}
              {sunIcon}
            </span>
          </span>
        </label>
      </div>
    </div>
  );
});

export default Leftsidebar;
