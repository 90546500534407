import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { v4 as idV4 } from "uuid";

import hospitalImg from "../assets/img/hospital-1.png";
import { editIcon, plusIcon } from "../assets/svg/exportSvg";
import Btn from "../components/buttons/Btn";
import CreateFacility from "../components/facilities/CreateFacility";
import Leftsidebar from "../components/Leftsidebar";
import Search from "../components/Search";
import SearchAndNotification from "../components/SearchAndNotification";
import TopBanner from "../components/TopBanner";
import facilitiesSvc from "../services/facilitiesService";
import store from "../store";
import { fixIconStyles } from "../utils";

const Facilities = observer(() => {
  const location = useLocation();

  const [queryParm, setQueryParm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [facilityDetails, setFacilityDetails] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [showCreateFacility, setShowCreateFacility] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const onCheckboxAllClick = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setSelectedFacilities([]);
      return;
    }

    setSelectedFacilities(facilities.slice(0));
  };

  const getFacilityDetails = async (facility) => {
    facilitiesSvc
      .getFacility(facility.facility_type, facility.facility_id)
      .then(setFacilityDetails);
  };

  const bannerContent = {
    btnLabel: "Add Hospital",
    bannerTitle: "Your Hospitals",
    bannerText: "Add hospitals within your facility_group",
    img: hospitalImg,
    buttonClick: () => setShowCreateFacility(true),
  };

  const fetchFacilities = () =>
    facilitiesSvc.getFacilities(debouncedSearchTerm).then(setFacilities);

  const reloadFacilities = () => fetchFacilities();

  useEffect(() => {
    fetchFacilities();
    setHasMore(false);
  }, []);

  useEffect(() => {
    fetchFacilities();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setQueryParm(queryParams.get("name"));
    if (queryParm) setSearchTerm(queryParm);
  }, [queryParm]);

  return (
    <div>
      {showCreateFacility && (
        <CreateFacility
          hide={() => setShowCreateFacility(false)}
          created={() => {
            setShowCreateFacility(false);
            reloadFacilities();
          }}
        />
      )}

      {facilityDetails && (
        <CreateFacility
          facilityDetails={facilityDetails}
          hide={() => setFacilityDetails(null)}
          created={() => {
            setFacilityDetails(null);
            reloadFacilities();
          }}
        />
      )}

      <Leftsidebar />

      <div className="page">
        <div className="page__wrapper">
          <div className="page__center">
            <div className="page__row page__row_head">
              <div className="page__col">
                <div className="page__hello h5">
                  Hi {store.profile.display_name},
                </div>
                <div className="page__welcome h2">Facilities</div>
              </div>
              <div className="page__col">
                <SearchAndNotification />
              </div>
            </div>
            <div className="page__content">
              <TopBanner data={bannerContent} />
              <div className="page__stat page__stat_pt32">
                <Search
                  value={searchTerm}
                  placeholder="Search"
                  change={setSearchTerm}
                >
                  <button
                    type="button"
                    className="sorting__action"
                    onClick={() => setShowCreateFacility(true)}
                  >
                    {fixIconStyles(plusIcon, store.profile.dark_mode)}
                  </button>
                  {selectedFacilities.length === 1 && (
                    <button
                      type="button"
                      className="sorting__action"
                      onClick={() => {
                        getFacilityDetails(selectedFacilities[0]);
                      }}
                    >
                      {fixIconStyles(editIcon, store.profile.dark_mode)}
                    </button>
                  )}
                </Search>
                <div className="products products_main">
                  <div className="products__table">
                    <div className="products__row products__row_head">
                      <div className="products__cell">
                        <label htmlFor="_checkbox" className="checkbox">
                          <input
                            id="_checkbox"
                            className="checkbox__input"
                            type="checkbox"
                            checked={
                              facilities.length > 0 &&
                              facilities.length === selectedFacilities.length
                            }
                            onChange={onCheckboxAllClick}
                          />
                          <span className="checkbox__in">
                            <span className="checkbox__tick" />
                          </span>
                        </label>
                      </div>
                      <div className="products__cell">Facility Name</div>
                      <div className="products__cell">Current Contracts</div>
                      <div className="products__cell">Pending Contracts</div>
                      <div className="products__cell">Monthly Spend</div>
                    </div>

                    {facilities.map((facility) => {
                      // const {
                      //   facilityId = facility.facility_id,
                      //   monthlyCost = facility.monthly_cost,
                      //   contractsCount = facility.contracts_count,
                      //   facilityName = facility.facility_name,
                      //   facilityGroup = facility.facility_group,
                      //   pendingContracts = facility.pending_contracts,
                      // } = facility;

                      const {
                        monthly_cost: monthlyCost,
                        contracts_count: contractsCount,
                        facility_name: facilityName,
                        facility_group: facilityGroup,
                        pending_contracts: pendingContracts,
                      } = facility;

                      const id = idV4();

                      return (
                        <div className="products__row" key={id}>
                          <div className="products__cell">
                            <label
                              htmlFor={`check__${id}`}
                              className="checkbox"
                            >
                              <input
                                id={`check__${id}`}
                                className="checkbox__input"
                                type="checkbox"
                                checked={
                                  selectedFacilities.indexOf(facility) !== -1
                                }
                                onChange={(e) =>
                                  setSelectedFacilities(
                                    e.target.checked
                                      ? [...selectedFacilities, facility]
                                      : selectedFacilities.filter(
                                          (x) => x !== facility,
                                        ),
                                  )
                                }
                                onClick={(e) => e.stopPropagation()}
                              />
                              <span className="checkbox__in">
                                <span className="checkbox__tick" />
                              </span>
                            </label>
                          </div>

                          <div className="products__cell">
                            <Link className="products__item" to="/facilities">
                              <img
                                className="patient_image"
                                src={hospitalImg}
                                alt=""
                              />

                              <div className="products__details">
                                <div className="products__title title">
                                  {facilityName}
                                </div>
                                <div className="products__info caption color-gray">
                                  {facilityGroup}
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className="products__cell">
                            <div className="products__payment">
                              {contractsCount}
                            </div>
                          </div>
                          <div className="products__cell">
                            <div className="products__payment">
                              {pendingContracts}
                            </div>
                          </div>
                          <div className="products__cell">
                            <div className="products__cost">
                              <div className="products__money">
                                ${monthlyCost}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {hasMore && (
                    <div className="products__more">
                      <Btn
                        data={{
                          label: "Load More",
                          className: "btn-dark",
                        }}
                        onclickFunc={() => {}}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Facilities;
