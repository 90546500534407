import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import medicalAppImage from "../assets/img/medical-app-1.png";

function IconProgressItem({ data }) {
  const { title, info, progress, chartOptions } = data;

  return (
    <div className="quality__item quality__item_chart">
      <div className={`quality__preview ${title && "bg-blue-light-opacity"}`}>
        {title && <img className="quality__pic" src={medicalAppImage} alt="" />}
      </div>
      <div className="quality__details">
        <div className="quality__title title">{title}</div>
        <div className="quality__info caption-sm">{info}</div>
      </div>
      <div className="quality__chart">
        {chartOptions && (
          <Chart
            options={chartOptions}
            series={[progress]}
            width="100%"
            height="100%"
            type={chartOptions.chart.type}
          />
        )}
        {progress && (
          <div className="quality__percent caption-sm">{progress}%</div>
        )}
      </div>
    </div>
  );
}
IconProgressItem.propTypes = {
  data: {
    title: PropTypes.string,
    info: PropTypes.string,
    progress: PropTypes.string,
    chartOptions: {
      type: PropTypes.string,
    },
  },
};
IconProgressItem.defaultProps = {
  data: {}, // Provide a default value that matches the expected data type
};

export default IconProgressItem;
