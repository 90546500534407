import PropTypes from "prop-types";

function DetailsItem({ data }) {
  const { detailsProgress, bg, detailsCounter, detailsText, img } = data;
  return (
    <div className="details__item">
      <div className="details__head">
        <div className={`details__preview ${bg}`}>
          <img className="details__pic" src={img} alt="" />
        </div>
        <div className="details__text caption-sm">{detailsText}</div>
      </div>
      <div className="details__counter h3">{detailsCounter}</div>
      <div className="details__indicator">
        <div
          className={`details__progress ${bg}`}
          style={{ width: detailsProgress }}
        />
      </div>
    </div>
  );
}

DetailsItem.propTypes = {
  data: {
    detailsProgress: PropTypes.string,
    detailsText: PropTypes.string,
    bg: PropTypes.string,
    img: PropTypes.string,
    detailsCounter: PropTypes.number,
  },
};

DetailsItem.defaultProps = {
  data: {}, // Provide a default value that matches the expected data type
};

export default DetailsItem;
