import { makeAutoObservable } from "mobx";

class Store {
  messagesCount = 0;

  navigation = [];

  permissions = [];

  profile = {};

  constructor() {
    makeAutoObservable(this);
  }

  setDarkMode(darkMode) {
    this.profile.dark_mode = darkMode;
  }

  setMessagesCount(count) {
    this.messagesCount = count;
  }

  setNavigation(navigation) {
    this.navigation = navigation || [];
  }

  setPermissions(permissions) {
    this.permissions = permissions || [];
  }

  setProfile(profile) {
    if (!profile) {
      return;
    }

    this.profile = profile;
  }

  updateNavigation(name, company, role, checked) {
    const navigation = this.navigation.find((x) => x.name === name);
    if (navigation) {
      navigation[company][role] = checked;
    }
  }

  updatePermission(permission, company, role, checked) {
    const updatedPermission = this.permissions.find(
      (x) =>
        x.section_name === permission.section_name &&
        x.name === permission.name,
    );

    updatedPermission[company][role] = checked;
  }
}

const store = new Store();

export default store;
