import { Subject } from "rxjs";
import PAGE_SIZE from "../constants/pagination";
import apiSvc from "./apiService";

class ContractService {
  contract$ = new Subject();

  showCreateContract$ = new Subject();

  contractCreated$ = new Subject();

  static acceptContract(hospitalId, id, comments) {
    return apiSvc.post(
      `/patient_contracts/accept?hospital_id=${hospitalId}&id=${id}`,
      {
        comments,
      },
    );
  }

  static approveContract(id) {
    return apiSvc.post(`/patient_contracts/approve?id=${id}`);
  }

  static createContract(data) {
    return apiSvc.post("/patient_contracts", data);
  }

  static getContract(hospitalId, id) {
    return apiSvc.get(
      `/patient_contracts/by-id?hospital_id=${hospitalId}&id=${id}`,
    );
  }

  static getContracts(status, search = "", pageNumber = 1) {
    return apiSvc.get(
      `/patient_contracts?status=${status || ""}&search=${
        search || ""
      }&pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}`,
    );
  }

  static getContractsByPatient(patientId) {
    return apiSvc.get(`/patient_contracts/by-patient?patient_id=${patientId}`);
  }

  static getCount(type, id) {
    return apiSvc.get(
      `/patient_contracts/count?facility_type=${type || ""}&facility_id=${
        id || ""
      }`,
    );
  }

  static getCurrentMonthExpense() {
    return apiSvc
      .get("/patient_contracts/current-month-expense")
      .then((res) => res || 0);
  }

  static getExpenseChart() {
    return apiSvc
      .get("/patient_contracts/expense-chart")
      .then((res) => res || []);
  }

  static getLatestContracts() {
    return apiSvc.get("/patient_contracts/latest").then((res) => res || []);
  }

  static getLinkedContractsLookup() {
    return apiSvc.get("/patient_contracts/lookup/linked");
  }

  static getPatientTrend() {
    return apiSvc.get("/patient_contracts/patient-trend");
  }

  static refuseContract(hospitalId, id, comments) {
    return apiSvc.post(
      `/patient_contracts/refuse?hospital_id=${hospitalId}&id=${id}`,
      {
        comments,
      },
    );
  }

  static updateContract(data) {
    return apiSvc.put("/patient_contracts", data);
  }

  static getContractVersions(contractId) {
    return apiSvc.get(`/patient_contracts/versions?contract_id=${contractId}`);
  }

  static uploadContract(file) {
    const formData = new FormData();
    formData.append("file", file);

    return apiSvc.post("/patient_contracts/upload_file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default ContractService;

export const contractSvc = new ContractService();
