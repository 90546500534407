import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import Select from "react-select";
import { dropdownStyles } from "../../constants/styles";
import countriesNowSvc from "../../services/countriesNowService";
import store from "../../store";

const CountriesComboBox = observer(({ selected, change }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      setCountries(await countriesNowSvc.fetchCountries());
    };

    fetchCountries();
  }, []);

  const handleChange = (selectedOption) => {
    change(selectedOption ? selectedOption.value : "");
  };

  return (
    <Select
      options={countries.map((country) => ({
        value: country,
        label: country,
      }))}
      value={selected}
      onChange={handleChange}
      placeholder="Select a country"
      isClearable
      isSearchable
      noOptionsMessage={() => "Type to search..."}
      styles={dropdownStyles(store.profile.dark_mode)}
    />
  );
});

export default CountriesComboBox;
